<template>
  <Card class="w-full space-y-4 p-4">
    <div class="grid grid-cols-5">
      <div class="col-span-4 flex flex-col space-y-4">

        <div class="text-payne-grey font-semibold">
          {{ assessment.detail.title }}
        </div>

        <div class="flex flex-row w-full items-center space-x-2">
          <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 4.375C10 4.20924 9.93415 4.05027 9.81694 3.93306C9.69973 3.81585 9.54076 3.75 9.375 3.75C9.20924 3.75 9.05027 3.81585 8.93306 3.93306C8.81585 4.05027 8.75 4.20924 8.75 4.375V11.25C8.75004 11.3602 8.77919 11.4684 8.83451 11.5636C8.88982 11.6589 8.96934 11.7379 9.065 11.7925L13.44 14.2925C13.5836 14.3701 13.7518 14.3885 13.9087 14.3437C14.0657 14.2989 14.1988 14.1944 14.2798 14.0527C14.3608 13.9111 14.3831 13.7433 14.342 13.5854C14.301 13.4274 14.1997 13.2918 14.06 13.2075L10 10.8875V4.375Z"
                  fill="#53687B"/>
              <path
                  d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10Z"
                  fill="#53687B"/>
            </svg>
          </div>

          <div>
            {{ extensions.toDate(assessment.detail.startDate.toString()) }} to {{ extensions.toDate(assessment.detail.endDate.toString()) }}
          </div>
        </div>
      </div>


      <div class="col-span-1 flex flex-col items-end">
        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#53687B"/>
        </svg>
      </div>
    </div>

    <div class="flex flex-row justify-between ">
      <div class="font-semibold text-payne-grey">
        Status
      </div>
      <div>
        <Pill :color="extensions.mapDelegateState(assessment)[0]">
          {{ extensions.mapDelegateState(assessment)[1] }}
        </Pill>
      </div>
    </div>

  </Card>

</template>

<script setup lang="ts">

import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";

import Card from "@/shared/components/Card.vue";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import Pill from "@/shared/components/Pill.vue";

defineProps<{
  assessment: AssessmentDisplayObject
}>()

const extensions = new AssessmentDOExtensions()
</script>