import {defineStore} from 'pinia'
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {UserAssessmentProxy} from "@/areas/userAssessments/proxies/UserAssessmentProxy";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import {DelegateState} from "@/areas/delegates/model/enums/delegateState";

interface UserDelegateStoreState {
    updated_at: Date

    userAssessmentProxy: UserAssessmentProxy

    assessments: AssessmentDisplayObject[],

    staticAssessments: AssessmentDisplayObject[],

    error?: string | null

    isLoading: boolean
}

function emptyState(): UserDelegateStoreState {
    return {
        updated_at: new Date(),

        userAssessmentProxy: new UserAssessmentProxy(),

        assessments: [],

        staticAssessments: [],

        isLoading: false
    }
}

export const useUserDelegateStore = defineStore({
    id: "userDelegateStore",
    state: (): UserDelegateStoreState => emptyState(),
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async init(entityGuid: string): Promise<void> {
            await this.withLoading(async () => {
                const extensions = new AssessmentDOExtensions()

                this.staticAssessments = []
                this.assessments = []

                let listResult: OperationResult<DelegateDto[]>

                listResult = await this.userAssessmentProxy.listDelegates(entityGuid)

                if (!listResult.isSuccessful) {
                    this.error = "Could not retrieve assessments from the server"
                    return
                }

                const displayObjects = listResult.content!
                    .filter(res => ![AssessmentState.Cancelled, AssessmentState.Expired].includes(res.assessment?.entityState!))
                    .filter(res => ![DelegateState.Removed].includes(res.entityState!))
                    .map(res => extensions.castToAssessmentDisplayObjectFromDelegate(res))

                this.assessments = displayObjects
                this.staticAssessments = displayObjects
            })
        },

        clearError() {
            this.error = null
        },

        async search(searchString?: string): Promise<void> {
            await this.withLoading(async () => {
                if (searchString == null || searchString.length === 0) {
                    this.assessments = this.staticAssessments

                    return
                }

                const searchValue = searchString.toLowerCase()

                this.assessments = this.staticAssessments
                    .filter(sta =>
                        sta.detail.title.toLowerCase().includes(searchValue) ||
                        sta.delegate?.displayName.includes(searchValue)
                    )

            })
        }
    }

});