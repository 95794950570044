<template>
    <button type="button" class="rounded-sm font-roboto px-3.5 py-2.5 text-sm font-semibold">
        <div class="flex flex-row items-center justify-around">
            <img v-if="imageSrc" :src="imageSrc" alt="button icon" :class="`inline-block mr-2 w-3 ${customStyle ? customStyle : ''}`">
            <p>{{ buttonText }}</p>
        </div>
    </button>
</template>

<script lang="ts">
export default {
    props: {
        buttonText: {
            type: String,
            default: 'Continue'
        },
        imageSrc: {
            type: String,
            default: ''
        },
        customStyle:{
            type: String,
        }
    }
}
</script>