import {formatDistanceToNowStrict, isToday, isYesterday, subDays} from "date-fns";

export const formatDateDisplay = (dateValue: Date | string): string => {
    let date: Date

    if (typeof dateValue === "string") {
        date = new Date(dateValue);
    } else {
        date = dateValue;
    }

    const now = new Date();
    const thirtyMinutesAgo = subDays(now, 1 / 48); // 30 minutes ago

    if (date > thirtyMinutesAgo) {

        return "Just Now";
    } else if (isToday(date)) {

        return "Today";
    } else if (isYesterday(date)) {

        return "Yesterday";
    } else {
        const daysAgo = formatDistanceToNowStrict(date, {unit: 'day'});

        const daysAgoNumber = parseInt(daysAgo.split(' ')[0]);

        if (daysAgoNumber <= 7) {
            return `${daysAgo} ago`;
        } else {
            return date.toISOString().split('T')[0]; // Return the date in YYYY-MM-DD format
        }
    }
}