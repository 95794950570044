<template>
  <div :class="[props.color, 'rounded-md px-2 text-center']">
    <slot />
  </div>

</template>

<script setup lang="ts">

const props = defineProps<{
  color: string
}>()

</script>