<template>
  <AppLayout>
    <div class="p-4 space-y-4">
      <DashboardHeader :tab="'EMPLOYEES'"/>
      <EmployeeList :has-users="hasUsers"/>
    </div>
  </AppLayout>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import AppLayout from '@/components/Layouts/AppLayout.vue';
import DashboardHeader from '@/components/Atomic/BAtoms/DashboardHeader.vue';
import EmployeeList from '@/components/Atomic/CMolecules/EmployeeList.vue';
import Toast from "@/Toast";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {useRoute} from "vue-router";
import EmployeeButtons from "@/components/Atomic/BAtoms/Employees/EmployeeButtons.vue";

export default defineComponent({
  name: 'EmployeesView',
  components: {
    EmployeeButtons,
    AppLayout,
    DashboardHeader,
    EmployeeList
  },
  setup() {
    const route = useRoute()
    const hasUsers = ref<boolean>(false)
    const showEmployeeButtons = ref<boolean>(false)
    const organisationGuid = route.params.organisationGuid as string

    const handleFileUploadResult = (result: OperationResult<any>) => {

      if (!result.isSuccessful) {
        Toast.error("Could not update table after upload. Try refreshing the page")
        hasUsers.value = false
        return;
      }
      hasUsers.value = true
    }

    return {
      organisationGuid,
      hasUsers,
      showEmployeeButtons,
      handleFileUploadResult,
    }
  }
})
</script>