import {defineStore} from "pinia";
import {DemographicsProxy} from "../proxy/demographicsProxy";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";
import {Operations} from "@/areas/helpers/responses/operations";

interface IState {
  proxy: DemographicsProxy;
  demographicsList: Demographic[];
}

export const useDemographicsStore = defineStore({
  id: "demographics",
  state: (): IState => ({
    proxy: new DemographicsProxy(),
    demographicsList: []
  }),

  actions: {
    async list(entityGuid: string) : Promise<OperationResult<Demographic[]>> {
      const result = await this.proxy.list(entityGuid);

      if (!result.isSuccessful) {
        return Operations.ToErrorResponse(result.error as string)
      }

      this.demographicsList = result.content as Demographic[];      
      
      return result;
    },

    async create(demographic: Demographic, entityGuid: string) : Promise<OperationResult<Demographic>> {
      return await this.proxy.create(entityGuid, demographic);
    },

    async toggle(entityGuid: string, demographicGuid: string) : Promise<OperationResult<Demographic>> {
      return await this.proxy.toggleEnable(entityGuid, demographicGuid);
    },

    async update(entityGuid: string, demographicGuid: string, demographic: Demographic) : Promise<OperationResult<Demographic>> {
      return await this.proxy.update(entityGuid, demographicGuid, demographic );
    },

    async deleteDemographic(entityGuid: string, demographicGuid: string) : Promise<OperationResult<Demographic>> {
      return await this.proxy.deleteDemographic(entityGuid, demographicGuid);
    }    
  },
});
