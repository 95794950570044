<template>
  <AppLayout>
    <div class="p-4 space-y-4">
      <DashboardHeader :tab="'ORGANISATION'"/>
      <OrgDetailBranding/>
      <OrganisationDemographics v-if="hasOrganisationGuid"/>
    </div>
  </AppLayout>
</template>

<script lang="ts">

import {useRoute} from 'vue-router';
import {defineComponent, watch, computed} from 'vue';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import DashboardHeader from '@/components/Atomic/BAtoms/DashboardHeader.vue';
import OrgDetailBranding from '@/components/Atomic/BAtoms/OrgDetailBranding.vue';
import OrganisationDemographics from '@/components/Atomic/CMolecules/OrganisationDemographics.vue';

export default defineComponent({
  name: 'OrganisationView',
  components: {
    AppLayout,
    DashboardHeader,
    OrgDetailBranding,
    OrganisationDemographics
  },
  setup() {
    const route = useRoute();
    const hasOrganisationGuid = computed(() => !!route.params.organisationGuid);

    return {
      hasOrganisationGuid
    }
  }
});

</script>
