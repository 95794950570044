<template>
  <AppLayout>
    <AssessorManager
        v-if="userAssessorStore.assessment && userAssessorStore.delegateDisplayItem"
        :assessment="userAssessorStore.assessment!"
        :draft-assessors="[]"
        :delegate="userAssessorStore.delegateDisplayItem!"
        :entity-guid="entityGuid"
        :force-validation="true"
        @save="save"
        @back="goToUserAssessments"
    />
  </AppLayout>

  <PopupErrorMessage
    v-if="userAssessorStore.error"
    :message="userAssessorStore.error"
    @closed="userAssessorStore.clearError()"
  />
</template>

<script setup lang="ts">

import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import AppLayout from "@/components/Layouts/AppLayout.vue";
import {useUserAssessorStore} from "@/areas/userAssessments/stores/UserAssessorStore";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import AssessorManager from "@/areas/assessors/components/AssessorManager.vue";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";

//Store
const userAssessorStore = useUserAssessorStore()

const route = useRoute()
const router = useRouter()

//Route parameters
const assessmentGuid = ref<string>(route.params.assessmentGuid as string)
const entityGuid = ref<string>(route.params.entityGuid as string)
const delegateGuid = ref<string>(route.params.delegateGuid as string)

const save = async (assessors: AssessorDisplayObject[]) => {
  await userAssessorStore.save(assessors)

  if (userAssessorStore.error) {
    Toast.error(userAssessorStore.error, toastOptions)
    return
  }

  Toast.success("Successfully added assessors")

  userAssessorStore.clearState()

  await goToUserAssessments()
}

const goToUserAssessments = async (timeout: number = 0) => {
  setTimeout(async () => {
    await router.push({
      name: 'UserAssessments',
      params: {
        entityGuid: entityGuid.value
      }
    })
  }, timeout ?? 0)
}

onMounted(async () =>{
  await userAssessorStore.init(entityGuid.value, assessmentGuid.value, delegateGuid.value)
})

</script>