import axiosInstance from "@/axiosConfig";
import {Operations} from "@/areas/helpers/responses/operations";
import {OperationResult, OperationResultOnly} from "@/areas/helpers/responses/models/operationResult";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {DomainResultDto} from "@/areas/userAssessments/models/dtos/DomainResultDto";
import {DomainUpsertResultDto} from "@/areas/userAssessments/models/dtos/DomainUpsertResultDto";
import {CommentResultDto} from "@/areas/userAssessments/models/dtos/CommentResultDto";

export class AssessorProxy {

    public async updateState(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string,
        assessorGuid: string,
        state: AssessorState
    ): Promise<OperationResult<AssessorDto>> {

        const baseUri = this.BaseUri(entityGuid, assessmentGuid, delegateGuid)

        const uri = `${baseUri}/${assessorGuid}/states/${state.toString()}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<AssessorDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async listResults(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string,
        assessorGuid: string,
        assessorUserGuid: string): Promise<OperationResult<DomainResultDto[]>> {

        const uri = `${this.BaseUri(entityGuid, assessmentGuid, delegateGuid)}/${assessorGuid}/results`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.get<DomainResultDto[]>(uri, {
                params: {
                    assessorUserGuid: assessorUserGuid,
                }
            })

            return Operations.ToSuccessResponse(result.data)
        });
    }

    public async listResultComments(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string,
        assessorGuid: string,
        assessorUserGuid?: string): Promise<OperationResult<CommentResultDto[]>> {

        const uri = `${this.BaseUri(entityGuid, assessmentGuid, delegateGuid)}/${assessorGuid}/results/comments`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.get<CommentResultDto[]>(uri, {
                params: {
                    assessorUserGuid: assessorUserGuid,
                }
            })

            return Operations.ToSuccessResponse(result.data)
        });
    }

    public async sendReminder(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string,
        assessorGuid: string): Promise<OperationResultOnly> {

        const uri = `${this.BaseUri(entityGuid, assessmentGuid, delegateGuid)}/${assessorGuid}/mails/reminders`

        return Operations.WrapRequestOnly(async () => {
            await axiosInstance.put(uri);

            return Operations.ToSuccessResponseOnly()
        });
    }

    public async listResultsByDelegate(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string): Promise<OperationResult<AssessorDto[]>> {

        const uri = `${this.BaseUri(entityGuid, assessmentGuid, delegateGuid)}/results`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.get<AssessorDto[]>(uri)

            return Operations.ToSuccessResponse(result.data)
        });
    }

    public async upsertResult(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string,
        assessorGuid: string,
        domainResult: DomainUpsertResultDto): Promise<OperationResult<DomainResultDto>> {

        const uri = `${this.BaseUri(entityGuid, assessmentGuid, delegateGuid)}/${assessorGuid}/results`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.put<DomainResultDto>(uri, domainResult)

            return Operations.ToSuccessResponse(result.data)
        });
    }

    public async complete(
        entityGuid: string,
        assessmentGuid: string,
        delegateGuid: string,
        assessorGuid: string,
        commentResults: CommentResultDto[]): Promise<OperationResult<DomainResultDto>> {

        const uri = `${this.BaseUri(entityGuid, assessmentGuid, delegateGuid)}/${assessorGuid}/results/states/complete`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.put<DomainResultDto>(uri, commentResults)

            return Operations.ToSuccessResponse(result.data)
        });
    }

    private BaseUri(entityGuid: string, assessmentGuid: string, delegateGuid: string): string {
        return `entities/${entityGuid}/assessments/${assessmentGuid}/delegates/${delegateGuid}/assessors`
    }
}