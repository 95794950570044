<template>
  <div v-if="isLoading" class="fixed drop-shadow-md bg-gray-500 font-roboto bg-opacity-30 inset-0 flex justify-center items-center z-50">

    <div class="bg-white flex flex-row items-center p-5 rounded-lg max-w-lg w-full">
      <img class="w-10" src="/img/loading-spinner.svg">
      Processing employee upload...
    </div>
  </div>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row gap-2">
      <div class="flex-col">
        <!-- Download CSV Template Button -->
        <button @click="downloadCsvTemplate" class="uppercase rounded-sm bg-white text-olive-green px-5 py-1.5 text-sm font-bold shadow-sm ring-1 ring-inset ring-olive-green" type="button">
            <img alt="download icon" class="w-4 h-4 mb-1 d-inline mr-2" src="/img/download.svg">
            Download CSV Template
        </button>
      </div>
      <div class="flex-col">
        <button class="rounded-sm bg-white px-5 py-1.5 text-sm font-bold text-olive-green shadow-sm ring-1 ring-inset ring-olive-green" type="button"
            @click="onAction(buttonText)">
          <img alt="upload icon" class="w-4 h-4 mb-1 d-inline mr-2" src="/img/upload.svg">
          {{ buttonText }}
        </button>
        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;">
      </div>
      <div class="flex-col">
        <!-- Existing Add Employee Button -->
        <button @click="newEmployeeUrl" class="uppercase rounded-sm bg-olive-green px-5 py-1.5 text-sm font-bold text-white shadow-sm ring-1 ring-inset ring-olive-green" type="button">
            <img alt="plus icon " class="w-4 h-4 mb-1 d-inline mr-2" src="/img/plus.svg">
            <p class="d-inline-block text-sm">Add employee</p>
        </button>
      </div>
    </div>
    <div v-if="fileName && fileName != ''" class="flex flex-row justify-start text-sm text-payne-grey">
      <input class="text-truncate w-[350px] ring-1 ring-inset ring-payne-grey rounded-sm" type="text" :label=" fileState === 'Uploading' ?? ''" :placeholder="fileName" :title="fileName" :disabled="true">
      <button @click="resetFileInput">
        <img class="w-4 h-4 mb-1 d-inline mr-2 text-gray-500" src="/img/close.svg" alt="remove">
      </button>
    </div>
  </div>
  <div v-if="showErrorsModal" class="fixed drop-shadow-md bg-gray-500  font-roboto bg-opacity-30 inset-0 flex justify-center items-center z-50">
    <div class="bg-white p-5 rounded-lg max-w-lg w-full">
      <div class="flex justify-between items-center">
        <h2 class="text-lg font-bold text-payne-grey">File Upload Errors</h2>
        <button @click="toggleErrorsModal" class="text-xl font-semibold">&times;</button>
      </div>
      <div class="mt-4 max-h-[600px] overflow-y-auto">
        <table class="w-full mt-4 text-payne-grey">
          <thead class="bg-gray-100">
            <tr>
              <th class="px-4 py-2 text-left">Row</th>
              <th class="px-4 py-2 text-left">Property</th>
              <th class="px-4 py-2 text-left">Error Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(error, index) in fileErrors" :key="index" class="border-b">
              <td class="px-4 py-2">{{ error.rowNumber }}</td>
              <td class="px-4 py-2">{{ error.key }}</td>
              <td class="px-4 py-2">{{ error.errorType }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { defineComponent, onMounted, ref } from "vue";
import FileDownloadButton from "@/components/Atomic/AQuarks/FileDownloadButton.vue";
import Tooltip from '@/components/Atomic/AQuarks/Tooltip.vue'
import { useEntityUserStore } from "@/areas/entities/store/entityUserStore";
import Toast from "@/Toast";
import Input from "@/components/Atomic/AQuarks/Input.vue";
import Button from "@/components/Atomic/AQuarks/Button.vue";

export default defineComponent({
  name: "EmployeeButtons",
  components: {
    Button,
    Input,
    FileDownloadButton,
    Tooltip
  },
  emits: ['file-upload-success'],
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const entityUserStore = useEntityUserStore();
    const fileInput = ref<HTMLInputElement | null>(null);
    const fileState = ref<string>('Select');
    const entityGuid = ref<string>();
    const buttonText = ref<string>('SELECT CSV FILE');
    const fileName = ref<string>('');
    const showErrorsModal = ref<boolean>(false);
    const fileErrors = ref<Array<{ rowNumber: number; key: string; errorType: string; severity: string }>>([]);
    const isLoading = ref<boolean>(false);

    const toggleErrorsModal = () => {
      showErrorsModal.value = !showErrorsModal.value;
    };

    const downloadCsvTemplate = async () => {
      const result = await entityUserStore.getEmployeeTemplateFile(entityGuid.value!);
      if (result) {
        const blob = new Blob([result.content], { type: 'text/csv;charset=utf-8;' });
        
        const url = window.URL.createObjectURL(blob);

        const downloadElement = document.createElement('a');
        downloadElement.href = url;
        downloadElement.setAttribute('download', 'employee_template.csv');

        document.body.appendChild(downloadElement);
        downloadElement.click();

        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(url);
      } else {
        Toast.error("Failed to download template");
      }
    };

    const onAction = async (buttonText: string) => {
      if (buttonText === 'SELECT CSV FILE') {
        fileInput.value?.click();
      } else if (buttonText === 'UPLOAD CSV FILE') {
        if (!fileInput.value || !fileInput.value.files?.length) {
          Toast.error("File not found");
          return;
        }
        fileState.value = 'Uploading';
        isLoading.value = true;
        const result = await entityUserStore.uploadFile(entityGuid.value!, fileInput.value.files[0])
          .finally(() => isLoading.value = false)
        if (result.isSuccessful && result.content.fileErrors.length > 0) {
          fileErrors.value = result.content.fileErrors;
          toggleErrorsModal();
          resetFileInput();
        } else if (result.isSuccessful) {
          Toast.success("File uploaded successfully without any errors.");
        } else {
          Toast.error("File upload failed.");
        }

        fileState.value = 'Select';
      }
    };

    onMounted(() => {
      entityGuid.value = route.params.organisationGuid as string;
      resetFileInput();
    });

    const handleFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files?.length) {
        buttonText.value = 'UPLOAD CSV FILE';
        fileName.value = target.files[0].name;
      }
    };

    const resetFileInput = () => {
      if (fileInput.value) {
        fileInput.value.value = '';
        fileName.value = '';
        buttonText.value = "SELECT CSV FILE";
      }
    };

    return {
      downloadCsvTemplate,
      route,
      router,
      fileName,
      fileInput,
      fileState,
      buttonText,
      onAction,
      handleFileChange,
      resetFileInput,
      toggleErrorsModal,
      showErrorsModal,
      fileErrors,
      isLoading
    };
  },
  methods: {
    newEmployeeUrl() {
      this.router.push(`/dashboard/employees/${this.route.params.organisationGuid}/new`);
    }
  }
})
</script>
