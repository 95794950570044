<template>
  <div class="grid grid-cols-2 gap-8">
    <Select
      v-for="({ label, key, isValid, options, versionGuid, selected, prepop }) of data"
      @value-changed="(value) => {
          setSelected({ demographicName: label, demographicGuid: key, valueGuid: value, versionGuid: versionGuid, selected })
          validateDemographics()
      }"
      :key="key + fieldValidationKey"
      :label="label"
      :disabled="label.toLowerCase() === 'age'"
      :selected="prepop ? selected : label.toLowerCase() === 'age' ? selectedAgeRangeValue : getSelected(key)"
      :options="options"
      :is-invalid="isValid"
      :required="true"
    />
  </div>
</template>

<script lang="ts">
import Select from "@/components/Atomic/AQuarks/Select.vue";
import {useRoute} from 'vue-router';
import bus from '@/bus'
import {computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {findIndex} from "lodash";
import {IDemographicRange} from "@/areas/demographics/model/data/demographics";
import {useDemographicsStore} from "@/areas/demographics/store/demographicStore";
import {SelectedUserDemographic} from "@/types/shared";
import {UserDemographicDto} from "@/areas/demographics/model/data/dtos/userDemographicDto";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";
import {Option} from '@/areas/entities/model/data/dtos/option'
import {EmployeeDto} from '@/areas/entities/model/data/dtos/employeeDto'
import {UserDemographic} from "@/areas/entities/model/data/dtos/userDemographicDto";

export default defineComponent({
  name: 'Demographics',
  components: {
    Select
  },
  props: {
      dob: {
          type: Date,
          default: new Date()
      },
      selectedDemographics: {
          type: Array as () => UserDemographic[],
          required: true
      },
      fieldValidationKey: {
          type: Number,
          default: 0
      },
      isFormValid: {
          type: Object as () => {state: boolean},
          required: true
      },
      userDetails: {
          type: Object as () => EmployeeDto,
          default: {}
      },
  },
  setup(props, context) {
    const route = useRoute();
    const organisationGuid = route.params.organisationGuid as string;
    const demographicStore = useDemographicsStore();
    const demoRanges = ref<IDemographicRange[]>([]);
    const selectedDemographics = ref<UserDemographicDto[]>(props.selectedDemographics);
    const data = ref<SelectedUserDemographic[]>([]);
    const selectedAgeRangeValue = ref('');

    const effectiveDemographics = computed(() => {
      return props.userDetails && props.userDetails.userDemographics && props.userDetails.userDemographics.length > 0
        ? props.userDetails.userDemographics
        : props.selectedDemographics;
    });

    function calculateAge(dob: string) {
      const birthDate = new Date(dob);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }

    //@ts-ignore
    watch(effectiveDemographics, (newValue) => {
      context.emit('update:selectedDemographics', newValue);
    }, { deep: true, immediate: true });

    function parseDateFromString(dateString: string): Date {
      const parts = dateString.split('/');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // JS months are 0-indexed
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      }
      return new Date(dateString); // Fallback to default parser if the format does not match
    }

    const fetchDemographics = async () => {
      const result = await demographicStore.list(organisationGuid);

      if (result.isSuccessful) {
        const enabledDemographics : Demographic[] = result.content!.filter(demographic => demographic.isEnabled);
        data.value = enabledDemographics.map((demographic) => {
          let options:Option[] = [];
          let versionGuid = '';
          let isValid = false;
          let selectedValue = '';
          let prepop = false;

          if (demographic.demographicVersions && demographic.demographicVersions.length > 0) {
            const version = demographic.demographicVersions[0];
            versionGuid = version.guid as string;

            if (demographic.demographicType === 'StaticCategory') {
              options = demographic.demographicVersions!.flatMap(dv => dv.payload.map(p => ({
                option: p.title, // Use title for StaticCategory
                value: p.title, // Use title as a unique identifier
              })));
            } else if (demographic.demographicType === 'NumberedRange') {
              options = demographic.demographicVersions!.flatMap(dv => dv.payload.map(p => ({
                option: `${p.min}-${p.max}`, // Fallback to min-max if title is not available
                value: `${p.min}-${p.max}`, // Using min-max as a unique identifier
              })));
            }
          }
          if (demographic.displayName.toLowerCase() === 'age') {
            const ageRanges = demographic.demographicVersions!.flatMap(dv => dv.payload);
            const today = new Date();

            const dob = computed(() => {
              const parsedDate = props.dob as Date

              //@ts-ignore
              return isNaN(parsedDate.getTime()) ? new Date(props.userDetails.user.dateOfBirth) : parsedDate;

            });
            const age = today.getFullYear() - dob.value.getFullYear() - (today.getMonth() < dob.value.getMonth() || (today.getMonth() === dob.value.getMonth() && today.getDate() < dob.value.getDate()) ? 1 : 0);

            const foundRange = ageRanges.find(r => age >= r.min! && age <= r.max!);
            if (foundRange) {
              selectedAgeRangeValue.value = `${foundRange.min}-${foundRange.max}`;
            }
            const ageDemographic = enabledDemographics.find(d => d.displayName.toLowerCase() === 'age');
            if (ageDemographic && selectedAgeRangeValue.value) {
              const ageDemographicData: UserDemographicDto = {
                demographicGuid: ageDemographic.guid ?? '',
                demographicName: ageDemographic.displayName,
                valueGuid: dob.value, 
                versionGuid: ageDemographic.demographicVersions?.[0]?.guid ?? '',
                isValid: true, // since it's calculated and not user-provided
              };

              const existingIndex = findIndex(selectedDemographics.value, { demographicGuid: ageDemographicData.demographicGuid });
              if (existingIndex !== -1) {
                selectedDemographics.value.splice(existingIndex, 1, ageDemographicData);
              } else {
                selectedDemographics.value.push(ageDemographicData);
              }
            }
          }

          if (props.userDetails && props.userDetails.userDemographics) {

            const userDemographic = effectiveDemographics.value.find(ud => ud.demographicGuid === demographic.guid);

            prepop = true
            if (userDemographic) {
              if (demographic.displayName.toLowerCase() === 'age') {
                // Calculate age and determine the range
                //@ts-ignore
                const age = calculateAge(userDemographic.answer);
                const ageRangeOption = options.find(option => {
                  const [min, max] = option.value.split('-').map(Number);
                  return age >= min && age <= max;
                });
                selectedValue = ageRangeOption?.value || '';
                isValid = !!selectedValue
              } else {
                // Directly use the answer for other demographics
                //@ts-ignore
                selectedValue = userDemographic.answer;
                isValid = !!selectedValue
              }
            }
          }

          
          return {
            key: demographic.guid ?? '',
            label: demographic.displayName,
            isValid: isValid,
            options: options,
            prepop: prepop,
            selected: selectedValue,
            versionGuid: versionGuid
          };
        });
      }
    };

    const validateDemographics = () => {
      let isValid = true;
    
      data.value = data.value.map(demographic => {
          //@ts-ignore
          const isPrepopulated = demographic.prepop;
          let demographicIsValid = false;
        
          //@ts-ignore
          if (isPrepopulated && demographic.selected) {
              //@ts-ignore
              demographicIsValid = !!demographic.selected;
              //@ts-ignore
          } else {
              const userDemographic = selectedDemographics.value.find(sd => sd.demographicGuid === demographic.key);
              demographicIsValid = !!userDemographic && userDemographic.valueGuid !== '';
          }
        
          demographic.isValid = !demographicIsValid;
          if (!demographicIsValid) {
              isValid = false;
          }
        
          return demographic;
      });
      props.isFormValid.state = isValid;
  };

  //@ts-ignore
  const setSelected = ({ demographicName, demographicGuid, valueGuid, versionGuid, selected }) => {
    let isUpdated = false;
    
    const updatedSelections = selectedDemographics.value.map(d => {
      if (d.demographicGuid === demographicGuid) {
        isUpdated = true;
        return reactive({ ...d, valueGuid, versionGuid, answer: valueGuid });
      }
      return d;
    });

    if (!isUpdated) {
      updatedSelections.push(reactive({ demographicName, demographicGuid, valueGuid, versionGuid, answer: valueGuid }));
    }

    selectedDemographics.value = updatedSelections; // Update the local ref
    context.emit('update:selectedDemographics', updatedSelections); // Emit the update to the parent
  };

    onMounted(() => {
      fetchDemographics()
        .then(() => {
          nextTick().then(() => {
            // Automatically set selected demographics based on fetched data or predefined conditions
            data.value.forEach(demographic => {
              //@ts-ignore
              let valueToSet = demographic.prepop ? demographic.selected : demographic.label.toLowerCase() === 'age' ? selectedAgeRangeValue.value : '';

              if (!valueToSet && demographic.options.length > 0) {
                // If no value is prepopulated or selected, default to the first option available
                // This is a basic approach; you may need a more complex logic based on your requirements
                valueToSet = demographic.options[0].value;
              }

              if (valueToSet) {
                setSelected({
                  demographicName: demographic.label,
                  demographicGuid: demographic.key,
                  valueGuid: valueToSet,
                  //@ts-ignore
                  selected: demographic.selected,
                  //@ts-ignore
                  versionGuid: demographic.versionGuid
                });
              }
            });
            validateDemographics();
          })
        })
      bus.on('validateDemographics', validateDemographics)
    });

    
    onUnmounted(()=>{
      bus.off('validateDemographics', validateDemographics)
    })
    

    return {
      data,
      demoRanges,
      selectedDemographics,
      validateDemographics,
      selectedAgeRangeValue,
      setSelected
    };
  },
  methods:{
      getSelected(demographicGuid: string): string {
          const userDemographic = this.selectedDemographics.find(sd => sd.demographicGuid === demographicGuid)

          return userDemographic ? userDemographic.demographicGuid as string : '';
      }
  }
});
</script>
