<template>
  <Card class="flex flex-row items-center rounded-md shadow-sm">
    <SearchInput
        class="w-full"
        :all-selected="allSelected"
        :placeholder="placeholder"
        :selectable="selectable"
        @searchUpdate="val => emit('searchUpdate', val)"
        @selectAll="val => emit('selectAll', val)"
    />
  </Card>
</template>

<script setup lang="ts">

import Card from "@/shared/components/Card.vue";
import SearchInput from "@/shared/components/inputs/SearchInput.vue";

const props = defineProps<{
  //Placeholder text to be displayed in the search bar
  placeholder?: string

  //Adds a tick box next to the card
  selectable?: boolean

  //Is all selected or not
  allSelected?: boolean
}>()

const emit = defineEmits<{
  searchUpdate: [searchString: string]
  selectAll: [boolean]
}>()

</script>