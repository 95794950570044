<template>
  <button @click="emit('back')" class="flex flex-row items-center text-sm bg-transparent text-payne-grey hover:bg-gray-50">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M10 15l-5-5 5-5v10z"/>
    </svg>
    <div class="items-center">{{ heading ?? 'BACK'}}</div>
  </button>
</template>

<script setup lang="ts">
defineProps<{
  heading?: string
}>()

const emit = defineEmits<{
  back:[]
}>()
</script>