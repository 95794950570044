<template>
  <AppLayout>
    <div class="p-4 space-y-4">
      <AssessmentTypeView :entity-guid="entityGuid"/>
    </div>
  </AppLayout>

</template>

<script setup lang="ts">
import AppLayout from "@/components/Layouts/AppLayout.vue";
import {useRoute} from "vue-router";
import AssessmentTypeView from "@/areas/assessmentTypes/components/AssessmentTypeView.vue";

const route = useRoute()

const entityGuid = route.params.entityGuid as string
</script>