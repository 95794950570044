import axiosInstance from "@/axiosConfig";
import {Operations} from "@/areas/helpers/responses/operations";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";

export class UserAssessmentProxy {

    public async listDelegates(entityGuid: string): Promise<OperationResult<DelegateDto[]>> {
        const uri = `/entities/${entityGuid}/users/assessments/delegates`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.get<DelegateDto[]>(uri)

            return Operations.ToSuccessResponse(result.data)
        });
    }

    public async getDelegate (entityGuid: string, assessmentGuid: string, delegateGuid: string): Promise<OperationResult<DelegateDto>> {
        const uri = `/entities/${entityGuid}/users/assessments/${assessmentGuid}/delegates/${delegateGuid}`

        return Operations.WrapRequest(async () => {
            const result = await axiosInstance.get<DelegateDto>(uri)

            return Operations.ToSuccessResponse(result.data)
        });
    }
}