<template>
  <div id="app" class="min-h-screen">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import '../public/fonts/assets/styles/index.css';
</style>
