<template>
  <PopupMessageDisplay>
    <div class="flex flex-row items-center justify-center">
      <div class="w-full sm:max-w-2xl">
        <div class="bg-red-lighten-5 border border-red-400 text-red-darken-4 px-4 py-4 rounded relative flex flex-row justify-between"
             role="alert">

          <span class="block sm:inline mr-4">{{ message }}</span>

          <div class="flex flex-col justify-center items-center">
          <span @click="emit('closed')" class="top-0 bottom-0 right-0">
            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path
                  d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </span>
          </div>
        </div>
      </div>
    </div>
  </PopupMessageDisplay>
</template>

<script setup lang="ts">
import PopupMessageDisplay from "@/shared/components/PopupMessageDisplay.vue";

defineProps<{
  message: string
}>()

const emit = defineEmits<{
  closed: []
}>()
</script>