<template>
  <AppLayout>
    <div class="flex flex-col justify-center items-center">
      <img class="mx-auto" alt="Vue logo" src="../../public/img/logo.svg">
      <p class="font-roboto text-center text-payne-grey">Welcome, please select an organisation or add a new one to get
        started</p>
      <div class="flex flex-row space-x-4 mt-4">
        <button @click="toggleOrgDropdown()"
                class="uppercase py-2 px-4 border font-bold text-sm text-olive-green font-roboto ring-inset ring-1 ring-olive-green">
          Select organisation
        </button>
        <button v-if="adminPriv" @click="navigateNewOrganisation()"
                class="flex flex-row items-center uppercase py-2 px-4 text-white font-bold text-sm font-roboto bg-olive-green">
          <img class="mr-2 w-5" src="../../public/img/plus.svg">Add new organisation
        </button>
      </div>
    </div>
  </AppLayout>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import {useUserStore} from '@/areas/users/store/userStore'
import {useRouter} from 'vue-router';
import bus from "@/bus"
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {EntityTypeEnum} from "@/areas/entities/model/enums/entityTypeEnum";

export default defineComponent({
  name: 'HomeView',
  components: {
    AppLayout
  },
  setup() {
    const userStore = useUserStore();
    const router = useRouter();
    const adminPriv = ref<boolean>(false);

    const validateRole = async () => {
      const userState = await userStore.getState()

      const entityType = userState.activeEntity.entityType;
      const userRole = userState.role;


      if (userRole == UserRole.SuperAdmin && entityType == EntityTypeEnum.Root) {
        adminPriv.value = true

        return;
      }

      if ((userRole == UserRole.SuperAdmin || userRole == UserRole.OrganisationAdmin) && entityType != EntityTypeEnum.Root) {
        await router.push(`/dashboard/organisation/${userState.activeEntity.guid}`)

        return;
      }

      await router.push(`/dashboard/entities/${userState.activeEntity.guid}/users/assessments`)
    }

    onMounted(validateRole);

    return {
      adminPriv
    }
  },
  methods: {
    navigateNewOrganisation() {
      this.$router.push('/dashboard/organisation')
    },
    toggleOrgDropdown() {
      bus.emit('toggle-org-dropdown');
    },
  }
});
</script>
