import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {DelegateCreateDto} from "@/areas/delegates/model/dtos/delegateCreateDto";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {DelegateSummary} from "@/areas/delegates/model/dos/DelegateSummary";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {DelegateDisplayState} from "@/areas/delegates/model/enums/DelegateDisplayState";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import { v4 as uuidv4 } from 'uuid';
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";

export class DelegateExtensions {
    constructor() {
    }

    static toDelegateCreateDto2 = (delegateDisplayItem: DelegateDisplayItem, assessorsDo: AssessorDisplayObject[]): DelegateCreateDto => {
        return {
            displayName: delegateDisplayItem.delegateType == DelegateType.Team ? delegateDisplayItem.displayName : undefined,
            individualGuid: delegateDisplayItem.delegateType == DelegateType.Individual ? delegateDisplayItem.individual?.guid : undefined,
            selectOwnAssessors: delegateDisplayItem.selectOwnAssessor ?? false,
            assessors: assessorsDo.map(ad => ad.assessorUpsertDto),
            delegateType: delegateDisplayItem.delegateType
        }
    }

    static toDelegateCreateDto = (delegateDisplayItem: DelegateDisplayItem): DelegateCreateDto => {
        return {
            displayName: delegateDisplayItem.delegateType == DelegateType.Team ? delegateDisplayItem.displayName : undefined,
            individualGuid: delegateDisplayItem.delegateType == DelegateType.Individual ? delegateDisplayItem.individual?.guid : undefined,
            selectOwnAssessors: delegateDisplayItem.selectOwnAssessor ?? false,
            assessors: delegateDisplayItem.assessors.map(ad => ad.assessorUpsertDto),
            delegateType: delegateDisplayItem.delegateType
        }
    }

    static toDelegateDisplayItem(delegateDto: DelegateDto): DelegateDisplayItem {
        const displayName = delegateDto.delegateType == DelegateType.Team ?
            delegateDto.displayName! :
            `${delegateDto.individual?.firstName} ${delegateDto.individual?.lastName}`

        return {
            key: delegateDto.guid!,
            displayName: displayName,
            delegateDto: delegateDto,
            individual: delegateDto.individual,
            delegateType: delegateDto.delegateType!,
            selectOwnAssessor: delegateDto.selectOwnAssessors,
            summary: this.toDelegateSummary(delegateDto),
            delegateDisplayState: DelegateDisplayState.Existing,
            assessors: []
        }
    }

    static toDelegateDisplayItemsFromParticipantDo (participantDo: ParticipantDo, state: DelegateDisplayState = DelegateDisplayState.New): DelegateDisplayItem[] {
        switch (participantDo.delegateType) {
            case DelegateType.Team :
                return [
                    {
                        key: uuidv4(),
                        displayName: participantDo.teamName!,
                        delegateType: DelegateType.Team,
                        delegateDisplayState: state,
                        selectOwnAssessor: false,
                        assessors: []
                    }
                ]

            case DelegateType.Individual :
                return participantDo.employees.map(emp => {
                    return {
                        key: emp.user.guid!,
                        displayName: `${emp.user.firstName} ${emp.user.lastName}`,
                        delegateType: DelegateType.Individual,
                        selectOwnAssessor: true,
                        individual: emp.user,
                        delegateDisplayState: state,
                        employee: emp,
                        assessors: []
                    }
                })

            default:
                return []
        }
    }

    static validateDelegate (delegateDisplayItem: DelegateDisplayItem, assessment: AssessmentDto): string | undefined {
        const aExtensions = new AssessmentDOExtensions()

        const assessmentDo = aExtensions.castToAssessmentDisplayObject(assessment)

        const assessmentDetail = assessmentDo.detail

        const minAssessors = assessmentDetail.minimumAssessors ?? 2

        const totalAssessors = delegateDisplayItem.assessors.length

        if (totalAssessors < minAssessors) {
            return `You currently have ${totalAssessors} assessors and you need a minimum of ${minAssessors} assessors. Please add more assessors`
        }

        if (assessmentDetail.maximumAssessors && totalAssessors > assessmentDetail.maximumAssessors) {
            return `You currently have ${totalAssessors} assessors and can only have a maximum of ${minAssessors} assessors. Please remove assessors`
        }

        if (assessmentDetail.includeLeader) {
            const containsLeader = delegateDisplayItem
                .assessors
                .some(ado => ado.assessorUpsertDto.assessorRelationshipType == AssessorRelationshipType.Leader)

            if (!containsLeader) {
                return "You need to add at least one leader to this assessment"
            }
        }
    }

    static toSummary(assessors: AssessorDisplayObject[]) {
        const activeAssessors = assessors
            .filter(as => ![AssessorState.Removed, AssessorState.Declined].includes(as.assessorState))

        const total = activeAssessors.length

        const waiting = activeAssessors
            .filter(as => [AssessorState.Draft, AssessorState.Invited, AssessorState.Accepted].includes(as.assessorState))
            .length

        const inProgress = activeAssessors
            .filter(as => [AssessorState.InProgress].includes(as.assessorState))
            .length

        const completed = activeAssessors
            .filter(as => [AssessorState.Completed].includes(as.assessorState))
            .length

        return {
            completed: completed,
            inProgress: inProgress,
            waiting: waiting,
            total: total
        }
    }

    static toDelegateSummary(delegateDto: DelegateDto): DelegateSummary {
        const assessors = delegateDto.assessors!
            .filter(as => ![AssessorState.Removed, AssessorState.Declined].includes(as.entityState!))

        const total = assessors.length

        const waiting = assessors
            .filter(as => [AssessorState.Draft, AssessorState.Invited, AssessorState.Accepted].includes(as.entityState!))
            .length

        const inProgress = assessors
            .filter(as => [AssessorState.InProgress].includes(as.entityState!))
            .length

        const completed = assessors
            .filter(as => [AssessorState.Completed].includes(as.entityState!))
            .length

        return {
            completed: completed,
            inProgress: inProgress,
            waiting: waiting,
            total: total
        }
    }
}