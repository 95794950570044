import {Entity} from "@/areas/entities/model/data/dtos/entity";

export class EntityExtensions {
    static getCompanyLogo(entity: Entity): string | undefined {
        if (entity.entityAssets != undefined && entity.entityAssets.length > 0) {
            return entity.entityAssets[0].signedUrl
        } else {
            return '/img/avatarIcon.svg'
        }
    }
}