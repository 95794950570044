<template>
  <Input
      label="Assessment Title"
      placeholder="Assessment Title"
      :required="true"
      :disabled="!inDraft()"
      :default-value="configSection?.title ?? ''"
      @value-changed="updateTitle"
      class="w-full"
  />
  <p v-if="showTitleError()" class="text-red text-xs my-2">
    Please provide a valid title with a minimum amount of 8 characters.
  </p>

  <Select
      label="Assessment Type"
      :selected="configSection?.assessmentType?.guid ?? ''"
      @value-changed="updateSelected"
      class="w-full"
      :options="selectOptions"
      :disabled="props.assessmentConfigSection != null"
      placeholder="Select an assessment type from the dropdown"
  />
  <p v-if="showAssessmentTypeError()" class="text-red text-xs my-2">
    Please select an assessment type from the drop down list
  </p>

  <div class="flex flex-row w-full space-x-4">
    <div class="flex flex-col w-full">

      <DatePicker
          label="Start Date"
          :error="showStartDateError() ? 'Please provide a valid start date' : ''"
          :min-date="new Date()"
          :date="configSection.startDate"
          :disabled="!inDraft()"
          @updatedDate="updateStartDate"
      />

    </div>

    <div class="flex flex-col w-full">
      <DatePicker
          label="End Date"
          :error="showEndDateError() ? 'Please provide a valid end date' : ''"
          :date="configSection.endDate ?? undefined"
          :min-date="configSection.startDate"
          :disabled="configSection.startDate == undefined"
          @updatedDate="updateEndDate"
      />

    </div>
   </div>

</template>

<script setup lang="ts">
import Input from '../../../components/Atomic/AQuarks/Input.vue';
import Select from '@/components/Atomic/AQuarks/Select.vue';
import {computed, onMounted, Ref, ref} from 'vue';
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessmentDetailValues} from "@/areas/assessments/model/data/AssessmentDetailValues";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import DatePicker from "@/shared/components/DatePicker.vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";

const props = defineProps<{
  assessmentTypes: AssessmentTypeDto[]
  assessmentConfigSection?: AssessmentDetailValues
  assessment?: AssessmentDisplayObject
  assessmentState?: AssessmentState
  showValidation: boolean
}>()

const emits = defineEmits<{
  assessmentInputValueChanged: []
  validated: [AssessmentDetailValues]
  inValidated: []
}>()

const configSection: Ref<AssessmentDetailValues> = ref(props.assessmentConfigSection ?? {} as AssessmentDetailValues)

const selectOptions = computed(() => {
  return props.assessmentTypes.map(assessmentType => ({
    option: assessmentType.displayName,
    value: assessmentType.guid!,
  }))
})

onMounted(() => {
  emitValid()
})

const updateTitle = (title: string) => {
  configSection.value.title = title

  emitValid();
}

const inDraft = (): boolean => {
  const state = props.assessment?.assessment?.entityState

  return (!state || state == AssessmentState.Draft)
}

const updateSelected = (selected: string) => {
  const selectedAssessmentType = props.assessmentTypes.find(at => at.guid == selected)

  if (selectedAssessmentType) {
    configSection.value.assessmentType = selectedAssessmentType
  } else {
    configSection.value.assessmentType = undefined
  }

  emitValid()
}

const updateStartDate = (date?: Date) => {
  if (!date) {
    configSection.value.startDate = undefined
    configSection.value.startDateText = "Please provide a valid date"

    return
  }

  date.setHours(0, 0, 0, 0)

  configSection.value.startDate = date
  configSection.value.startDateText = undefined

  emitValid()
}

const updateEndDate = (date?: Date) => {
  if (!date) {
    configSection.value.endDate = undefined
    configSection.value.endDateText = "Please provide a valid date"

    return
  }

  date.setHours(0, 0, 0, 0)

  configSection.value.endDate = date
  configSection.value.endDateText = undefined

  emitValid()
}

const emitValid = () => {
  if (validate()) {
    emits('validated', configSection.value)
  } else {
    emits('inValidated')
  }
}

const validate = () => {
  const isTitleValid = configSection.value.title && configSection.value.title !== '' && configSection.value.title?.length > 8

  const isStartDateValid = configSection.value.startDate != undefined

  const isEndDateValid = configSection.value.endDate != undefined

  const isOptionSelected = configSection.value.assessmentType != undefined

  return isTitleValid && isStartDateValid && isEndDateValid && isOptionSelected
}

const showStartDateError = (): boolean => {
  return ((
          !configSection.value.startDate && configSection.value.startDateText && configSection.value.startDateText !== '')
      || (!configSection.value.startDate && props.showValidation))
}

const showEndDateError = (): boolean => {
  return ((
          !configSection.value.endDate && configSection.value.endDateText && configSection.value.endDateText !== '')
      || (!configSection.value.endDate && props.showValidation))
}

const showTitleError = (): boolean => {
  return (configSection.value.title != undefined || props.showValidation) && (configSection.value.title?.length ?? 0) < 8
}

const showAssessmentTypeError = (): boolean => {
  return (configSection.value.assessmentType == undefined && props.showValidation)
}

</script>