<template>
  <div class="p-2 space-y-8">
    <div class="">
      <NavHeading
          :heading="getHeading()"
          @back="emits('back')"
          class="py-4">

        <div v-if="canEdit()" class="flex flex-col items-end" >
          <GenericButton class="bg-olive-green text-white" @onSubmit="save">{{ getButtonHeading() }}</GenericButton>
        </div>
      </NavHeading>
    </div>

    <Card>
      <Input
          @value-changed="val => title = val"
          label="Assessment Type Title"
          placeholder="Assessment Type Title"
          :required="true"
          :disabled="!canEdit()"
          :default-value="assessmentType?.displayName"
          class="w-full"
      />
      <div v-if="titleError">
        <div class="text-red w-full text-center bg-red-lighten-5">
          The title for the assessment type is invalid, please provide a valid title with at least 10 characters
        </div>
      </div>

      <div class="text-payne-grey font-semibold pt-8">
        Welcome Message
      </div>

      <div class="border-gray-200 border-2">
              <textarea
                  v-model="welcomeMessage"
                  class="w-full h-32 p-2 resize-none overflow-auto focus:outline-none border-2 border-payne-grey text-payne-grey"
                  placeholder="Enter welcome message here"
                  :disabled="!canEdit()"/>
      </div>

      <div v-if="welcomeMessageError">
        <div class="text-red w-full text-center bg-red-lighten-5">
          The welcome message for the assessment type is invalid, please provide a valid welcome message with at least
          10 characters
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import GenericButton from "@/shared/components/GenericButton.vue";
import Card from "@/shared/components/Card.vue";
import Input from "@/components/Atomic/AQuarks/Input.vue";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessmentTypeState} from "@/areas/assessmentTypes/model/data/enums/AssessmentTypeState";
import {onMounted, ref, watch} from "vue";
import NavHeading from "@/shared/components/NavHeading.vue";

const props = defineProps<{
  assessmentType?: AssessmentTypeDto
}>()

const emits = defineEmits<{
  back: []
  save: [title: string, welcomMessage: string]
}>()

const save = () => {
  validate()

  if (!welcomeMessageError.value && !titleError.value) {
    emits('save', title.value, welcomeMessage.value)
  }
}

const title = ref<string>('')

const titleError = ref<string | undefined>(undefined)

const welcomeMessage = ref<string>('')

const welcomeMessageError = ref<string | undefined>(undefined)

const canEdit = (): boolean => {
  return (!props.assessmentType) || props.assessmentType.entityState == AssessmentTypeState.Draft
}

const getHeading = (): string => {
  if (props.assessmentType) {
    if (props.assessmentType.entityState == AssessmentTypeState.Draft) {
      return "Edit Assessment Type"
    }

    return "View Assessment Type"
  }

  return "Create Assessment Type"
}

const getButtonHeading = (): string => {
  if (props.assessmentType) {
    return 'Save'
  }

  return 'Next'
}

const validate = () => {
  validateWelcomeMessage()

  validateTitle()
}

const validateWelcomeMessage = () => {
  if (welcomeMessage.value.length < 10) {
    welcomeMessageError.value = "The welcome message needs to be a minimum of 10 characters"
  } else {
    welcomeMessageError.value = undefined
  }
}

const validateTitle = () => {
  if (title.value.length < 10) {
    titleError.value = "The welcome message needs to be a minimum of 10 characters"
  } else {
    titleError.value = undefined
  }
}

watch(() => welcomeMessage.value, () => {
  validateWelcomeMessage()
})

watch(() => title.value, () => {
  validateTitle()
})

onMounted(() => {
  if (props.assessmentType) {
    welcomeMessage.value = props.assessmentType.welcomeMessage
    title.value = props.assessmentType.displayName
  }
})

</script>