import {defineStore} from 'pinia'
import {AssessmentTypeProxy} from '@/areas/assessmentTypes/proxy/assessmentTypeProxy'
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {DomainType} from "@/areas/assessmentTypes/model/data/enums/DomainType";
import {DomainManageDo} from "@/areas/assessmentTypes/model/dos/DomainManageDo";
import {QuestionDto} from "@/areas/assessmentTypes/model/data/dto/QuestionDto";
import {QuestionManageDo} from "@/areas/assessmentTypes/model/dos/QuestionManageDo";
import {DomainDto} from "@/areas/assessmentTypes/model/data/dto/DomainDto";
import {isEqual, sortBy} from "lodash";

interface AssessmentTypeDomainStoreState {
    isLoading: boolean
    error: string | undefined
    readonly: boolean
    entityGuid: string | undefined,
    assessmentType: AssessmentTypeDto | undefined,
    domainManageDos: DomainManageDo[]
    activeDomain: DomainManageDo | undefined
}

function clearStore(): AssessmentTypeDomainStoreState {
    return {
        isLoading: false,
        error: undefined,
        readonly: false,
        entityGuid: undefined,
        assessmentType: undefined,
        domainManageDos: [],
        activeDomain: undefined
    }
}

const domainManageDos = (): DomainManageDo[] => [
    {
        domainDto: {
            key: "WEL",
            name: "Personal Rating",
            colour: '',
            domainType: DomainType.Welcome,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "A",
            name: "My Space",
            colour: '',
            domainType: DomainType.AssessDomain,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "B",
            name: "My Team",
            colour: '',
            domainType: DomainType.AssessDomain,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "C",
            name: "Our Culture",
            colour: '',
            domainType: DomainType.AssessDomain,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "D",
            name: "Our Results",
            colour: '',
            domainType: DomainType.AssessDomain,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "E",
            colour: '',
            name: "Our Growth",
            domainType: DomainType.AssessDomain,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "F",
            colour: '',
            name: "Our Purpose",
            domainType: DomainType.AssessDomain,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
    {
        domainDto: {
            key: "COM",
            name: "Completion Screen",
            colour: '',
            domainType: DomainType.Completion,
            questions: [],
        },
        isChanged: false,
        isValid: false,
        questions: []
    },
]

const buildState = (assessmentType: AssessmentTypeDto): DomainManageDo[] => {
    let newDos: DomainManageDo[] = []

    for (const domainManageDoEntry of domainManageDos()) {
        const domainManageDo = structuredClone(domainManageDoEntry) as DomainManageDo

        const domain = assessmentType
            .domains
            .find(d => d.key.toLowerCase() == domainManageDo.domainDto.key.toLowerCase() && !d.deletedAt)


        if (domain) {
            domainManageDo.domainDto = domain
            domainManageDo.isValid = true
        }

        switch (domainManageDo.domainDto.domainType) {
            case DomainType.AssessDomain:

                for (let i = 1; i <= 5; i++) {
                    const key = `${domainManageDo.domainDto.key}${i}`

                    domainManageDo.questions.push(toQuestionManageDo(key, domain))
                }

                break

            case DomainType.Welcome:

                const key = `${domainManageDo.domainDto.key}1`

                domainManageDo.questions.push(toQuestionManageDo(key, domain))

                break

            case DomainType.Completion:

                for (let i = 1; i <= 2; i++) {
                    const key = `${domainManageDo.domainDto.key}${i}`

                    domainManageDo.questions.push(toQuestionManageDo(key, domain))
                }

                break
        }

        newDos.push(domainManageDo)
    }

    return newDos
}

function toQuestionManageDo(key: string, domain?: DomainDto): QuestionManageDo {

    const existingQuestionDto = domain?.questions.find(q => q.key == key)

    const questionDto: QuestionDto = !!(existingQuestionDto)
        ? existingQuestionDto
        : {
            key: key,
            title: "",
            colour: "",
            statements: []
        }

    return {
        questionDto: questionDto,
        isValid: !!(existingQuestionDto)
    }
}

export function getDomainDisplayTitle(domain: DomainManageDo): string {
    switch (domain.domainDto.domainType) {
        case DomainType.AssessDomain:
            return `${domain.domainDto.key}. ${domain.domainDto.name}`
        case DomainType.Welcome:
        case DomainType.Completion:
            return `${domain.domainDto.name}`
        default:
            return ''
    }
}

export const useAssessmentTypeDomainStore = defineStore({
    id: "assessmentTypeDomainStore",
    state: (): AssessmentTypeDomainStoreState => clearStore(),
    getters: {
        getDisplayTitle: (state): string => {
            if (!state.activeDomain) {
                return ''
            }

            return getDomainDisplayTitle(state.activeDomain)
        },
        isDomainValid: (state): boolean => {
            if (!state.activeDomain) {
                return false
            }

            const invalidQuestion = state.activeDomain.questions.findIndex(q => !q.isValid)

            return !(!invalidQuestion || !state.activeDomain.domainDto.colour)
        }
    },
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async init(entityGuid: string, assessmentTypeGuid: string) {
            await this.withLoading(async () => {
                this.$state = clearStore()

                const fetchResult = await AssessmentTypeProxy.get(entityGuid, assessmentTypeGuid)

                if (!fetchResult.isSuccessful) {
                    this.error = "Could not retrieve assessment type at this time. Please try again later"
                    return
                }

                this.entityGuid = entityGuid
                this.assessmentType = fetchResult.content!
                this.domainManageDos = buildState(this.assessmentType!)
            })
        },

        async save() {
            await this.withLoading(async () => {
                if (!this.activeDomain) {
                    return
                }

                const invalidQuestion = this.activeDomain.questions.findIndex(q => !q.isValid)

                if (invalidQuestion != -1 || this.activeDomain.domainDto.colour == '') {
                    this.error = "The domain is not configured correctly, please have a look at the errors on the page"
                    return
                }

                const domain = this.activeDomain.domainDto

                domain.questions = this.activeDomain.questions.map(q => q.questionDto)

                const result = await AssessmentTypeProxy.addDomain(this.entityGuid!, this.assessmentType!.guid!, domain)

                if (!result.isSuccessful) {
                    this.error = "Could not update the domain at this time, please try again later"
                    return
                }

                this.activeDomain.isChanged = false
            })
        },

        setQuestionValue(questionManageDo: QuestionManageDo) {
            if (!this.activeDomain) {
                return
            }

            const questionIndex = this.activeDomain.questions!.findIndex(q => q.questionDto.key == questionManageDo.questionDto.key)

            if (questionIndex == -1) {
                return
            }

            const sortedNewQuestion = {
                ...questionManageDo.questionDto,
                statements: sortBy(questionManageDo.questionDto.statements, 'statementPerspectiveType')
            }

            const sortedExistingQuestion = {
                ...this.activeDomain.questions[questionIndex].questionDto,
                statements: sortBy(this.activeDomain.questions[questionIndex].questionDto.statements, 'statementPerspectiveType')
            }

            if (isEqual(sortedNewQuestion, sortedExistingQuestion)) {
                return
            }

            this.activeDomain.questions[questionIndex] = questionManageDo
            this.activeDomain.isChanged = true
        },

        setActiveDomainByName(name: string) {
            this.activeDomain = this.domainManageDos.find(dos => getDomainDisplayTitle(dos) == name)
        },

        setColor(color: string | undefined) {
            if (!this.activeDomain || !color) {
                return
            }

            if (this.activeDomain.domainDto.colour == color) {
                return
            }

            this.activeDomain.domainDto.colour = color
            this.activeDomain.isChanged = true
        },

        clearError() {
            this.error = undefined
        },
    }
})