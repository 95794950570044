import { inRange } from "lodash";
import {
  IRange, 
  ISharedProps,
  IAccordionProps, 
  IDemographicData, 
  IDemographicRange,
  DemographicNew
} from "@/areas/demographics/model/data/demographics";
import { DateTime } from "luxon";
import keycloak from '@/keycloak';
import { useUserStore } from '@/areas/users/store/userStore'
import { Demographic } from "@/areas/demographics/model/data/dtos/demographic";

/**@param ranges the array of ranges to check
 * @returns false if there's no overlap and true otherwise
 */
export const hasOverlap = (ranges: IRange[]) => {
  let overlapDetected = false;
  for (let i = 0; i < ranges.length - 1; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const range1 = ranges[i].value;
      const range2 = ranges[j].value;

      if (
        inRange(range2[0], range1[0], range1[1] + 1) ||
        inRange(range1[0], range2[0], range2[1] + 1)
      ) {
        overlapDetected = true;
      }
    }
  }
  return overlapDetected;
};

export const convertDemographicToAccordionParam = (
  demographicNew: DemographicNew
): IAccordionProps => {

  const { demographic, guid, isEnabled } = demographicNew;

  let values = [];

  const demographicSharedProps: ISharedProps = {
    demographicEnabled: isEnabled,
    demographicTitle: demographic.displayName,
    demographicType: demographic.demographicTypeEnum,
    guid: demographic.guid || '',
    entityDemographicGuid: guid || ''
  };

  if (demographic.demographicTypeEnum === 'NumberedRange') {
    values = demographic.demographicVersions?.flatMap(demographicVersion => 
      demographicVersion.payload.map(({ title, min, max }) => ({
        title,
        value: [min, max]
      }))
    ) || [];
  } else {
    values = demographic.demographicCategories?.map(({ guid, title }) => ({
      guid,
      title
    })) || [];
  }
    
    return {
      ...demographicSharedProps,
      values,
    };
};

export const convertDemographicToDefaultDemoGraphicsTable = (
  demographicNew: Demographic
): IAccordionProps => {
  const { isEnabled } = demographicNew;

  let values = [];

  const demographicSharedProps: ISharedProps = {
    demographicEnabled: isEnabled,
    demographicTitle: demographicNew.displayName,
    demographicType: demographicNew.demographicType,
    guid: demographicNew.guid || '',
  };

  if (demographicNew.demographicType === 'NumberedRange') {
    values = demographicNew.demographicVersions?.flatMap(demographicVersion => 
      demographicVersion.payload.map(({ title, min, max }) => ({
        title,
        value: [min, max]
      }))
    ) || [];
  } else {
    values = demographicNew.demographicVersions?.flatMap(demographicVersion => 
      demographicVersion.payload.map(({ title }) => ({
        title
      }))
    ) || [];
  }
    
    return {
      ...demographicSharedProps,
      values,
    };
};

export const fullPathMatcher = (fullPath : string)=>{
  return fullPath.match(
    /^\/dashboard\/(employees|organisation|assessments|assessment\/types)\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/
  );  
}

export const getObjectFromLocalStorage = (key: string) : any =>{

  const targetValue = localStorage.getItem(key)

  if(targetValue){
    return JSON.parse(targetValue) 
  }

  return targetValue
}

export const updateLocalStorage = (key: string, newValue: string) =>{
  localStorage.removeItem(key)
  localStorage.setItem(key, newValue)
}

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export const dateFormatter = (date: string, format = 'dd/MM/yyyy')=>{
  return DateTime.fromFormat(date, format, { zone: 'utc' })
}

export const calculateAge = (dob: Date): number => {
  const currentYear = new Date().getFullYear();
  const birthYear = dob.getFullYear();
  return currentYear - birthYear;
}

export const isValidAndAcceptableDate = (date: string)=>{
  const parsedDate = dateFormatter(date)
  if(parsedDate.isValid){
    const age = calculateAge(parsedDate.toJSDate())
    return age > 17
  }

  return false
}

export const findUserDemographicRange = (value: Date | number, ranges: IDemographicRange[] = []): IDemographicRange | null => {
  let demoRange: number;
  if (typeof value === 'number') {
    demoRange = value as number;
  } else if (value instanceof Date) {
    demoRange = calculateAge(value);
  } else {
    return null;
  }

  for (const range of ranges) {
    if (demoRange >= range.min && demoRange <= range.max) {
      return range;
    }
  }
  return null;
};

