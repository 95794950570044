<template>
  <PopupMessageDisplay>
    <div class="flex flex-row items-center justify-center">
      <div class="w-full sm:max-w-2xl">
        <Card>
          <div class="flex flex-col space-y-2">
            <div class="py-2 text-payne-grey font-semibold">
              {{ title }}
            </div>

            <div class="py-2 border-b-2 border-t-2 text-payne-grey">
              <slot></slot>
            </div>

            <div class="flex flex-row w-full justify-between">
              <button v-if="!ignoreCancel" @click="emit('cancel')"
                      class="w-1/4 inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-payne-grey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-payne-grey sm:text-sm">
                Cancel
              </button>

              <button @click="emit('confirm')"
                      class="w-1/4 inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
                {{ confirmText ? confirmText : 'Confirm' }}
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </PopupMessageDisplay>
</template>

<script setup lang="ts">

import PopupMessageDisplay from "@/shared/components/PopupMessageDisplay.vue";
import Card from "@/shared/components/Card.vue";

defineProps<{
  title: string
  confirmText?: string
  ignoreCancel?: boolean
}>()

const emit = defineEmits<{
  cancel: []
  confirm: []
}>()


</script>