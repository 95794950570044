<template>
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                :length="totalRows"
                active-color="olive-green"
                color="#000000"
                :show-first-last-page="true"
                total-visible="10"
                :update:modelValue="$emit('pageChanged', page-1)"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  <script lang="ts">
    export default {
      data () {
        return {
          page: 1,
        }
      },
      props:{
        totalRows:{
            type: Number,
            required: true
        }
      },
    }
  </script>