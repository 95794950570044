import {AssessorAction} from "@/areas/userAssessments/models/enums/AssessorAction";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {DelegateState} from "@/areas/delegates/model/enums/delegateState";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";

export class AssessmentDOExtensions {

    toDate(date: string): string {
        const convertedDate = new Date(date)

        return convertedDate.toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'})
    }

    toActionText(action: AssessorAction): string {
        if (action === AssessorAction.AddAssessors) {
            return "Please add assessors"
        } else {
            return "Please complete assessment"
        }
    }

    castToAssessmentDisplayObjectFromDelegate(delegate: DelegateDto): AssessmentDisplayObject {
        const assessmentDetails = delegate
            .assessment!
            .assessmentDetails
            .filter(det => det.deletedAt == null)
            .pop()

        return {
            guid: delegate.guid!,
            assessment: delegate.assessment!,
            delegate: delegate,
            detail: assessmentDetails!
        }
    }

    castToAssessmentDisplayObject(assessment: AssessmentDto): AssessmentDisplayObject {
        const assessmentDetails = assessment
            .assessmentDetails
            .filter(det => det.deletedAt == null)
            .pop()

        return {
            assessment: assessment,
            detail: assessmentDetails!,
            guid: assessment.guid!
        }
    }

    mapDelegateState(assessment: AssessmentDisplayObject): [string, string] {
        switch (assessment.delegate!.entityState) {
            case DelegateState.AwaitingAssessors:
                return ["bg-blue-lighten-1", "Awaiting Assessors"]

            case DelegateState.Ready:
                if (assessment.assessment.entityState == AssessmentState.Completed) {
                    return ["bg-green-lighten-1", "Completed"]
                }


                return ["bg-purple-lighten-3", "Ready to Start"]


            case DelegateState.Removed:
                return ["bg-red-lighten-1", "Removed"]
        }

        return ["bg-black", "NotMapped"]
    }

    mapColorsForAssessmentState(assessmentState: AssessmentState): [string, string] {
        switch (assessmentState) {
            case AssessmentState.Draft:
                return ["bg-blue-grey-darken-1", "Draft"]
            case AssessmentState.Active:
                return ["bg-olive-green", "Active"]

            case AssessmentState.Published:
                return ["bg-blue-darken-1", "Published"]

            case AssessmentState.Completed:
                return ["bg-blue-grey-darken-1", "Completed"]

            case AssessmentState.Cancelled:
                return ["bg-deep-orange-darken-2", "Cancelled"]
        }

        return ["bg-black", "NotMapped"]
    }

    isActive = (assessment: AssessmentDisplayObject, isDelegate: boolean): boolean => {
        return [AssessmentState.Active, AssessmentState.Published].includes(assessment.assessment.entityState) &&
            isDelegate && assessment.delegate!.selectOwnAssessors
    }

    show = (assessment: AssessmentDisplayObject): boolean => {
        return ![AssessmentState.Cancelled, AssessmentState.Expired, AssessmentState.Draft].includes(assessment.assessment.entityState)
    }
}