<template>
  <div class="w-full overflow-x-auto min-w-full align-middle space-y-4 pb-8">

    <div v-if="assessmentTypes.length > 0">
      <Card class="space-y-4">
        <SearchInput
            class="w-1/2"
            placeholder="Search Assessment Types"
            @searchUpdate="search"/>


        <table class="min-w-full divide-y divide-gray-300 border border-payne-grey font-roboto text-payne">

          <thead class="bg-box-bg font-bold text-base leading-5 border-payne-grey">

          <tr>
            <th scope="col" class="min-w-[140px] py-3.5 px-3 text-left text-payne">Title</th>
            <th scope="col" class="min-w-[140px] px-3 py-3.5 text-left text-payne">Version</th>
            <th scope="col" class="min-w-[140px] px-3 py-3.5 text-left text-payne">Created By</th>
            <th scope="col" class="min-w-[100x] px-3 py-3.5 text-left text-payne">Status</th>
            <th scope="col" class="min-w-[20px] px-3 py-3.5 text-left text-payne"></th>
          </tr>

          </thead>

          <tbody class="divide-y divide-gray-200 bg-white font-normal text-sm leading-5">
          <tr v-for="assessmentType in filteredAssessments"
              :key="assessmentType?.guid">

            <td class="whitespace-nowrap py-4 px-3 text-payne">
              {{ assessmentType.displayName }}
            </td>

            <td class="whitespace-nowrap px-3 py-4 text-payne">
              {{ assessmentType.version }}
            </td>

            <td class="whitespace-nowrap px-3 py-4 text-payne">
              {{ `${assessmentType.user?.firstName} ${assessmentType.user?.lastName}` }}
            </td>

            <td class="whitespace-nowrap px-3 py-4 text-payne">
              <Pill
                  :color="getStateColor(assessmentType.entityState!)"
                  class="py-1 sm:w-full xl:w-1/2">

                {{ assessmentType.entityState }}
              </Pill>
            </td>

            <td class="max-w-[10px] py-3.5 text-left text-payne">
              <AssessmentTypesPopover
                  :assessmentType="assessmentType"
                  @edit="emits('edit',assessmentType)"
                  @delete="emits('delete',assessmentType)"
                  @manage="emits('manage', assessmentType)"
                  @publish="emits('publish', assessmentType)"
                  @copy="emits('copy', assessmentType)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </Card>
    </div>


    <div v-if="assessmentTypes.length === 0">
      <EmptyAddCard>
        You currently have no assessment types configured for this assessment, please add an assessment type
      </EmptyAddCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import Pill from "@/shared/components/Pill.vue";
import AssessmentTypesPopover from "@/areas/assessmentTypes/components/AssessmentTypesPopover.vue";
import {AssessmentTypeState} from "@/areas/assessmentTypes/model/data/enums/AssessmentTypeState";
import Card from "@/shared/components/Card.vue";
import SearchInput from "@/shared/components/inputs/SearchInput.vue";
import EmptyAddCard from "@/shared/components/EmptyAddCard.vue";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {ref} from "vue";

const props = defineProps<{
  entityGuid: string
  assessmentTypes: AssessmentTypeDto[]
}>()

const emits = defineEmits<{
  edit: [AssessmentTypeDto]
  delete: [AssessmentTypeDto]
  manage: [AssessmentTypeDto]
  publish: [AssessmentTypeDto]
  copy: [AssessmentTypeDto]
}>()

const filteredAssessments = ref<AssessmentTypeDto[]>(props.assessmentTypes)

const getStateColor = (state: AssessmentTypeState): string => {
  if (state == AssessmentTypeState.Draft) {
    return "bg-blue-grey-darken-1"
  } else {
    return "bg-olive-green"
  }
}

const search = (searchString: string) => {
  const searchValue = searchString.toLowerCase()

  if (searchValue.length === 0) {
    filteredAssessments.value = props.assessmentTypes
  }

  filteredAssessments.value = props.assessmentTypes.filter(fa =>
      fa.displayName.toLowerCase().includes(searchValue) ||
      `${fa.user?.firstName} ${fa.user?.lastName}`.toLowerCase().includes(searchValue))
}
</script>