<template>
  <Card class="space-y-2">
    <div class="flex flex-col items-center">
      <div class="text-xl font-semibold text-payne-grey font-roboto">
        {{ displayName }}
      </div>

      <div class="text-sm font-roboto text-olive-green font-semibold">
        {{ employee.user.email }}
      </div>
    </div>

    <div class="grid grid-cols-9">
      <div class="col-span-3">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            State
          </div>

          <div class="text-base">
            <Pill :color="stateColor">{{ stateName }}</Pill>
          </div>
        </div>
      </div>

      <div
          class="col-span-3"
          :class="!isUser() ? 'md:hidden' : ''"
      />

      <div v-if="!isUser()" class="col-span-3 hidden md:block">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            Last Contact
          </div>

          <div class="text-base">
            {{ lastUpdated() }}
          </div>
        </div>
      </div>


      <div class="col-span-3">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            Team
          </div>

          <div class="text-base">
            {{ getTeamName() }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-center items-center space-y-4 ">
      <Select
          v-if="requireRelationship"
          :options="dropDownOptions"
          :isInvalid="errorMessage != null"
          :disabled="assessorDisplayObject.assessorState == AssessorState.Completed"
          @value-changed="onDropDownSelect"
          label="Select Relationship"
          :include-label-in-options="true"
          :selected="assessorDisplayObject.assessorUpsertDto.assessorRelationshipType ?? undefined"/>

      <div class="space-x-4">
        <button
            v-if="![AssessorState.Completed, AssessorState.Removed].includes(assessorDisplayObject.assessorState)"
            @click="deleteInProgress = true"
            class="text-payne-grey bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
            title="Delete Assessor">
          <img src="/img/delete_grey.svg" class="h-6" alt="Delete">
        </button>

        <button
            v-if="showReminderButton() && !isUser()"
            @click="sendReminderInProgress = true"
            class="text-payne-grey bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
            title="Send Reminder"
        >
          <img src="/img/mail.svg" class="h-5" alt="Send Reminder">
        </button>

        <button
            v-if="userStore.state && userStore.state.role != UserRole.User && [AssessorState.InProgress, AssessorState.Completed, AssessorState.Removed].includes(assessorDisplayObject.assessorState)"
            @click="emit('viewResults')"
            class="text-payne-grey bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
            title="View Assessment Results">

          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12Z"
                  fill="#7D983C"/>
          </svg>
        </button>
      </div>

      <div v-if="assessorDisplayObject.error"
           class="py-2 border-red border-2 bg-red-lighten-1 text-white w-full rounded-md text-center font-semibold">
        {{ assessorDisplayObject.error }}
      </div>

    </div>
  </Card>

  <PopupConfirmation
      v-if="deleteInProgress"
      title="Remove Assessor"
      @cancel="deleteInProgress = false"
      @confirm="confirmDelete">

    By clicking confirm below, you will remove {{ assessorDisplayObject.employee.user.firstName }} as an assessor from
    the assessment

    Are you sure you want to continue?
  </PopupConfirmation>

  <PopupConfirmation
      v-if="sendReminderInProgress"
      title="Send Reminder"
      @cancel="sendReminderInProgress = false"
      @confirm="confirmSendReminder">

    You are about to send a reminder for {{ getEmailTopic() }} to {{ assessorDisplayObject.employee.user.firstName }}. Do you want to proceed

  </PopupConfirmation>
</template>

<script setup lang="ts">
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {onMounted, Ref, ref} from "vue";
import {AssessorExtensions} from "@/areas/assessors/extensions/AssessorExtensions";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import Pill from "@/shared/components/Pill.vue";
import Select from "@/components/Atomic/AQuarks/Select.vue";
import {ISelectOptions} from "@/types/shared";
import Card from "@/shared/components/Card.vue";
import {parseStringToEnum} from "@/areas/helpers/EnumHelpers";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {useUserStore} from "@/areas/users/store/userStore";
import {UserRole} from "@/areas/users/model/enums/UserRole";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";
import {formatDateDisplay} from "@/areas/helpers/DateHelpers";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";

const props = defineProps<{
  assessorDisplayObject: AssessorDisplayObject
  requireRelationship: boolean
  assessmentState: AssessmentState
}>()

const emit = defineEmits<{
  updateRelationship: [AssessorRelationshipType]
  delete: []
  viewResults: []
  sendReminder: []
}>()

const employee = ref(props.assessorDisplayObject.employee!)

const userStore = useUserStore()

const displayName = ref(`${employee.value.user.firstName} ${employee.value.user.lastName}`)

const [stateColor, stateName] = AssessorExtensions.toOptions(props.assessorDisplayObject.assessorState)

const dropDownOptions: ISelectOptions[] = [
  {option: 'Leader', value: 'Leader'},
  {option: 'Colleague', value: 'Peer'},
  {option: 'Direct Report', value: 'DirectReport'},
]

const dropDownValue: Ref<string | undefined> = ref(undefined)

const errorMessage: Ref<string | undefined> = ref(undefined)

const deleteInProgress = ref<boolean>(false)

const sendReminderInProgress = ref<boolean>(false)

const lastUpdated = (): string => {
  if (props.assessorDisplayObject.assessor?.mails) {
    const sentEmails = props.assessorDisplayObject.assessor?.mails.filter(m => m.processedAt != undefined)

    if (sentEmails && sentEmails.length > 0) {
      return formatDateDisplay(sentEmails[0].processedAt!)
    }
  }

  return "Never"
}

const isUser = (): boolean => {
  return userStore.state != undefined && userStore.state.role == UserRole.User
}

const onDropDownSelect = (selectedReference: string) => {
  dropDownValue.value = dropDownOptions.find(option => option.value == selectedReference)?.value

  if (dropDownValue.value) {
    errorMessage.value = undefined
  }

  const relationshipType = parseStringToEnum(AssessorRelationshipType, dropDownValue.value!)

  emit('updateRelationship', relationshipType!)
}

const getTeamName = (): string => {
  const teamDemographic = employee.value.userDemographics.find(ud => ud.demographic?.displayName == "Team") ?? null

  if (teamDemographic) {
    return teamDemographic.answer
  }

  return "-"
}

const showReminderButton = ():boolean => {
  return (props.assessmentState == AssessmentState.Published && props.assessorDisplayObject.assessor?.entityState == AssessorState.Invited) ||
      (props.assessmentState == AssessmentState.Active &&
          [AssessorState.Invited, AssessorState.Accepted, AssessorState.InProgress].includes(props.assessorDisplayObject.assessorState))
}

const getEmailTopic = (): string => {
  if ([AssessmentState.Published, AssessmentState.Active] && props.assessorDisplayObject.assessor?.entityState == AssessorState.Invited) {
    return "Assessment Invitation"
  }

  return "Assessment Completion"
}

const confirmSendReminder = () => {
  sendReminderInProgress.value = false

  emit('sendReminder')
}

const confirmDelete = () => {
  deleteInProgress.value = false

  emit('delete')
}

onMounted(async () => {
  await userStore.init()
})


</script>