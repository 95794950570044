<template>
  <div class="flex font-roboto uppercase">

    <div v-for="tab in tabs"
         :key="tab"
         :class="[tab === currentTab ? 'border-dark-blue text-dark-blue cursor-pointer' : 'cursor-pointer border-transparent text-payne-grey hover:border-gray-300 hover:text-gray-700',
              'flex-grow basis-0 border-b-4 py-4 px-1 text-center text-sm font-bold']"
         @click="onTabClicked(tab)">{{ tab }}
    </div>

  </div>

</template>

<script setup lang="ts">

import {ref, watch} from "vue"

const props = defineProps<{
  tabs: string[],
  selectedTab: string
}>()

const emit = defineEmits<{
  tabChange: [tabName: string]
}>()

const currentTab = ref(props.selectedTab)

watch(
    () => props.selectedTab,
    (newVal) => {
      currentTab.value = newVal;
    },
    { immediate: true }
)

function onTabClicked(tabName: string): void {
  if (tabName === currentTab.value) {
    return;
  }

  emit('tabChange', tabName)
}

</script>