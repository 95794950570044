import {defineStore} from 'pinia'
import {AssessmentTypeProxy} from '@/areas/assessmentTypes/proxy/assessmentTypeProxy'
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessmentTypeState} from "@/areas/assessmentTypes/model/data/enums/AssessmentTypeState";
import {AssessmentTypeCopyDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeCopyDto";

interface AssessmentTypeStoreState {
    isLoading: boolean
    error: string | undefined
    entityGuid: string | undefined,
    assessmentTypes: AssessmentTypeDto[],
}

function clearStore(): AssessmentTypeStoreState {
    return {
        isLoading: false,
        error: undefined,
        entityGuid: undefined,
        assessmentTypes: [],
    }
}

export const useAssessmentTypeStore = defineStore({
    id: "assessmentType",
    state: (): AssessmentTypeStoreState => clearStore(),
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async init(entityGuid: string) {
            await this.withLoading(async () => {
                const fetchResult = await AssessmentTypeProxy.list(entityGuid)

                if (!fetchResult.isSuccessful) {
                    this.error = "Could not retrieve assessment types for this organisation"
                    return
                }

                this.entityGuid = entityGuid
                this.assessmentTypes = fetchResult.content!.filter(as => as.entityState != AssessmentTypeState.Removed)
            })
        },

        async save(title: string, welcomeMessage: string) {
            await this.withLoading(async () => {
                const assessmentTypeDto: AssessmentTypeDto = {
                    domains: [],
                    displayName: title,
                    welcomeMessage: welcomeMessage,
                    entityState: AssessmentTypeState.Draft
                }

                var createResult = await AssessmentTypeProxy.create(this.entityGuid!, assessmentTypeDto)

                if (!createResult.isSuccessful) {
                    switch (createResult.error) {
                        case "AssessmentTypeAlreadyExists":
                            this.error = "There is an existing assessment type already created with the same name and version number, please remove the assessment or create a new one with a different name"
                            break
                        default:
                            this.error = "Could not create the assessment type at this time, please try again later"
                    }
                }

                await this.init(this.entityGuid!)
            })
        },

        async copy(copyDto: AssessmentTypeCopyDto) {
            await this.withLoading(async () => {

                const assessmentTypeDto = this.assessmentTypes.find(at => at.guid == copyDto.assessmentTypeGuid)

                if (assessmentTypeDto == null) {
                    this.error = "The assessment type in question does not exist"
                    return
                }

                const result = await AssessmentTypeProxy.copy(this.entityGuid!, copyDto)

                if (!result.isSuccessful) {
                    if (result.error == "AssessmentTypeAlreadyExists") {
                        this.error = "The name used to specify the assessment type already exists, please select a different name"
                        return
                    }

                    this.error = `Could not copy the assessment results: ${result.error}`
                    return
                }

                await this.init(this.entityGuid!)
            })
        },

        async update(title: string, welcomeMessage: string, assessmentTypeGuid: string) {
            await this.withLoading(async () => {
                const assessmentTypeDto: AssessmentTypeDto = {
                    domains: [],
                    displayName: title,
                    welcomeMessage: welcomeMessage,
                    entityState: AssessmentTypeState.Draft
                }

                const updateResult = await AssessmentTypeProxy.update(this.entityGuid!, assessmentTypeGuid, assessmentTypeDto);

                if (!updateResult.isSuccessful) {
                    this.error = "Could not update the assessment type at this time, please try again later"
                }

                await this.init(this.entityGuid!)
            })
        },

        async delete(assessmentTypeGuid: string) {
            await this.withLoading(async () => {
                const result = await AssessmentTypeProxy.delete(this.entityGuid!, assessmentTypeGuid)

                if (!result.isSuccessful) {
                    this.error = "Could not delete the assessment type at this time, please try again later"
                }

                setTimeout(async () => {
                    await this.init(this.entityGuid!)
                }, 1000)
            })
        },

        async publish(assessmentTypeGuid: string) {
            await this.withLoading(async () => {
                const result = await AssessmentTypeProxy.publish(this.entityGuid!, assessmentTypeGuid)

                if (!result.isSuccessful) {
                    this.error = "The assessment could not be published. Please make sure that all components are complete before publishing"
                    return
                }

                await this.init(this.entityGuid!)
            })
        },

        clearError() {
            this.error = undefined
        },
    }
})