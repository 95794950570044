<template>
  <div v-if="!assessmentTypeStore.isLoading">
    <div v-if="!editing && !managingDomain && !copying" class="flex flex-col space-y-4">
      <AssessmentTypeHeader @add="editing = true"/>

      <AssessmentTypeTable
          :entity-guid="entityGuid"
          :assessment-types="assessmentTypeStore.assessmentTypes"
          @edit="edit"
          @delete="val => deletingAssessment = val"
          @manage="val => managingDomain = val"
          @publish="val => publish(val)"
          @copy="val => copying = val"
      />
    </div>

    <AssessmentTypeCreate
        :assessment-type="editingAssessment"
        v-if="editing"
        @back="back()"
        @save="saveAssessmentType"/>

    <DomainManager
        v-if="managingDomain"
        :assessment-type="managingDomain"
        :entity-guid="entityGuid"
        @back="managingDomain = undefined"
    />

    <AssessmentTypeCopy
        v-if="copying"
        :entity-guid="entityGuid"
        :assessment-type-dto="copying!"
        @back="copying = undefined"
        @copy="copy"/>
  </div>

  <LoadingCard :is-loading="assessmentTypeStore.isLoading"/>

  <PopupErrorMessage
      v-if="assessmentTypeStore.error"
      :message="assessmentTypeStore.error"
      @closed="assessmentTypeStore.clearError()"/>

  <PopupConfirmation
      v-if="deletingAssessment"
      :title="`Deleting Assessment Type`"
      @cancel="deletingAssessment = undefined"
      @confirm="async () => await deleteAssessmentType()">

    Are you sure you want to delete the Assessment Type with the title: {{ deletingAssessment.displayName }}?

  </PopupConfirmation>


</template>

<script setup lang="ts">
import AssessmentTypeHeader from "@/areas/assessmentTypes/components/AssessmentTypeHeader.vue";
import AssessmentTypeTable from "@/areas/assessmentTypes/components/AssessmentTypeTable.vue";
import {onMounted, ref, watch} from "vue";
import AssessmentTypeCreate from "@/areas/assessmentTypes/components/AssessmentTypeCreate.vue";
import {AssessmentTypeDto} from "../model/data/dto/AssessmentTypeDto";
import {useAssessmentTypeStore} from "@/areas/assessmentTypes/store/assessmentTypeStore";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";
import DomainManager from "@/areas/assessmentTypes/components/DomainManager.vue";
import AssessmentTypeCopy from "@/areas/assessmentTypes/components/AssessmentTypeCopy.vue";
import {AssessmentTypeCopyDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeCopyDto";

const props = defineProps<{
  entityGuid: string
}>()

const emits = defineEmits <{
  showHeading: [boolean]
}>()

const editing = ref<boolean>(false)

const assessmentTypeStore = useAssessmentTypeStore()

const editingAssessment = ref<AssessmentTypeDto | undefined>(undefined)

const copying = ref<AssessmentTypeDto | undefined>(undefined)

const deletingAssessment = ref<AssessmentTypeDto | undefined>(undefined)

const managingDomain = ref<AssessmentTypeDto | undefined>(undefined)

const saveAssessmentType = async (title: string, welcomeMessage: string) => {
  if (!editingAssessment.value) {
    await assessmentTypeStore.save(title, welcomeMessage)
  } else {
    await assessmentTypeStore.update(title, welcomeMessage, editingAssessment.value.guid!)
  }

  if (!assessmentTypeStore.error) {
    if (editingAssessment.value) {
      Toast.success("Assessment Type updated successfully", toastOptions)
    } else {
      Toast.success("Assessment Type created successfully", toastOptions)
    }
  }

  editingAssessment.value = undefined
  editing.value = false
}

const deleteAssessmentType = async () => {
  if (!deletingAssessment.value) {
    return
  }

  await assessmentTypeStore.delete(deletingAssessment.value.guid!)

  deletingAssessment.value = undefined

  if (!assessmentTypeStore.error) {
    Toast.success("Assessment Type deleted successfully", toastOptions)
  }
}

const edit = (assessmentType: AssessmentTypeDto) => {
  editingAssessment.value = assessmentType
  editing.value = true
}

const hideHeadingEmit = () => {
  if (!editing.value && !managingDomain.value && !copying.value) {
    emits('showHeading', true)
    return
  }

  emits('showHeading', false)
}

const publish = async (assessmentType: AssessmentTypeDto) => {
  await assessmentTypeStore.publish(assessmentType.guid!)

  if (!assessmentTypeStore.error) {
    Toast.success("Assessment Type was successfully published", toastOptions)
  }
}

const copy = async (copyDto: AssessmentTypeCopyDto) => {
  await assessmentTypeStore.copy(copyDto)

  if (!assessmentTypeStore.error) {
    copying.value = undefined
    Toast.success("Assessment Type copied successfully", toastOptions)
  }
}

const back = () => {
  editingAssessment.value = undefined
  editing.value = false
}

watch (() => editing.value, () =>{
  hideHeadingEmit()
})

watch (() => managingDomain.value, () =>{
  hideHeadingEmit()
})

watch (() => copying.value, () =>{
  hideHeadingEmit()
})

onMounted(async () => {
  await assessmentTypeStore.init(props.entityGuid)
})

</script>