<template>
  <div class="flex flex-col">
    <p :class="`pb-3 font-semibold ${!isInvalidRef ? labelColor: 'text-red'}`">{{ label }}</p>
    <input
        type="text"
        v-model="value"
        @input="handleInput"
        @focus="$emit('input-on-focus')"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :class="`w-full pl-0 border-0 py-1.5 font-roboto text-payne-grey focus:ring-0 placeholder-gray-400
                    ${isInvalidRef ? 'placeholder:text-red': ''} ${disabled ? 'hover:cursor-not-allowed' : ''}`"
    />
    <div :class="`border-t peer-focus:border-t-2 peer-focus:border-payne-grey
            ${isInvalidRef  ? 'border-red' : 'border-payne-grey'}`"
         aria-hidden="true"/>

    <div v-if="isInvalidRef && !customErrorShow" class="text-red text-xs">
      Please complete this field
    </div>
  </div>

</template>
<script lang="ts">
import {defineComponent, ref, computed, watch} from 'vue';

export default defineComponent({
  name: 'Input',
  props: {
    label: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: 'text-payne-grey'
    },
    placeholder: {
      type: String,
      default: 'Enter value'
    },
    required: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String,
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customErrorShow: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const value = ref<string>(props.defaultValue)
    // const isInvalidRef = ref<boolean>(props.isInvalid && props.required)

    // const handleOnChange = ()=>{
    //     context.emit('value-changed', value.value)
    // }

    const isInvalidRef = computed(() => {
      // If the field is not required, it's never invalid based on emptiness
      if (!props.required) return false;

      // If the field is required but has a value, it's valid
      // This assumes empty string ('') is considered invalid
      if (value.value) return false;

      // Use the prop to determine validity otherwise
      return props.isInvalid;
    });

    const handleInput = (event: any) => {
      context.emit('value-changed', event.target.value);
    };

    watch(() => props.isInvalid, (newVal) => {
      //@ts-ignore
      isInvalidRef.value = newVal;
    });

    watch(() => props.defaultValue, (newVal) => {
      value.value = newVal;
    }, {immediate: true});

    return {
      value,
      isInvalidRef,
      handleInput,
    }
  }
})
</script>

<style scoped>
/* Target the placeholder within inputs of the component */
.text-sm::placeholder {
  color: #c0c0c0; /* Lighter gray placeholder text */
}
</style>