<template>
  <Collapsable :is-open="isOpen" :title="computeTitle" @isOpen="val => emits('isOpen', val)">
    <div class="flex flex-col border-2 rounded-sm px-2 py-2 text-payne-grey">

      <div class="grid grid-cols-8 py-2">
        <div class="col-span-3">
          <Input
              @value-changed="val => title = val"
              label="Question Title"
              placeholder="Enter a title for the question"
              :required="true"
              :disabled="!canEdit"
              :default-value="title"/>

          <div v-if="titleError" class="text-red w-full text-center bg-red-lighten-5">
            {{ titleError }}
          </div>
        </div>

        <div class="col-span-2">

        </div>

        <div class="col-span-3">
          <ColorPicker
              v-model="color"
              :color="color"
              label="Question Color"
              placeholder="Select a color for the question"
              :disabled="!canEdit"
          />

          <div v-if="colorError" class="text-red w-full text-center bg-red-lighten-5">
            {{ colorError }}
          </div>
        </div>
      </div>

      <div class="text-left text-[18px] sm:text-2xl text-payne-grey font-semibold py-8">
        Question Statements
      </div>

      <div class="grid grid-cols-8 py-2">

        <div class="col-span-3">
          <Input
              @value-changed="val => firstHead = val"
              label="First Person Head Statement"
              placeholder="Enter a first person head statement"
              :required="true"
              :disabled="!canEdit"
              :default-value="firstHead"
              :custom-error-show="true"
          />

          <div v-if="firstHeadError" class="text-red w-full text-center bg-red-lighten-5">
            {{ firstHeadError }}
          </div>
        </div>

        <div class="col-span-2">

        </div>

        <div class="col-span-3">
          <Input
              @value-changed="val => firstHeart = val"
              label="First Person Heart Statement"
              label-color="text-olive-green"
              placeholder="Enter a first person heart statement"
              :required="true"
              :disabled="!canEdit"
              :default-value="firstHeart"/>

          <div v-if="firstHeartError" class="text-red w-full text-center bg-red-lighten-5">
            {{ firstHeartError }}
          </div>

        </div>
      </div>

      <div class="grid grid-cols-8 py-2">
        <div class="col-span-3">
          <Input
              @value-changed="val => thirdHead = val"
              label="Third Person Head Statement"
              placeholder="Enter a third person head statement"
              :required="true"
              :disabled="!canEdit"
              :default-value="thirdHead"/>

          <div v-if="thirdHeadError" class="text-red w-full text-center bg-red-lighten-5">
            {{ thirdHeadError }}
          </div>
        </div>

        <div class="col-span-2">

        </div>

        <div class="col-span-3">
          <Input
              @value-changed="val => thirdHeart = val"
              label="Third Person Heart Statement"
              label-color="text-olive-green"
              placeholder="Enter a third person heart statement"
              :required="true"
              :disabled="!canEdit"
              :default-value="thirdHeart"/>

          <div v-if="thirdHeartError" class="text-red w-full text-center bg-red-lighten-5">
            {{ thirdHeartError }}
          </div>

        </div>
      </div>

    </div>
  </Collapsable>
</template>

<script setup lang="ts">

import Collapsable from "@/shared/components/Collapsable.vue";
import Input from "@/components/Atomic/AQuarks/Input.vue";
import {computed, onMounted, ref, watch} from "vue";
import {StatementDto} from "@/areas/assessmentTypes/model/data/dto/StatementDto";
import {StatementPerspectiveType} from "@/areas/assessmentTypes/model/data/enums/StatementPerspectiveType";
import ColorPicker from "@/shared/components/inputs/ColorPicker.vue";
import {QuestionManageDo} from "@/areas/assessmentTypes/model/dos/QuestionManageDo";

const props = defineProps<{
  canEdit: boolean
  isOpen: boolean;
  question: QuestionManageDo
  validate: boolean
}>()

const emits = defineEmits<{
  isOpen: [boolean],
  update: [QuestionManageDo]
}>()

const totalChars = 1

const title = ref<string | undefined>(undefined)

const color = ref<string | undefined>()

const firstHead = ref<string | undefined>(undefined)

const firstHeart = ref<string | undefined>(undefined)

const thirdHead = ref<string | undefined>(undefined)

const thirdHeart = ref<string | undefined>(undefined)

const titleError = ref<string | undefined>(undefined)

const colorError = ref<string | undefined>(undefined)

const firstHeadError = ref<string | undefined>(undefined)

const firstHeartError = ref<string | undefined>(undefined)

const thirdHeadError = ref<string | undefined>(undefined)

const thirdHeartError = ref<string | undefined>(undefined)

const startingUp = ref<boolean>(true)

const findStatement = (statementPerspectiveType: StatementPerspectiveType): StatementDto | undefined => {
  return props.question.questionDto.statements.find(s => s.statementPerspectiveType == statementPerspectiveType)
}

const computeTitle = computed<string>(() => {
  return !!(title.value) ? `${props.question.questionDto.key}. ${title.value}` : `${props.question.questionDto.key}. NOT SET`
})

watch(() => title.value, () => {
  if (!title.value || title.value.length <= totalChars) {
    titleError.value = `The title for the question needs at least ${totalChars} characters`
  } else {
    titleError.value = undefined
  }

  validate()
})

watch(() => color.value, () => {
  if (!color.value || color.value == '') {
    colorError.value = "Please select a color for this question"
  } else {
    colorError.value = undefined
  }

  validate()
})

watch(() => firstHead.value, () => {
  if (!firstHead.value || firstHead.value.length <= totalChars) {
    firstHeadError.value = `The first head statement needs at least ${totalChars} characters`
  } else {
    firstHeadError.value = undefined
  }

  validate()
})

watch(() => firstHeart.value, () => {
  if (!firstHeart.value || firstHeart.value.length <= totalChars) {
    firstHeartError.value = `The first heart statement needs at least ${totalChars} characters`
  } else {
    firstHeartError.value = undefined
  }

  validate()
})

watch(() => thirdHead.value, () => {
  if (!thirdHead.value || thirdHead.value.length <= totalChars) {
    thirdHeadError.value = `The third head statement needs at least ${totalChars} characters`
  } else {
    thirdHeadError.value = undefined
  }

  validate()
})

watch(() => thirdHeart.value, () => {
  if (!thirdHeart.value || thirdHeart.value.length <= totalChars) {
    thirdHeartError.value = `The third heart statement needs at least ${totalChars} characters`
  } else {
    thirdHeartError.value = undefined
  }

  validate()
})

const validate = () => {
  let isValid = true

  if (!title.value || title.value.length <= totalChars) {
    isValid = false

    if (props.validate) {
      titleError.value = `The title for the question needs at least ${totalChars} characters`
    }
  }

  if (!color.value) {
    isValid = false

    if (props.validate) {
      colorError.value = "Please select a color for this question"
    }
  }

  if (!firstHead.value || firstHead.value.length <= totalChars) {
    isValid = false

    if (props.validate) {
      firstHeadError.value = `The first head statement needs at least ${totalChars} characters`
    }
  }

  if (!firstHeart.value || firstHeart.value.length <= totalChars) {
    isValid = false

    if (props.validate) {
      firstHeartError.value = `The first heart statement needs at least ${totalChars} characters`
    }
  }

  if (!thirdHead.value || thirdHead.value.length <= totalChars) {
    isValid = false

    if (props.validate) {
      thirdHeadError.value = `The third head statement needs at least ${totalChars} characters`
    }
  }

  if (!thirdHeart.value || thirdHeart.value.length <= totalChars) {
    isValid = false

    if (props.validate) {
      thirdHeartError.value = `The third heart statement needs at least ${totalChars} characters`
    }
  }

  send(isValid)
}

const send = (isValid: boolean) => {
  const questionManageDo: QuestionManageDo = {
    isValid: isValid,
    questionDto: {
      guid: props.question.questionDto.guid,
      key: props.question.questionDto.key,
      colour: color.value!,
      title: title.value!,
      statements: [
        {
          statementPerspectiveType: StatementPerspectiveType.First,
          head: firstHead.value,
          heart: firstHeart.value
        },
        {
          statementPerspectiveType: StatementPerspectiveType.Third,
          head: thirdHead.value,
          heart: thirdHeart.value
        }
      ]
    }
  }

  if (!startingUp.value) {
    emits('update', questionManageDo)
  }
}

onMounted(() => {
  startingUp.value=true

  title.value = props.question.questionDto.title == '' ? undefined : props.question.questionDto.title
  color.value = props.question.questionDto.colour == '' ? undefined : props.question.questionDto.colour

  firstHead.value = findStatement(StatementPerspectiveType.First)?.head
  firstHeart.value = findStatement(StatementPerspectiveType.First)?.heart

  thirdHead.value = findStatement(StatementPerspectiveType.Third)?.head
  thirdHeart.value = findStatement(StatementPerspectiveType.Third)?.heart

  validate()

  startingUp.value=false
})

</script>