<template>
  <div class="flex flex-col w-full">

    <p :class="`pb-3 font-semibold ${!error ? 'text-payne-grey': 'text-red'}`">{{ label }}</p>

    <div class="flex flex-row justify-center items-center">
      <input v-model="selectedColor" type="text"
             class="w-full border-0 px-0 py-1.5 font-roboto text-payne-grey focus:ring-0"
             :disabled="disabled"
             :class="disabled ? 'hover:cursor-not-allowed': ''"
             :placeholder="placeholder"/>

      <div @click="onClick()" id="chooseColourButton"
           :style="{ backgroundColor: selectedColor }"
           class="right-2 border border-black w-6 h-6 rounded-sm drop-shadow-sm z-50"
           :class="disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'"/>

      <div v-if="showPicker" ref="colorPicker" v-click-outside="clickedOutside"
           class="absolute z-50 drop-shadow-md rounded-md bg-white right-10 top-[25%] shadow-lg">
        <v-color-picker hide-inputs v-model="selectedColor"></v-color-picker>
      </div>
    </div>

    <div :class="`border-t peer-focus:border-t-2 peer-focus:border-payne-grey
            ${error  ? 'border-red' : 'border-payne-grey'}`"/>

    <div v-if="error" class="text-red text-xs">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">

import {ref, watch} from "vue";
import Input from "@/components/Atomic/AQuarks/Input.vue";

const props = defineProps<{
  label?: string
  error?: string
  color?: string
  placeholder?: string
  disabled: boolean
}>()

const emits = defineEmits<{
  'back': [boolean]
  'update:modelValue': [string?]
}>()

const clickedOutside = () => {
  showPicker.value = false
}

const selectedColor = ref<string | undefined>(props.color)

const preventEmit = ref<boolean>(false)

const showPicker = ref<boolean>(false)

const onClick = () => {
  if (props.disabled) {
    return
  }

  showPicker.value = !showPicker.value
}

watch(() => selectedColor.value, (newValue: string | undefined) => {
  if (!preventEmit.value) {
    emits('update:modelValue', newValue)
  }

  preventEmit.value = false
})


watch(() => props.color, (newValue: string | undefined) => {
  if (selectedColor.value != props.color) {
    preventEmit.value = true

    selectedColor.value = newValue
  }
})

</script>