<template>
  <button
      @click="emit('onSubmit')"
      :disabled="disabled"
      class="w-full font-semibold py-2 px-4 rounded shadow-lg"
      :class="[
          disabled ? 'text-black bg-gray-400 hover:cursor-not-allowed' : 'hover:scale-105 focus:outline-none focus:shadow-outline active:scale-95 transition duration-100 uppercase hover:cursor-pointer',
          bgColor ? bgColor : 'bg-olive-green text-white']">
    <slot></slot>
  </button>
</template>

<script setup lang="ts">

defineProps<{
  disabled?: boolean
  bgColor?: string
}>()

const emit = defineEmits<{
  onSubmit: [],
}>()

</script>