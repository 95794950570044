<template>
    <AppLayout>
        <DefaultDemographicsHeader class="sm:pt-8"/>
        <div class="w-full px-6 pt-8">
            <DemographicsTable/>
        </div>
    </AppLayout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import DefaultDemographicsHeader from '@/components/Atomic/BAtoms/Demographics/DefaultDemographicsHeader.vue'
import DemographicsTable from '@/components/Atomic/AQuarks/DemographicsTable.vue'

export default defineComponent({
    name: 'DefaultDemographics',
    components: {
        AppLayout,
        DefaultDemographicsHeader,
        DemographicsTable        
    }
})
</script>