import axiosInstance from "@/axiosConfig";
import {Operations} from "@/areas/helpers/responses/operations";
import {DelegateCreateDto} from "../model/dtos/delegateCreateDto";
import {AssessorUpsertDto} from "../../assessors/models/dtos/assessorUpsertDto";
import {OperationResult, OperationResultOnly} from "@/areas/helpers/responses/models/operationResult";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";

export class DelegateProxy {
    public async create(entityGuid: string, assessmentGuid: string, delegateCreateDto: DelegateCreateDto): Promise<OperationResult<DelegateDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/delegates`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.post<DelegateDto>(uri, delegateCreateDto)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async update(entityGuid: string, assessmentGuid: string, delegateGuid: string, assessorUpsertDtos: AssessorUpsertDto[]): Promise<OperationResult<DelegateDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/delegates/${delegateGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.put<DelegateDto>(uri, assessorUpsertDtos)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async get(entityGuid: string, assessmentGuid: string, delegateGuid: string): Promise<OperationResult<DelegateDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/delegates/${delegateGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<DelegateDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async sendReminder(entityGuid: string, assessmentGuid: string, delegateGuid: string): Promise<OperationResultOnly> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/delegates/${delegateGuid}/mails/reminders`

        return await Operations.WrapRequestOnly(async () => {
            await axiosInstance.put(uri)

            return Operations.ToSuccessResponseOnly()
        })
    }
    
    public async list(entityGuid: string, assessmentGuid: string): Promise<OperationResult<DelegateDto[]>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/delegates`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.get<DelegateDto[]>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

    public async delete(entityGuid: string, assessmentGuid: string, delegateGuid: string): Promise<OperationResult<DelegateDto>> {
        const uri = `/entities/${entityGuid}/assessments/${assessmentGuid}/delegates/${delegateGuid}`

        return await Operations.WrapRequest(async () => {
            const response = await axiosInstance.delete<DelegateDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        })
    }

}