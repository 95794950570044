<template>
  <AppLayout>
    <div class="px-4 flex flex-col w-full mx-auto sm:max-w-2xl min-h-full space-y-4">

      <div v-if="tabState !== AssessmentTabState.Assessment">
        <div class="text-2xl text-payne-grey font-roboto font-semibold items-center flex justify-center pt-4">
          <h1>My Assessments</h1>
        </div>

        <TabNavigation :selected-tab="selectedTab" :tabs="tabs" @tabChange="onTabChange"/>
      </div>

      <UserAssessmentList
          v-if="tabState === AssessmentTabState.ToComplete"
          :entity-guid="entityGuid"
          @startAssessment="startAssessment"
      />

      <UserDelegateList
          v-if="tabState === AssessmentTabState.Mine"
          :entity-guid="entityGuid"
      />

      <AnswerForm
          v-if="tabState === AssessmentTabState.Assessment"
          :entity-guid="entityGuid"
          :readonly="false"
          :assessment-display-object="assessmentExtensions.castToAssessmentDisplayObject(userAssessmentToComplete!.assessment)"
          :delegate="userAssessmentToComplete!.delegate"
          :assessor="userAssessmentToComplete!.assessor"
          :show-header="true"
          @exit="onTabChange('To Complete')"
          @saved="savedAssessment"
      />

    </div>
  </AppLayout>
</template>x

<script setup lang="ts">

import {onMounted, Ref, ref} from "vue";
import AppLayout from "@/components/Layouts/AppLayout.vue";
import TabNavigation from "@/shared/components/TabNavigation.vue";
import UserAssessmentList from "@/areas/userAssessments/components/UserAssessmentList.vue";
import {useRoute} from "vue-router";
import {AssessmentTabState} from "@/areas/userAssessments/models/enums/AssessmentTabState";
import UserDelegateList from "@/areas/userAssessments/components/UserDelegateList.vue";
import {UserAssessmentDto} from "@/areas/assessments/model/dtos/UserAssessmentDto";
import AnswerForm from "@/areas/userAssessments/components/AnswerForm.vue";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";

const tabs = ref(['Mine', 'To Complete'])

const assessmentExtensions = new AssessmentDOExtensions()

const route = useRoute()

const tabState: Ref<AssessmentTabState> = ref(AssessmentTabState.Mine)

const selectedTab = ref<string> ('Mine')

const entityGuid = ref<string>(route.params.entityGuid as string)

const userAssessmentToComplete: Ref<UserAssessmentDto | undefined> = ref(undefined)

const startAssessment = (userAssessment: UserAssessmentDto) => {
  tabState.value = AssessmentTabState.Assessment

  userAssessmentToComplete.value = userAssessment
}

const onTabChange = (tabName: string) => {
  if (tabName === "Mine") {
    selectedTab.value = 'Mine'
    tabState.value = AssessmentTabState.Mine
  } else {
    selectedTab.value = 'To Complete'
    tabState.value = AssessmentTabState.ToComplete
  }
}

const savedAssessment = () => {
  tabState.value = AssessmentTabState.ToComplete
  Toast.success("Assessment submission saved successfully", toastOptions)
}

onMounted(async () => {
  onTabChange('To Complete')
})

</script>