<template>
    <div class="relative" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
      <slot></slot>
      <div v-if="showTooltip"  :class="`absolute drop-shadow-md z-40 p-2 bg-wine-red rounded-lg top-10 left-0 ${customCss ? customCss : 'text-white'}`">
        {{ text }}
      </div>
    </div>
  </template>
  
  <script lang="ts">
  export default {
    props: {
      text: String, // The text displayed in the tooltip
      customCss: String,
    },
    data() {
      return {
        showTooltip: false,
      };
    },
  };
  </script>
  