<template>
  <div class="flex flex-row w-full justify-between">
    <div class="flex-col font-bold text-lg text-payne-grey">
      {{ "ORGANISATION EMPLOYEES" }}
    </div>
    <div class="flex flex-row justify-end">
      <EmployeeButtons/>
    </div>
  </div>
  <div class="flex flex-row w-full border-b rounded-sm drop-shadow-sm bg-white border-gray-200 px-10 pt-8">
    <EmployeeTable v-show="hasUsers" @employees-exist="handleEmployeesExist"/>
    <EmptyEmployeeList
      v-if="!hasUsers"
      text="You do not yet have any employees added to your organisation.">
        <div class="flex py-10 flex-row justify-center">
          <EmployeeButtons />
        </div>
    </EmptyEmployeeList>

  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import EmployeeButtons from '../BAtoms/Employees/EmployeeButtons.vue';
import EmptyEmployeeList from '../BAtoms/Employees/TabEmptyState.vue';
import Table from '../BAtoms/Employees/Table.vue';
export default defineComponent({
  components:{
    EmptyEmployeeList,
    EmployeeButtons,
    EmployeeTable: Table
  },
  props: {
    hasUsers: {
      type: Boolean,
      default: true
    },
  },
  setup(props, context){
    const hasUsers = ref(props.hasUsers)
    const handleEmployeesExist = () => {
      hasUsers.value = true; // Or perform any other action needed
    };
    
    return {
      hasUsers,
      // uploadUsers,
      // handleCsvUploadSuccess,
      handleEmployeesExist
    }
  }
})
</script>