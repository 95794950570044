<template>
  <div class="w-full z-20 font-roboto bg-white">
    <div class="flex flex-col gap-5 justify-center w-full">
        <p class="text-lg text-payne text-center font-weight-[400] leading-[21.09px] ">
          {{ text }}
        </p>
        <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import EmployeeButtons from './EmployeeButtons.vue';

export default defineComponent({
    components: {
        EmployeeButtons
    },
    props:{
        text: {
            type: String,
            default: ''
        }
    }
})
</script>