<template>
  <Modal
    justify="start"
    :modal-width="25"
    classes="py-0 px-0 modal"
    :show-x-icon="false"
    class="ml-0 w-full"
    :modal-title="`Are you sure you want to remove ${demographicTitle}?`">
    <template v-slot:modal_activator>
       <div>Delete {{ demographicTitle }}</div>
    </template>
    <div class="w-full">
      <p class="text-sm font-roboto">
        You are about to permanently delete the {{ demographicTitle }} demographic.
        This action <span class="font-bold">cannot be undone.</span>
      </p>
      <hr class="my-5">
      <div class="flex justify-between px-4 pb-4">
        <v-btn class="bg-white text-payne-grey" @click="handleCancel">
          Cancel
        </v-btn>
        <v-btn class="bg-red text-white" @click="handleDeleteDemographic(index!)">
          Remove
        </v-btn>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '../../AQuarks/Modal.vue';
import bus from '@/bus'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    demographicTitle: String,
    index: Number
  },
  emits: ['deleteDemographic'],
  methods: {
    handleCancel() {
      bus.emit('close-popover-menu')
    },
    handleDeleteDemographic(index: number) {
      this.$emit('deleteDemographic', this.index);
    }
  }
});
</script>