<template>
  <div class="flex flex-col items-center">
    <div class="text-xl font-semibold text-payne-grey font-roboto">
      {{ employee.user.firstName }} {{ employee.user.lastName }}
    </div>

    <div class="text-sm font-roboto text-olive-green font-semibold">
      {{ employee.user.email || '-' }}
    </div>
  </div>

  <div class="grid grid-cols-7">
    <div class="col-span-3">
      <div class="flex flex-col items-center text-payne-grey">
        <div class="font-semibold">
          Team
        </div>

        <div class="text-base">
          {{ getTeamName() }}
        </div>
      </div>
    </div>

    <div class="col-span-1"></div>

    <div class="col-span-3 items-center">
      <div class="flex flex-col items-center text-payne-grey">
        <div class="font-semibold">
          Relationship
        </div>

        <div class="text-base">
          {{ getRelationShipDisplayName() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";

const props = defineProps<{
  relationship?: AssessorRelationshipType
  employee: EmployeeDto
}>()

const getTeamName = (): string => {
  const teamDemographic = props.employee.userDemographics.find(ud => ud.demographic?.displayName == "Team") ?? null

  if (teamDemographic) {
    return teamDemographic.answer
  }

  return "-"
}

const getRelationShipDisplayName = (): string => {
  if (props.relationship) {
    switch (props.relationship) {
      case AssessorRelationshipType.Leader:
        return "Leader"
      case AssessorRelationshipType.Peer:
        return "Colleague"
      case AssessorRelationshipType.DirectReport:
        return "Direct Report"
    }
  }

  return ""
}

</script>