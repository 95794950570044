import {defineStore} from "pinia";
import {UserProxy} from "../proxy/userProxy";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import keycloak from "@/keycloak";
import {UserState} from "@/areas/users/model/state/UserState";
import {KeycloakTokenParsed} from "keycloak-js";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {User} from "@/areas/users/model/data/User";
import {useEntityStore} from "@/areas/entities/store/entityStore";
import {Operations} from "@/areas/helpers/responses/operations";
import {EntityProxy} from "@/areas/entities/proxy/entityProxy";


interface IState {
    proxy: UserProxy
    state?: UserState | null
}

function getRole(loggedInUser: KeycloakTokenParsed): UserRole | null
{
    if (!loggedInUser.realm_access?.roles) {
        return null
    }

    const roles = loggedInUser.realm_access.roles

    if (roles.includes("SuperAdmin"))
        return UserRole.SuperAdmin

    if (roles.includes("OrganisationAdmin"))
        return UserRole.OrganisationAdmin

    return UserRole.User
}

function restoreUserState(): UserState | undefined {
    const storedState = localStorage.getItem('userStore');
    if (!storedState) return undefined;

    const parsedState: Partial<UserState> = JSON.parse(storedState);

    return {
        user: parsedState.user as User,
        activeEntity: parsedState.activeEntity as Entity,
        role: UserRole[parsedState.role as keyof typeof UserRole],
    };
}

function clearUserStore(): void {
    localStorage.removeItem('userStore')
}

function saveToLocalStorage(userState: UserState) {
    localStorage.setItem('userStore', JSON.stringify(userState));
}

export const useUserStore = defineStore({
    id: "users",
    state: (): IState => ({
        proxy: new UserProxy(),
        state: null,
    }),

    actions: {
        async init(force: boolean = false): Promise<OperationResult<UserState>> {
            if (this.state == null && !force) {
                this.state = restoreUserState()
            }

            const loggedInUser = keycloak.tokenParsed;

            if (loggedInUser == undefined) {
                clearUserStore()
                return Operations.ToErrorResponse("UserNotLoggedIn")
            }

            if (this.state != null && !force && this.state.user && this.state.user.guid && loggedInUser.sub == this.state.user.guid) {
                return Operations.ToSuccessResponse(this.state)
            } else {
                clearUserStore()
            }

            const role = getRole(loggedInUser)

            if (role == null) {
                return Operations.ToErrorResponse("UserHasNoRoles")
            }

            const userRetrieveResult = await this.proxy.getUser();

            if (!userRetrieveResult.isSuccessful) {
                return Operations.ToErrorResponse(userRetrieveResult.error!)
            }

            const user = userRetrieveResult.content!

            const entity = user.entities.length > 0 ? user.entities[0] : null

            if (entity == null) {
                return Operations.ToErrorResponse("UserHaveNoEntities")
            }

            const entityProxy = new EntityProxy()

            const entityResult = await entityProxy.fetchEntity(entity.guid!)

            if (!entityResult.isSuccessful) {
                return Operations.ToErrorResponse("EntityNotFound")
            }

            this.state = {
                user: user,
                role: role,
                activeEntity: entityResult.content!
            }

            saveToLocalStorage(this.state)

            return Operations.ToSuccessResponse(this.state)
        },

        async setActiveEntityByGuid (entityGuid: string) {
            const entityStore= useEntityStore()

            const result = await entityStore.fetchEntity(entityGuid);

            if (result.isSuccessful) {
                await this.setActiveEntity(result.content!)
            }
        },

        async setActiveEntity(entity: Entity) {
            const result = await this.init()

            if (!result.isSuccessful) {
                throw new Error(`This should not be possible: ${result.error}`)
            }

            this.state!.activeEntity = entity

            saveToLocalStorage(this.state!)
        },

        async listUserEntities(): Promise<OperationResult<Entity[]>> {
            return await this.proxy.getUserEntities()
        },

        async getState(): Promise<UserState> {
            const result = await this.init()

            if (!result.isSuccessful) {
                throw new Error(`This should not be possible: ${result.error}`)
            }

            return this.state!
        }
    },

})