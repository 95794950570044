<template>
    <div class="rounded-md bg-red p-1 mb-5">
      <div class="flex my-1">
        <div class="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-[12px] font-medium">
            {{ message }}
          </h3>
        </div>
      </div>
    </div>
  </template>
    
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'Banner',
    props: {
      showDismiss: {
        type: Boolean,
        default: false
      },
      message: {
        type: String,
        default: 'You have one or more overlapping ranges, please resolve this by adjusting the values before adding this demographic'
      }
    }
  })
  </script>
