<template>
  <div class="grid grid-cols-2 gap-8">
    <Input
        :default-value="details.firstName.value"
        :is-invalid="details.firstName.isValid"
        :key="fieldValidationKey"
        @value-changed="value=> {
                details.firstName.value = value;
                details.firstName.isValid = value === '';
                inputValueChanged();
            }"
        label="* First Name"
        placeholder="Enter first name"
        :required="true"
        class="w-[90%]"/>

    <Input
        :default-value="details.lastName.value"
        :is-invalid="details.lastName.isValid"
        :key="fieldValidationKey"
        @value-changed="(value)=> {
                details.lastName.value = value;
                details.lastName.isValid = value === '';
                inputValueChanged();
            }"
        label="* Last Name"
        placeholder="Enter last name"
        :required="true"
        class="w-[90%]"/>

    <div class="w-[90%]">
      <Input
          :default-value="details.email.value"
          :is-invalid="details.email.isValid"
          :key="fieldValidationKey"
          @value-changed="(value)=>{
                    details.email.value = value;
                    details.email.isValid = value === '';
                    inputValueChanged();
                }"
          label="* Email Address"
          placeholder="Enter email address"
          :required="true"/>
      <p v-if="!isValidEmail(details.email.value) && details.email.value !== ''" class="text-red text-xs my-2">
        Invalid email.
      </p>
    </div>
    <Input
        :default-value="details.mobileNumber"
        @value-changed="(value)=> details.mobileNumber = value"
        label="Mobile Number"
        placeholder="Enter mobile number (optional)"
        class="w-[90%]"/>

    <Select
        label="Member Role"
        @value-changed="(value)=> {
                details.role.value = value
                details.role.isValid = value === '';
                inputValueChanged();
            }"
        :is-invalid="details.role.isValid"
        :key="fieldValidationKey"
        :options="[
                {option: 'Member', value: 'User'},
                {option: 'Organisation Admin', value: 'OrganisationAdmin'},
                {option:'Super Admin', value: 'SuperAdmin'},
            ]"
        :selected="formData.role.value"/>

    <div class="w-[90%]">
      <DatePicker
          label="* Date of Birth"
          :disabled="false"
          :error="details.dateOfBirth.isValid ? undefined : 'Please provide a valid date of birth'"
          :max-date="new Date()"
          :date="dateOfBirth"
          @updatedDate="validateDate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ComputedRef, onMounted, ref, watch} from 'vue';
import {DateTime} from 'luxon';
import Input from '../../../AQuarks/Input.vue';
import Select from '@/components/Atomic/AQuarks/Select.vue';
import {NewUser} from '@/areas/users/model/data/NewUser'
import {ISelectOptions} from '@/types/shared';
import {dateFormatter, isValidEmail} from '@/components/utils/utils';
import {EmployeeDto} from '@/areas/entities/model/data/dtos/employeeDto'
import DatePicker from "@/shared/components/DatePicker.vue";

const emits = defineEmits<{
  inputValueChanged: []
}>()

const props = defineProps<{
  formData: NewUser,
  userDetails?: EmployeeDto,
  fieldValidationKey: number
}>()

const details = ref<NewUser>(props.formData);

const update = (userDetails: EmployeeDto) => {
  details.value.firstName.value = userDetails.user?.firstName || '';
  details.value.lastName.value = userDetails.user?.lastName || '';
  details.value.email.value = userDetails.user?.email || '';
  details.value.mobileNumber = userDetails.user?.mobileNumber || '';
  details.value.role.value = userDetails.user?.userRole || '';

  if (userDetails.user?.dateOfBirth) {
    const dobInput = userDetails.user?.dateOfBirth instanceof Date
        ? userDetails.user?.dateOfBirth.toISOString()
        : userDetails.user?.dateOfBirth; // Assuming this is already in ISO string format or a format acceptable by DateTime.fromISO

    details.value.dateOfBirth.value = new Date(dobInput)
  } else {
    details.value.dateOfBirth.value = undefined;
  }

  details.value.firstName.isValid = !!userDetails.user?.firstName;
  details.value.lastName.isValid = !!userDetails.user?.lastName;
  details.value.email.isValid = !!userDetails.user?.email && isValidEmail(userDetails.user?.email);
  details.value.role.isValid = !!userDetails.user?.userRole;
  details.value.dateOfBirth.isValid = !!userDetails.user?.dateOfBirth;
}

const roles = ref<ISelectOptions[]>([
  {option: 'Member', value: 'User'},
  {option: 'Organisation Admin', value: 'OrganisationAdmin'},
  {option: 'Super Admin', value: 'SuperAdmin'},
])

const toDate = (date: Date | string): Date => {
  if (typeof date === 'string') {
    return dateFormatter(date).toJSDate()
  } else {
    return date
  }
}

const validateDate = (date?: Date) => {
  details.value.dateOfBirth.isValid = !!(date)

  if (date) {
    details.value.dateOfBirth.value = date
  }
}

const inputValueChanged = () => {
  emits('inputValueChanged')
}

const dateOfBirth: ComputedRef<Date | undefined> = computed(() => {
  if (details.value.dateOfBirth.isValid) {
    return toDate(details.value.dateOfBirth.value)
  }

  return undefined
});

watch(() => props.userDetails, (newVal) => {
  if (newVal) {
    update(newVal)
  }
}, {
  deep: true,
  immediate: true,
})

</script>