<template>
  <SearchInputCard
      v-if="!userDelegateStore.isLoading"
      class="py-2 w-full"
      @searchUpdate="onSearchChange"
  />

  <div
      v-if="!userDelegateStore.isLoading"
      v-for="assessment in userDelegateStore.assessments"
      :key="assessment.assessment.guid"
      class="flex flex-row space-y-2 w-full">


    <AssessmentCard
        :assessment="assessment"
        @click="onClickRow(assessment)"
    />

  </div>

  <div v-if="userDelegateStore.assessments.length === 0 && !userDelegateStore.isLoading">

    <Card>
      <div class="py-4 font-roboto text-center text-payne-grey">
        There are no assessments set up for you.
      </div>
    </Card>

  </div>

  <LoadingCard :is-loading="userDelegateStore.isLoading"/>

  <PopupErrorMessage
      v-if="userDelegateStore.error"
      :message="userDelegateStore.error"
      @closed="userDelegateStore.clearError()"
  />

</template>

<script setup lang="ts">
import {useUserDelegateStore} from "@/areas/userAssessments/stores/UserDelegateStore";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import Card from "@/shared/components/Card.vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {useRouter} from "vue-router";
import SearchInputCard from "@/shared/components/SearchInputCard.vue";
import {onMounted, ref, Ref, watch} from "vue";
import AssessmentCard from "@/areas/assessments/components/AssessmentCard.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";

const props = defineProps<{
  entityGuid: string
}>()

const router = useRouter()

const userDelegateStore = useUserDelegateStore()

const searchTerm: Ref<string> = ref('')

watch(() => searchTerm, () => onSearchChange(searchTerm.value))

async function onClickRow(assessment: AssessmentDisplayObject) {
  await router.push({
    name: 'UserAssessors',
    params: {
      entityGuid: props.entityGuid,
      assessmentGuid: assessment.assessment.guid,
      delegateGuid: assessment.delegate!.guid!
    }
  })
}

const onSearchChange = (searchValue: string) => {
  userDelegateStore.search(searchValue);
}

onMounted(async () => {
  await userDelegateStore.init(props.entityGuid)
})

</script>