<template>
  <div class="w-full">
    <div @click="toggle" class="px-2 py-4 flex flex-row justify-between cursor-pointer rounded-sm bg-[#EBEBEB] text-olive-green">
      <div>
        <h2 class="font-semibold">{{ title }}</h2>
      </div>

      <div class="flex items-center justify-center">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" :style="isOpen ? 'transform: rotate(180deg); transform-origin: center;' : ''">
          <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z" fill="#53687B"/>
        </svg>
      </div>
    </div>

    <div v-show="isOpen" class="">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';

const props = defineProps<{
  title: string
  isOpen: boolean
}>()

const emits = defineEmits<{
  isOpen: [boolean]
}>()

const degrees = ref(0)

const isOpen = ref(props.isOpen)

const ignoreEmit = ref<boolean>(false)

const toggle = () => {
  degrees.value = 180
  isOpen.value = !isOpen.value
}

watch(() => props.isOpen, (newValue) => {
  ignoreEmit.value = true
  isOpen.value = newValue
})

watch(() => isOpen.value, (newValue) => {
  if (!ignoreEmit.value) {
    emits('isOpen', newValue)
  }

  ignoreEmit.value = false
})

</script>