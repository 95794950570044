<template>
  <div class="flex font-roboto uppercase">

    <div v-for="tab in tabs"
         :key="tab.name"
         :class="[tab.name === currentTab?.name ? 'border-dark-blue text-dark-blue' : 'border-transparent hover:border-gray-300 hover:text-gray-700',
              tab.edited ? 'text-red' : 'text-payne-grey',
              'flex-grow basis-0 border-b-4 py-4 px-1 text-center text-sm font-bold cursor-pointer']"
         @click="onTabClicked(tab)">{{ tab.name }}{{ getStar(tab.edited) }}
    </div>

  </div>

</template>

<script setup lang="ts">

import {computed, ref, watch} from "vue"

export interface TabProperty {
  name: string,
  edited: boolean
}

const props = defineProps<{
  tabs: TabProperty[],
  selectedTab?: TabProperty
}>()

const emit = defineEmits<{
  tabChange: [TabProperty]
}>()

const currentTab = ref(props.selectedTab)

const getStar = (edited: boolean) => {
  if (edited)
    return '*'

  return ''
}

watch(
    () => props.selectedTab,
    (newVal) => {
      currentTab.value = newVal;
    },
    {immediate: true}
)

function onTabClicked(tabProperty: TabProperty): void {
  if (tabProperty.name === currentTab.value?.name) {
    return;
  }

  emit('tabChange', tabProperty)
}

</script>