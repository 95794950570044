import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      modelValue: _ctx.menu,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menu) = $event)),
      "close-on-content-click": false,
      location: "start"
    }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
          _renderSlot(_ctx.$slots, "popover_activator")
        ], 16)
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_v_card, {
          class: "min-w-[200px]",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeOnClick ? _ctx.closePopover : () => {}))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            })
          ]),
          _: 3
        })), [
          [_directive_click_outside, _ctx.clickedOutside]
        ])
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}