<template>
  <div class="fixed z-50 inset-0 overflow-y-none" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- Modal content -->
      <div class="inline-block align-bottom bg-white rounded-sm pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div>
          <h3 class="text-lg leading-6 px-4 pb-2 border-b font-medium text-gray-900" id="modal-title">
            Permanently delete {{ deleteItemName }}
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500 px-4">
              You are about to permanently delete {{ deleteItemName }} and all its contents.<br> This action <b>cannot be undone.</b>
            </p>
            <div class="flex flex-row border-b pb-4 justify-start space-x-2 px-4 items-center mt-4 text-payne-grey">
            <p>Type "delete" to confirm</p>
            <input v-model="confirmationText" type="text" class="w-1/3 p-2 border rounded">
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-end px-4 space-x-2 mt-5 sm:mt-6">
          <button @click="cancelDelete" class="w-1/4 inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-payne-grey hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm disabled:opacity-50">
            Cancel
          </button>
          <button :disabled="confirmationText.toLowerCase() !== 'delete'" @click="confirmDelete" class="w-1/4 inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm disabled:opacity-50">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useEntityStore} from '@/areas/entities/store/entityStore'
import {useAssessmentTypeStore} from '@/areas/assessmentTypes/store/assessmentTypeStore'
import {useRoute, useRouter} from 'vue-router';
import Toast from '@/Toast';
import bus from "@/bus"
import {useUserStore} from "@/areas/users/store/userStore";

export default defineComponent({
  name: 'ConfirmationModal',
  props: {
    itemName: {
        type: String,
        required: false
    },
    assessmentTypeGuid: {
        type: String,
        required: false
    },
    organisationGuid: {
        type: String,
        required: false
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const userStore = useUserStore();

    const assessmentTypeStore = useAssessmentTypeStore();
    const orgGuid = ref<string>("");
    const organisationName = ref<string>("");
    const existingOrganisation = ref(false);
    let deleteItemName = ref<string>("");

    const isDashboardOrganisationRoute = computed(() => {
      return route.path.startsWith("/dashboard/organisation");
    });

    const fetchOrganisationDetails = async () => {
      if(isDashboardOrganisationRoute.value){
        const organisationGuid = route.params.organisationGuid as string;

        if (organisationGuid) {
            existingOrganisation.value = !!existingOrganisation;
            orgGuid.value = organisationGuid;
            try {
              const details = await entityStore.fetchEntity(orgGuid.value);
              if (details) {  // Check if details is not null or undefined
                  if (details.content?.displayName) {
                    deleteItemName.value = details.content?.displayName;  // Update organisationName only if name exists
                  }
              }
            } catch (error) {
              console.error(error)
              Toast.error("Existing organisation could not be found")
            }
        }
      } else {
        deleteItemName.value = props.itemName!;
      }
    };

    const confirmDelete = async () => {
      if(isDashboardOrganisationRoute.value){
        let orgDeleteResult = await entityStore.deleteEntity(orgGuid.value)

        if(!orgDeleteResult) {
          Toast.error('Something went wrong while deleting the organisation')
        } else {
          Toast.success("Organisation deleted")

          await userStore.init(true)

          await router.push('/')
        }
      }
    }

    watch(() => route.params.organisationGuid, (newGuid, oldGuid) => {
      if (newGuid !== oldGuid) {
          fetchOrganisationDetails();
      }
    }, { immediate: true });

    onMounted(fetchOrganisationDetails);

    const confirmationText = ref('');

    return {
      confirmationText,
      organisationName,
      orgGuid,
      entityStore,
      confirmDelete,
      deleteItemName
    };
  },
  methods: {
    cancelDelete() {
      bus.emit('cancel-delete');
    }
  },
});
</script>