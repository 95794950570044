<template>
  <div class="p-4 space-y-4">
    <DashboardHeader :tab="'ASSESSMENTS'"/>

    <div class="grid grid-cols-8 justify-between w-full">
      <div class="col-span-4">
        <h1 class="uppercase text-[18px] text-payne-grey font-bold">Organisation Assessments</h1>
      </div>

      <div class="col-span-2"></div>

      <div class="col-span-2">
        <GenericButton @onSubmit="emit('addAssessment')" class="w-1/4">
          Add Assessment
        </GenericButton>
      </div>
    </div>

    <div
        v-if="!isLoading && hasAssessments"
        class="flex flex-col items-center justify-center w-full border-b rounded-md drop-shadow-sm bg-white border-gray-200 px-10 pt-8">

      <AssessmentsTable
          v-if="hasAssessments"
          :assessments="assessments"
          @edit="value => emit('edit', value)"
          @setStatus="setStatus"
          @reports="value => emit('reports', value)"
          @manageDelegates="value => emit('manageDelegates', value)"
      />

    </div>

    <EmptyAddCard
        v-if="!hasAssessments && !props.isLoading">
      The selected Organisation does not have any Assessments set up as yet. Please add an Assessment to get started
    </EmptyAddCard>

    <LoadingCard :is-loading="isLoading"/>
  </div>

  <PopupConfirmation
      v-if="changingStatus"
      :title="`${toStateName(changingStatus[1])} Assessment`"
      @cancel="changingStatus = undefined"
      @confirm="confirmStatusChange"
  >

    You are about to {{ toStateName(changingStatus[1]) }} assessment {{ changingStatus[0].detail.title }}. Are you sure
    you want to proceed?
  </PopupConfirmation>

</template>

<script setup lang="ts">

import AssessmentsTable from "@/areas/assessments/components/AssessmentsTable.vue";
import DashboardHeader from "@/components/Atomic/BAtoms/DashboardHeader.vue";
import {computed, ref} from "vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";
import GenericButton from "@/shared/components/GenericButton.vue";
import EmptyAddCard from "@/shared/components/EmptyAddCard.vue";

const props = defineProps<{
  assessments: AssessmentDisplayObject[]
  isLoading: boolean
}>()

const changingStatus = ref<[AssessmentDisplayObject, AssessmentState] | undefined>(undefined)

const emit = defineEmits<{
  addAssessment: []
  edit: [AssessmentDisplayObject]
  reports: [AssessmentDisplayObject]
  manageDelegates: [AssessmentDisplayObject]
  setStatus: [AssessmentDisplayObject, AssessmentState]
}>()

const hasAssessments = computed(() => props.assessments.length !== 0)

const setStatus = (assessment: AssessmentDisplayObject, assessmentState: AssessmentState) => {
  changingStatus.value = [assessment, assessmentState]
}

const confirmStatusChange = () => {
  if (changingStatus.value) {
    emit('setStatus', changingStatus.value[0], changingStatus.value[1])
    changingStatus.value = undefined
  }
}

const toStateName = (state: AssessmentState): string => {
  switch (state) {
    case AssessmentState.Cancelled:
      return "Cancel"

    case AssessmentState.Published:
      return "Publish"

    case AssessmentState.Active:
      return "Activate"

    case AssessmentState.Completed:
      return "Complete"

    default:
      return ""
  }
}

</script>