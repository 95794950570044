export function parseStringToEnum<T>(enumObj: T, value: string): T[keyof T] | undefined {
    // Get all the values of the enum
    let enumValues = Object.values(enumObj) as string[];

    // Find the enum value that matches the input string
    let match = enumValues.find(enumValue => enumValue === value);

    if (match) {
        // If a match is found, return it as the specific enum type
        return enumObj[match as keyof T];
    }

    // Return undefined if no match is found
    return undefined;
}
