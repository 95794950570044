<template>
  <div class="flex flex-row">
    <v-checkbox-btn
        v-if="selectable"
        color="olive-green"
        v-model="selected"
    />

    <input type="text" name="assessment-search"
           v-model="inputValue"
           class="block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-[#AAAAAA] focus:ring-2
                                focus:ring-inset  sm:text-sm sm:leading-6"
           :placeholder="placeholder ? placeholder : 'Search'"/>

    <div class="flex items-center p-2">
      <img src="/img/search.svg" class="w-6 h-6" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";

const props = defineProps<{
  //Placeholder text to be displayed in the search bar
  placeholder?: string

  //Adds a tick box next to the card
  selectable?: boolean

  //Is all selected or not
  allSelected?: boolean
}>()

const emit = defineEmits<{
  searchUpdate: [searchString: string]
  selectAll: [boolean]
}>()

const inputValue = ref<string>("")

const selected = ref<boolean>(false)
const ignoreSelect = ref<boolean>(false)

watch(() => inputValue.value, (newValue, _) => {
  emit("searchUpdate", newValue)
})

watch(() => selected.value, (newValue) => {
  if (!ignoreSelect.value) {
    emit('selectAll', newValue)
  }

  ignoreSelect.value = false
})

watch(() => props.allSelected, (newValue) => {
  if (selected.value != newValue) {
    ignoreSelect.value = true
    selected.value = newValue
  }
})
</script>