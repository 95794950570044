import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4" }
const _hoisted_2 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_3 = { class: "px-4" }
const _hoisted_4 = { class: "list-disc font-sm font-roboto text-payne-grey pl-10" }
const _hoisted_5 = {
  key: 1,
  class: "text-center text-payne-grey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DemographicsAccordion = _resolveComponent("DemographicsAccordion")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      default: _withCtx(() => [
        (_ctx.demographics && _ctx.demographics.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consolidatedDemographics(_ctx.demographics), (demographic, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: demographic.guid
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_DemographicsAccordion, {
                      demographic: demographic,
                      index: index,
                      "is-active": _ctx.activeAccordionIndex === index
                    }, null, 8, ["demographic", "index", "is-active"]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createElementVNode("ul", _hoisted_4, [
                        (demographic.demographicType === 'NumberedRange')
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(demographic.values, (value) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: value.title
                              }, _toDisplayString(value.title) + ": " + _toDisplayString(value.value.join(' - ')), 1))
                            }), 128))
                          : _createCommentVNode("", true),
                        (demographic.demographicType === 'StaticCategory')
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(demographic.values, (value) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: value.guid
                              }, _toDisplayString(value.title), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ], 512), [
                      [_vShow, _ctx.activeAccordionIndex === index]
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.demographics || _ctx.demographics.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, " There are currently no demographics in this list, please add demographics using the Add Demographics button "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}