import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DemographicForm = _resolveComponent("DemographicForm")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "modal-description": _ctx.modalDescription,
    justify: "start",
    "modal-title": _ctx.modalTitle
  }, {
    modal_activator: _withCtx(() => [
      _createElementVNode("div", null, "Edit " + _toDisplayString(_ctx.demographicTitle), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_DemographicForm, {
            selectedDemographic: _ctx.selectedDemographic,
            "existing-categories": _ctx.isEditMode ? _ctx.values : [],
            "existing-ranges": _ctx.isEditMode ? _ctx.values : [],
            index: _ctx.index
          }, null, 8, ["selectedDemographic", "existing-categories", "existing-ranges", "index"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-description", "modal-title"]))
}