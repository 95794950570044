<template>
  <div class="grid grid-cols-8 items-center w-full">

    <div class="col-span-5 md:col-span-3">
      <div class="col-span-4">
        <h1 class="uppercase text-[18px] text-payne-grey font-bold">Organisation Assessment Types</h1>
      </div>
    </div>

    <div class="hidden md:block md:col-span-3"></div>

    <div class="col-span-3 md:col-span-2">
      <GenericButton @onSubmit="emits('add')">Add Assessment Type</GenericButton>
    </div>
  </div>
</template>

<script setup lang="ts">

const emits = defineEmits<{
  add: []
}>()

import GenericButton from "@/shared/components/GenericButton.vue";
</script>