import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, ({ label, key, isValid, options, versionGuid, selected, prepop }) => {
      return (_openBlock(), _createBlock(_component_Select, {
        onValueChanged: (value) => {
          _ctx.setSelected({ demographicName: label, demographicGuid: key, valueGuid: value, versionGuid: versionGuid, selected })
          _ctx.validateDemographics()
      },
        key: key + _ctx.fieldValidationKey,
        label: label,
        disabled: label.toLowerCase() === 'age',
        selected: prepop ? selected : label.toLowerCase() === 'age' ? _ctx.selectedAgeRangeValue : _ctx.getSelected(key),
        options: options,
        "is-invalid": isValid,
        required: true
      }, null, 8, ["onValueChanged", "label", "disabled", "selected", "options", "is-invalid"]))
    }), 128))
  ]))
}