<template>
  <div class="flex flex-col space-y-4">
    <Card>
      <div v-if="demographics && demographics.length > 0" class="space-y-4">
        <div v-for="(demographic, index) in consolidatedDemographics(demographics)" :key="demographic.guid">
          <div class="px-4">
            <!-- Expandable accordion -->
            <DemographicsAccordion
                :demographic="demographic"
                :index="index"
                :is-active="activeAccordionIndex === index">
            </DemographicsAccordion>
            <!-- Content for expanded accordion -->

            <div v-show="activeAccordionIndex === index">
              <ul class="list-disc font-sm font-roboto text-payne-grey pl-10">
                <li v-for="value in demographic.values" :key="value.title"
                    v-if="demographic.demographicType === 'NumberedRange'">
                  {{ value.title }}: {{ value.value.join(' - ') }}
                </li>
                <li v-for="value in demographic.values" :key="value.guid"
                    v-if="demographic.demographicType === 'StaticCategory'">
                  {{ value.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!demographics || demographics.length === 0" class="text-center text-payne-grey">
        There are currently no demographics in this list, please add demographics using the Add Demographics button
      </div>
    </Card>
  </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, ref} from 'vue';
import DemographicsAccordion from '../BAtoms/Demographics/DemographicsAccordion.vue';
import {useDemographicsStore} from "@/areas/demographics/store/demographicStore";
import {useUserStore} from "@/areas/users/store/userStore";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";
import {useRouter} from 'vue-router';
import Toast from '@/Toast';
import {convertDemographicToDefaultDemoGraphicsTable,} from '../../utils/utils'
import bus from '@/bus'
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {Entity} from '@/areas/entities/model/data/dtos/entity';
import Card from "@/shared/components/Card.vue";

export interface AccordionIndexState {
  state: boolean
  index: number
}

export default defineComponent({
  name: 'DemographicsTable',
  components: {
    Card,
    DemographicsAccordion,
    Toast
  },
  setup() {
    const router = useRouter();
    const demographicsStore = useDemographicsStore()
    const userStore = useUserStore()
    const demographics = ref<Demographic[]>([]);
    const expandedAccordions = ref<number[]>([])
    const activeAccordionIndex = ref<number | null>(null);
    const isActive = ref<boolean>(true);
    const accordionStates = ref<boolean[]>([]);
    const activeEntity = ref<Entity | undefined>(undefined);
    const adminPriv = ref<boolean>(false);

    const checkRoles = async () => {
      const state = await userStore.getState();

      activeEntity.value = state.activeEntity

      switch (state.role) {
        case UserRole.SuperAdmin:
          adminPriv.value = true
          break

        case UserRole.OrganisationAdmin:
          adminPriv.value = false
          break

        case UserRole.User:
          await router.push(`/dashboard/assessments/${state.activeEntity.guid}`)
      }
    }

    const fetchDemographics = async () => {
      const fetchedDemographics = await demographicsStore.list(activeEntity!.value!.guid as string);
      const sortedDemographics = fetchedDemographics.content?.sort((a, b) => {
        return (b.isEnabled === a.isEnabled) ? 0 : a.isEnabled ? -1 : 1;
      });
      demographics.value = sortedDemographics as Demographic[];
      accordionStates.value = demographics.value.map(() => false);
    }

    const handleAccordionToggled = (accordionIndexState: AccordionIndexState) => {
      if (activeAccordionIndex.value === accordionIndexState.index) {
        activeAccordionIndex.value = null;
      } else {
        activeAccordionIndex.value = accordionIndexState.index;
      }
    }

    onMounted(async () => {
      await checkRoles()

      await fetchDemographics()

      bus.on('default-accordion-toggled', (data) => {
        handleAccordionToggled(data as AccordionIndexState);
      });
      bus.on('close-modal', fetchDemographics);
    })

    onBeforeUnmount(() => {
      bus.off('default-accordion-toggled', handleAccordionToggled)
      bus.off('close-modal', fetchDemographics);
    })

    return {
      demographics,
      expandedAccordions,
      activeAccordionIndex,
      accordionStates,
      handleAccordionToggled,
      isActive,
      fetchDemographics,
      activeEntity,
      adminPriv
    }
  },
  methods: {
    consolidatedDemographics(demographics: Demographic[]) {
      return demographics.map(demographic => {
        return convertDemographicToDefaultDemoGraphicsTable(demographic);
      });
    }
  }
})

</script>