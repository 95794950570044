import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "rounded-sm font-roboto px-3.5 py-2.5 text-sm font-semibold"
}
const _hoisted_2 = { class: "flex flex-row items-center justify-around" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.imageSrc)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.imageSrc,
            alt: "button icon",
            class: _normalizeClass(`inline-block mr-2 w-3 ${$props.customStyle ? $props.customStyle : ''}`)
          }, null, 10, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString($props.buttonText), 1)
    ])
  ]))
}