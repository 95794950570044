<template>
  <Card class="space-y-4 w-full">
    <div class="flex flex-col items-center">
      <div class="text-xl font-semibold text-payne-grey font-roboto">
        {{ userAssessmentDto.delegate.displayName }}
      </div>

      <div class="text-sm font-roboto text-olive-green font-semibold">
        {{ userAssessmentDto.assessmentDetail.title }}
      </div>

    </div>

    <div class="grid grid-cols-9">
      <div class="col-span-3">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            Type
          </div>

          <div class="text-base">
            {{ userAssessmentDto.delegate.delegateType }}
          </div>
        </div>
      </div>

      <div class="col-span-3"></div>


      <div class="col-span-3">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            State
          </div>

          <div class="text-base">
            <Pill :color="stateColour">{{ stateName }}</Pill>
          </div>
        </div>
      </div>
    </div>

    <Divider />

    <div class="flex flex-row w-full justify-center items-center space-x-8 py-2">

      <div
          v-if="userAssessmentDto.assessor.entityState === AssessorState.Invited"
          class="flex flex-row w-full justify-around items-center space-x-2 sm:w-4/5">

        <GenericButton class="bg-olive-green" @onSubmit="emit('accept')">
          Accept
        </GenericButton>

        <GenericButton class="bg-payne-grey" @onSubmit="emit('decline')">
          Decline
        </GenericButton>
      </div>


      <div v-if="[AssessorState.Accepted, AssessorState.InProgress].includes(userAssessmentDto.assessor.entityState!)" class="w-full sm:w-4/5">
        <GenericButton @onSubmit="emit('complete')" class="bg-olive-green text-white">Complete</GenericButton>
      </div>
    </div>
  </Card>

</template>

<script setup lang="ts">
import Card from "@/shared/components/Card.vue";
import Pill from "@/shared/components/Pill.vue";
import Divider from "@/shared/components/Divider.vue";
import {AssessorExtensions} from "@/areas/assessors/extensions/AssessorExtensions";
import GenericButton from "@/shared/components/GenericButton.vue";
import {UserAssessmentDto} from "@/areas/assessments/model/dtos/UserAssessmentDto";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";

const props = defineProps<{
  userAssessmentDto: UserAssessmentDto
}>()

const [stateColour, stateName] = AssessorExtensions.toOptions(props.userAssessmentDto.assessor.entityState!)

const emit = defineEmits<{
  accept: []
  decline: []
  complete: []
}>()


</script>