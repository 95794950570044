<template>
  <div class="flex flex-col w-full h-full space-y-2 pb-4">

    <div v-if="heading" class="text-2xl font-semibold flex justify-center md:justify-start text-payne-grey font-roboto">
      <h1> {{ heading }}</h1>
    </div>

    <SearchInputCard
        placeholder="Search colleagues"
        :selectable="selectable"
        :all-selected="allSelected"
        @searchUpdate="updateSearch"
        @selectAll="selectAll" class="w-full"/>

    <div v-for="employee in employeeSearchStore.results" :key="employee.user.guid"
         class="space-y-4"
         @click="onEmployeeSelect(employee)">

      <div v-if="!employeeSearchStore.isLoggedInUser(employee)" class="py-2 w-full">
        <EmployeeCard
            class="flex-grow-1"
            :employee="employee"
            :selectable="selectable"
            :is-selected="employee.selected ?? false"
            :disabled="false"
            @selected="selected(employee)"
            @deselected="deselected(employee)"
        />
      </div>
    </div>

    <div v-if="employeeSearchStore.isLoading">
      I am loading now...
    </div>

    <LoadingCard :isLoading="employeeSearchStore.isLoading"></LoadingCard>

  </div>
</template>

<script setup lang="ts">

import SearchInputCard from "@/shared/components/SearchInputCard.vue";
import {computed, onMounted, onUnmounted, ref, Ref} from "vue";
import EmployeeCard from "@/areas/employees/components/EmployeeCard.vue";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import {useEmployeeSearchStore} from "@/areas/employees/stores/EmployeeSearchStore";
import {User} from "@/areas/users/model/data/User";

const props = defineProps<{
  entityGuid: string
  selectedUsers: User[]
  heading?: string
  selectable: boolean
  canDeselect: boolean
}>()

const emit = defineEmits<{
  selected: [EmployeeDto]
  deselected: [EmployeeDto]
}>()

const employeeSearchStore = useEmployeeSearchStore()

const lastScrollPoint = ref<number>(0)

const currentSearchValue: Ref<string> = ref("")

const allSelected = computed(() => employeeSearchStore.allSelected())

const SCROLLING_THRESHOLD = 300

const onScroll = async () => {
  const {scrollTop, offsetHeight} = document.documentElement;
  const windowHeight = window.innerHeight;

  //Where the window is currently. take top and add port size
  const currentPosition = windowHeight + scrollTop

  //When to start scrolling.
  const threshold = offsetHeight - SCROLLING_THRESHOLD

  //Calculate padding from bottom to prevent infinite loop calling
  const padding = SCROLLING_THRESHOLD * 0.2

  //Padding to prevent reloads right at the bottom of the screen
  const paddedThreshold = offsetHeight - padding

  if (currentPosition >= threshold &&
      currentPosition <= paddedThreshold &&
      !employeeSearchStore.isLoading &&
      currentPosition > lastScrollPoint.value) {

    lastScrollPoint.value = currentPosition

    await updateSearch(currentSearchValue.value)
  }
}

const onEmployeeSelect = (employee: EmployeeDto) => {
  emit('selected', employee)
}

const selected = (employee: EmployeeDto) => {
  employeeSearchStore.selectEmployee(employee)

  emit('selected', employee)
}

const deselected = (employee: EmployeeDto) => {
  employeeSearchStore.deselectEmployee(employee)

  emit('deselected', employee)
}

const selectAll = (isSelected: boolean) => {
  if (isSelected) {
    employeeSearchStore.results.forEach(emp => {
      selected(emp)
    })
  } else {
    employeeSearchStore.results.forEach(emp => {
      deselected(emp)
    })
  }
}

const updateSearch = async (searchString: string) => {
  currentSearchValue.value = searchString

  await employeeSearchStore.searchEmployees(props.entityGuid, searchString)
}

onMounted(async () => {
  employeeSearchStore.clearEmployeeSearch()

  employeeSearchStore.setSelectedEmployees(props.selectedUsers)
  window.addEventListener('scroll', onScroll)

  await updateSearch(currentSearchValue.value)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})

</script>