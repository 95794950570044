<template>
    <FormTabs />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import EmployeeFormTabs from '../BAtoms/Employees/EmployeeFormTabs.vue';

export default defineComponent({
    components:{
        FormTabs : EmployeeFormTabs
    }
})
</script>