import {Dictionary, reduce} from "lodash";
import {colors, default_chart_colors, section_description, sectionIndex,} from "./constants";
import {ReportQuestion} from "@/areas/reports/models/data/ReportQuestion";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {StatementPerspectiveType} from "@/areas/assessmentTypes/model/data/enums/StatementPerspectiveType";
import {UserAssessmentExtensions} from "@/areas/userAssessments/extensions/UserAssessmentExtensions";
import {Entity} from "@/areas/entities/model/data/dtos/entity";

const {primary} = colors;

export const gridLines = {
    gridLines: {
        color: "rgba(0, 0, 0, 0.5)", // Gridline color for the X-axis
        lineWidth: 1, // Gridline width for the X-axis
    },
};

const sectionAvgScoresReducer = (
    grouped_scores: Dictionary<any[]>,
    qidx: number
) => {
    const section_data = grouped_scores[sectionIndex[qidx]];

    let section_avg_scores_others =
        reduce(section_data, (result, obj) => result + obj.scoreAvgOthers, 0) /
        section_data.length || 1;

    let section_avg_scores_self =
        reduce(section_data, (result, obj) => result + obj.scoreSelf, 0) /
        section_data.length || 1;

    return {
        domainOtherAverageScore: section_avg_scores_others,
        domainMineAverageScore: section_avg_scores_self,
        section_data
    };
};

const lowestScores = (sortedScores: ReportQuestion[], delegate: DelegateDto, entity: Entity) => {
    const lowestRatedBehaviours = Array();

    for (let idx = 0; idx < 5; idx++) {

        const thirdStatement = sortedScores[idx]
            .question
            .statements
            .find(st =>
                st.statementPerspectiveType == StatementPerspectiveType.Third)!

        const head = UserAssessmentExtensions.parseText(thirdStatement.head!, delegate, entity, delegate.individual)
        const heart = UserAssessmentExtensions.parseText(thirdStatement.heart!, delegate, entity, delegate.individual)

        lowestRatedBehaviours.push({
            behaviour: {
                key: sortedScores[idx].question.key,
                title: head,
                description: heart,
                rating: Math.round(sortedScores[idx].resultsOtherAverage * 10) / 10
            },
        });
    }

    return lowestRatedBehaviours;
};

const highestScores = (sortedScores: ReportQuestion[], delegate: DelegateDto, entity: Entity) => {
    let idx = sortedScores.length - 1;
    const highestRatedBehaviours = Array();

    do {
        const thirdStatement = sortedScores[idx]
            .question
            .statements
            .find(st =>
                st.statementPerspectiveType == StatementPerspectiveType.Third)!

        const head = UserAssessmentExtensions.parseText(thirdStatement.head!, delegate, entity, delegate.individual)
        const heart = UserAssessmentExtensions.parseText(thirdStatement.heart!, delegate, entity, delegate.individual)

        highestRatedBehaviours.push({
            behaviour: {
                key: sortedScores[idx].question.key,
                title: head,
                description: heart,
                rating: Math.round(sortedScores[idx].resultsOtherAverage * 10) / 10
            },
        });

        idx -= 1;
    } while (idx >= sortedScores.length - 5);

    return highestRatedBehaviours;
};

const feelingRatingDataFormatter = async (
    surveyId: any,
    assessments: any,
    delegate_id: any,
    surveyType: string
) => {

    interface Comment {
        comment: string;
        isSelf: boolean;
    }

    const slug = `mores-survey-${surveyId}-assessment_`;
    const slugs = assessments.map(
        (assessment: { _id: any }) => `${slug}${assessment._id}`
    );

    const regexSlugs = slugs.map((slug: string) => new RegExp(slug));

    const result: any = []; //await Content.find({ slug: { $in: regexSlugs } });

    const feeling_comment: Comment[] = [];
    const feeling_rating: number[] = [];
    const assessment_comments_away: Comment[] = [];
    const assessment_comments_toward: Comment[] = [];

    let self_feeling_rating = 0;

    result.forEach((ct: any) => {
        const {content, slug: _slug} = ct;
        const isSelf: boolean =
            _slug.includes(delegate_id) && surveyType.includes("360");

        if (_slug.includes("rating-comment")) {
            feeling_comment.push({comment: content as string, isSelf});
        } else if (_slug.includes("rating-rating")) {
            if (!surveyType.includes("360") || !isSelf) {
                //For team feeling rating and others
                feeling_rating.push(+content);
            } else {
                self_feeling_rating = +content;
            }
        } else if (_slug.includes("CustomComment-1")) {
            assessment_comments_away.push({comment: content as string, isSelf});
        } else if (_slug.includes("CustomComment-0")) {
            assessment_comments_toward.push({comment: content as string, isSelf});
        }
    });

    return {
        feeling_comment,
        feeling_rating,
        assessment_comments_away,
        assessment_comments_toward,
        self_feeling_rating,
    };
};

const getChartColorAndTitle = (quality: any, qualityIndex: number) => {
    const {chart_color: color, chart_title: title} = quality;
    const chart_color =
        color && !color.includes("undefined")
            ? color
            : default_chart_colors[qualityIndex];
    const chart_title = title ? title : section_description[qualityIndex];
    return {chartColor: chart_color, chart_title};
};

const contrastingColor = (hexColor: string) => {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose black or white based on luminance
    return luminance > 0.6 ? '#000000' : '#ffffff';
}

export {
    lowestScores,
    highestScores,
    contrastingColor,
    getChartColorAndTitle,
    sectionAvgScoresReducer,
    feelingRatingDataFormatter,
};
