import {colors} from "@/colors";

export const styles = {
    normal: {
        font: 'Roboto',
    },
    default: {
        fontSize: 10,
        font: 'Roboto',
        alignment: 'justify',
        margin: [0, 0, 10, 0],
        lineHeight: 1.5,
        bold: false,
        italics: false,
    },
    sublist: {
        fontSize: 8,
    },
    title: {
        fontSize: 16,
        bold: true,
        font: 'Roboto',
    },
    primary: {
        color: colors["payne-grey"],
    },
    secondary: {
        color: colors["olive-green"],
    },
    header: {
        fontSize: 12,
        bold: true,
        font: 'Roboto',
        margin: [0, 15, 0, 15],
    },
    subheader: {
        fontSize: 11,
        bold: true,
        font: 'Roboto',
        margin: [0, 15],
    },
    subheader2: {
        fontSize: 10,
        bold: true,
        font: 'Roboto',
        margin: [0, 15],
    },
    superscript: {
        fontSize: 8,
        margin: [0, 0, 0, 0],
        fontFeatures: ['sups']
    },
    quote: {
        fontSize: 11,
        font: 'Roboto',
        italics: true,
        color: colors["payne-grey"],
    },
    centerAligned: {
        alignment: 'center',
    },
    justified: {
        alignment: 'justify',
    },
    table_of_contents: {
        fontSize: 10,
        fontFamily: 'Roboto',
        fontWeight: 'light',
        color: colors["payne-grey"]
    },
    section_title: {
        fontFamily: 'Roboto',
        fontWeight: 'light',
        fontSize: 16,
        color: colors['olive-green'],
        margin: [0, 0, 0, 15]
    },
    section_subtitle: {
        font: 'Roboto',
        fontSize: 12,
    },
    section_description: {
        font: 'Roboto',
        fontSize: 10,
    },
    text_small: {
        font: 'Roboto',
        fontSize: 8,
    },
    comments: {
        font: 'Roboto',
        fontSize: 9,
    },
    roboto_light_italic: {
        fontFamily: 'Roboto',
        fontWeight: 'light',
        fontStyle: 'italic',
    },
    tocTitle: {
        fontSize: 16,
        bold: true,
        color: colors["olive-green"] // Make sure 'olive-green' is defined in your colors object
    }
};