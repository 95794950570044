<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" class="block w-full rounded-md text-payne-grey font-roboto border-gray-300 focus:border-dark-blue focus:ring-dark-blue">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b rounded-md drop-shadow-sm bg-white border-gray-200">
        <nav class="-mb-px flex font-roboto uppercase" aria-label="Tabs">
          <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-dark-blue text-dark-blue' : 'border-transparent text-payne-grey hover:border-gray-300 hover:text-gray-700', 'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-bold']"
            :aria-current="tab.current ? 'page' : undefined"
            @click="selectTab(tab, $event)">
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, defineProps, computed } from 'vue';
import { useRoute } from 'vue-router';
import {IOrganisationTabs, ITabProps} from '@/types/shared'
import Toast from '@/Toast';

const route  = useRoute();
const isOrganisationCreated = computed(() => {
    return route.params.organisationGuid != '';
});
const props = defineProps({
  tabs: {
    type:  Array as ()=> IOrganisationTabs[],
    required: true,
  }
})
const tabs = ref<IOrganisationTabs[]>(props.tabs)
// Initialize the current tab
const currentTab = ref(props.tabs.find(tab => route.path.startsWith(tab.href)));

const selectTab = (selectedTab: IOrganisationTabs, event: MouseEvent) => {
  if (!isOrganisationCreated.value && selectedTab.name !== 'Organisation') {
    event.preventDefault(); // Prevent navigation if the organization is not created
    Toast.error("Please create an organisation before proceeding")
  }
};
// Watch for route changes and update the current tab
watch(route, (newRoute) => {
  tabs.value.forEach(tab => {
    switch (tab.name) {
      case 'Assessments':
        tab.current = newRoute.path.endsWith('assessments');
        break;
      case 'Organisation':
        tab.current = newRoute.path.startsWith('/dashboard/organisation');
        break;
      case 'Employees':
        tab.current = newRoute.path.startsWith('/dashboard/employees');
        break;
      case 'Assessment Types':
        tab.current = newRoute.path.endsWith('assessments/types');
        break;
      default:
        tab.current = newRoute.path === tab.href;
    }
  });
}, { immediate: true });
</script>