import axiosInstance from "@/axiosConfig";
import {User} from '@/areas/users/model/data/User'
import {Operations} from "@/areas/helpers/responses/operations";
import {ProblemDetails} from "@/areas/helpers/responses/models/problemDetails";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {Entity} from "@/areas/entities/model/data/dtos/entity";

export class UserProxy {
    public async getUser(): Promise<OperationResult<User>> {
        const uri = `/users`;

        try {
            const response = await axiosInstance.get<User>(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async getUserEntities(): Promise<OperationResult<Entity[]>> {
        const uri = `/users/entities`;

        try {
            const response = await axiosInstance.get<Entity[]>(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }
}