<template>
  <div :justify="justify" style="" class="modal-container">
    <v-dialog v-model="dialog" persistent :class="`w-${modalWidth}`">
      <template v-slot:activator="{ props }">
                <span v-bind="props">
                    <slot name="modal_activator"></slot>
                </span>
      </template>
      <v-card :class="`${classes} ml-0 pt-4 pb-10`">
        <div :class="`flex px-6 justify-between text-payne-grey ${showXIcon ? '' : ''}`">
          <span class="text-base font-roboto mb-2 text-xl">{{ modalTitle }}</span>
          <svg v-show="showXIcon" xmlns="http://www.w3.org/2000/svg" name="close-icon" fill="none" viewBox="0 0 24 24"
               stroke-width="1.5" @click="closeModal" stroke="currentColor"
               class="cursor-pointer w-6 h-6 stroke-1 hover:stroke-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </div>
        <p v-if="modalDescription" class="text-sm pl-6 pb-4 text-payne-grey">
          {{ modalDescription }}
        </p>
        <v-divider :thickness="1" class="border-opacity-100"></v-divider>
        <div class="w-full items-center px-6">
          <slot></slot>
        </div>
      </v-card>

    </v-dialog>
  </div>
</template>
<script lang="ts">
import {defineComponent, ref, onMounted, onBeforeUnmount} from 'vue'
import bus from "@/bus"

export default defineComponent({
  name: 'Modal',
  props: {
    modalTitle: {
      type: String,
      default: 'Set Demographic'
    },
    modalDescription: String,
    justify: {
      type: String as () => any,
      default: 'center'
    },
    modalWidth: {
      type: Number,
      default: 50
    },
    showXIcon: {
      type: Boolean,
      default: true
    },
    classes: {
      type: String,
      default: ''
    },
  },
  setup() {

    const dialog = ref<boolean>(false)

    const closeModal = () => {
      dialog.value = false
      bus.emit('close-popover-menu')
    }

    onMounted(() => bus.on('close-modal', closeModal))
    onBeforeUnmount(() => bus.off('close-modal', closeModal))

    return {
      dialog,
      closeModal
    }
  }
})
</script>