<template>
    <p v-show="ranges.length > 0" class="mt-5 font-roboto text-sm font-normal leading-5 text-payne-grey">Specify
        the ranges</p>
    <div class="mt-4">
        <div class="grid grid-cols-2 gap-4">
            <div v-for="(range, index) in rangesRef" :key="range.guid" class="mb-8">
                <v-range-slider :color="range.isOverLapping ? 'red' : 'light-grey'"
                    :track-fill-color="determineColor(range.isOverLapping)"
                    :thumb-label="true"
                    :thumb-color="determineColor(range.isOverLapping)" min="18" max="100" step="1" v-model="range.value"
                    strict @update:modelValue="onSliderValueChange(range, index)"></v-range-slider>
                <div :class="`text-${determineColor(range.isOverLapping, true)} text-sm font-roboto ml-3`">
                    <div class="flex justify-between">
                        <div class="relative mb-3">
                            <input type="text"
                                class="text-sm peer block w-full border-0 pl-0 font-roboto text-payne-grey focus:ring-0"
                                v-model="range.title"
                                @blur="updateRangeTitle(range.title, index)"
                                placeholder="Enter range title" />
                            <div class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
                                aria-hidden="true" />
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            @click="removeRange(index, range.guid)" fill="currentColor"
                            class="w-5 h-5 stroke-1 hover:stroke-2 cursor-pointer">
                            <path
                                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                    </div>
                    <p>{{ range.value.join(' - ') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { hasOverlap } from '../../../utils/utils'
import {IDeleteRangeOrCategory, IRange} from "@/areas/demographics/model/data/demographics";

export default defineComponent({
    name: 'RangeSlider',
    
    props: {
        ranges: {
            type: Array as () => IRange[],
            default: () => []
        }
    },

    setup(props, context) {
        const rangesRef = ref(props.ranges)

        const rangesOverlapping = computed(() => hasOverlap(rangesRef.value));

        watch(() => props.ranges, (newRanges) => {
            rangesRef.value = newRanges;
        });
        
        return {
            rangesRef,
            rangesOverlapping,
        }
    },
    methods: {
        determineColor(isOverlapping: Boolean, text = false) {
            return isOverlapping ? 'red' : text ? 'payne-grey' : 'olive-green'
        },

        removeRange(index: number, rangeGuid: string | undefined) {
            this.rangesRef.splice(index as number, 1)
            const range: IDeleteRangeOrCategory = {
                targetGuid: rangeGuid as string,
                ranges: this.rangesRef,
                index
            }

            this.$emit('rangeRemoved', range)
        },

        updateRangeTitle(newTitle: string, index: number){
            this.$emit('rangeTitleUpdated', { newTitle, index })
        },

        onSliderValueChange(range: IRange, index: number) {
            const rangesOverlapping = hasOverlap(this.rangesRef);
            
            this.$emit('sliderValueChange', {
                isOverLapping: rangesOverlapping,   
                existingRanges: this.rangesRef,
                index
            });
        }
    },

})
</script>



