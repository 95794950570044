import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {AssessorUpsertDto} from "@/areas/assessors/models/dtos/assessorUpsertDto";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";

export class AssessorExtensions {
    static castToAssessmentDisplayObject(delegate: DelegateDto): AssessmentDisplayObject {

        const assessmentDetails = delegate
            .assessment!
            .assessmentDetails
            .filter(det => det.deletedAt == null)
            .pop()

        return {
            guid: delegate.guid!,
            assessment: delegate.assessment!,
            delegate: delegate,
            detail: assessmentDetails!
        }
    }

    static toOptions = (state: AssessorState): [string, string] => {
        switch (state) {
            case AssessorState.Draft:
                return ["bg-blue-grey-darken-1", "Draft"]
            case AssessorState.Invited:
                return ["bg-purple-darken-1", "Invited"]
            case AssessorState.Accepted:
                return ["bg-teal-darken-1", "Accepted"]
            case AssessorState.InProgress:
                return ["bg-green-darken-1", "In Progress"]
            case AssessorState.Completed:
                return ["bg-brown-darken-1", "Completed"]
            case AssessorState.Removed:
                return ["bg-grey-darken-1", "Removed"]
            case AssessorState.Declined:
                return ["bg-red-darken-1", "Declined"]
        }
    }

    static toAssessorDisplayObject(employee: EmployeeDto, assessor: AssessorDto): AssessorDisplayObject {
        const assessorUpsertDto: AssessorUpsertDto = {
            userGuid: employee.user.guid!,
            assessorRelationshipType: assessor.assessorRelationshipType
        }

        return {
            assessor: assessor,
            employee: employee,
            assessorUpsertDto: assessorUpsertDto,
            assessorState: assessor.entityState!
        }
    }
}