<template>
  <Modal
    :modal-description="modalDescription"
    justify="start"
    :modal-title="modalTitle">
    <template v-slot:modal_activator>
       <div>Edit {{ demographicTitle }}</div>
    </template>
    <v-row>
      <DemographicForm
        :selectedDemographic="selectedDemographic"
        :existing-categories="isEditMode ? values : []"
        :existing-ranges="isEditMode ? values : []"
        :index="index"/>
    </v-row>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Modal from '../../AQuarks/Modal.vue';
import DemographicForm from './DemographicForm.vue';

export default defineComponent({
  components: {
    Modal,
    DemographicForm
  },
  props: {
    demographicTitle: String,
    selectedDemographic: Object,
    values: Array,
    index: Number,
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modalTitle() {
      return this.isEditMode ? `Edit ${this.demographicTitle}` : 'Add New Demographic';
    },
    modalDescription() {
      return this.isEditMode ? `This demographic will be edited according to the organisation’s requirements.` : 'Add a new demographic to the organisation.';
    }
  }
});
</script>