import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full px-6 pt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultDemographicsHeader = _resolveComponent("DefaultDemographicsHeader")!
  const _component_DemographicsTable = _resolveComponent("DemographicsTable")!
  const _component_AppLayout = _resolveComponent("AppLayout")!

  return (_openBlock(), _createBlock(_component_AppLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_DefaultDemographicsHeader, { class: "sm:pt-8" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DemographicsTable)
      ])
    ]),
    _: 1
  }))
}