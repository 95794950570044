import {OperationResult, OperationResultOnly} from "@/areas/helpers/responses/models/operationResult";
import {ProblemDetails} from "@/areas/helpers/responses/models/problemDetails";

export class Operations {

    static ToSuccessResponse<TContent>(content: TContent): OperationResult<TContent> {
        return {
            content: content,
            isSuccessful: true
        }
    }

    static ToSuccessResponseOnly(): OperationResultOnly {
        return {
            isSuccessful: true
        }
    }

    static ToErrorResponse<TContent>(error: string): OperationResult<TContent> {
        return {
            isSuccessful: false,
            error: error
        }
    }

    static ToErrorResponseOnly(error: string): OperationResultOnly {
        return {
            isSuccessful: false,
            error: error
        }
    }

    static ToErrorResponseFromProblemDetails<TContent>(error: ProblemDetails): OperationResult<TContent> {
        return {
            isSuccessful: false,
            error: error.type
        }
    }

    static ToErrorResponseFromProblemDetailsOnly(error: ProblemDetails): OperationResultOnly {
        return {
            isSuccessful: false,
            error: error.type
        }
    }

    static async WrapRequest<T>(core: () => Promise<OperationResult<T>>): Promise<OperationResult<T>> {
        try {
            return await core();
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    static async WrapRequestOnly(core: () => Promise<OperationResultOnly>): Promise<OperationResultOnly> {
        try {
            return await core();
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetailsOnly(problemDetails)
            }

            return Operations.ToErrorResponseOnly("RequestFailure")
        }
    }
}