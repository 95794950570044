import {defineStore} from 'pinia'
import {EntityProxy} from "@/areas/entities/proxy/entityProxy";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {User} from "@/areas/users/model/data/User";
import {Operations} from "@/areas/helpers/responses/operations";

interface IState {
    updated_at: Date;
    proxy: EntityProxy;
    entityDto: Entity | null;
}

export const useEntityStore = defineStore({
    id: "entity",
    state: (): IState => ({
        updated_at: new Date(),
        proxy: new EntityProxy(),
        entityDto: null,
    }),
    getters: {
        getOrganisation: (state) => {
            return state.entityDto as Entity
        }
    },
    actions: {
        async createEntity(callingGuid: string, entity: Entity): Promise<OperationResult<Entity | null>> {
            return await this.proxy.create(entity.displayName, entity.brandColour, entity.entityType, callingGuid)
        },

        async fetchEntity(entityGuid: string): Promise<OperationResult<Entity | null>> {
            let entityResult = await this.proxy.fetchEntity(entityGuid)

            if (!entityResult.isSuccessful) {
                // TODO add error handling for this
                return Operations.ToErrorResponse(entityResult.error as string)
            }

            let entity = entityResult.content as Entity

            this.$state.entityDto = entity

            return Operations.ToSuccessResponse(entity)
        },

        async updateEntity(entity: Entity): Promise<OperationResult<Entity | null>> {
            let entityResult = await this.proxy.updateEntity(entity.guid!, entity.displayName!, entity.brandColour!)

            if (!entityResult.isSuccessful) {
                return Operations.ToErrorResponse(entityResult.error as string)
            }
            const updatedEntity = entityResult.content as Entity

            this.$state.entityDto = updatedEntity

            return Operations.ToSuccessResponse(updatedEntity)
        },

        async getUsers(entityGuid: string): Promise<OperationResult<User[] | null>> {
            return await this.proxy.getUsers(entityGuid)
        },

        async deleteEntity(entityGuid: string): Promise<OperationResult<any>> {
            return await this.proxy.deleteEntity(entityGuid)
        },

        // TODO: specify return type better
        async downloadCsv(entityGuid?: string): Promise<OperationResult<any>> {
            return await this.proxy.downloadCsv(entityGuid)
        },

        async uploadLogo(blob: Blob, mimeType: string, entityGuid: string): Promise<OperationResult<Entity>> {

            const uploadRequestResult = await this.proxy.requestAssetUpload(mimeType, entityGuid)

            if (!uploadRequestResult.isSuccessful) {
                return Operations.ToErrorResponse(uploadRequestResult.error!)
            }

            const asset = uploadRequestResult.content!

            // return await this.proxy.uploadLogo(blob, fileType, entityGuid);
            const s3UploadResult = await this.proxy.s3Upload(asset.signedUrl!, blob, mimeType)

            if (s3UploadResult.isSuccessful) {
                const entity = await this.fetchEntity(entityGuid)

                return Operations.ToSuccessResponse(entity.content!)
            }

            //Failed, so delete the open asset on the remote platform to avoid confusion
            await this.proxy.deleteAsset(entityGuid, asset.guid!)

            return Operations.ToErrorResponse(s3UploadResult.error!)
        }
    }
})