import {defineStore} from 'pinia'
import {DelegateProxy} from "@/areas/delegates/proxy/delegateProxy";
import {useAssessorStore} from "@/areas/assessors/store/AssessorStore";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {DelegateExtensions} from "@/areas/delegates/extensions/DelegateExtensions";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";

interface UserAssessorState {
    delegateProxy: DelegateProxy

    delegateGuid: string | undefined

    delegateDisplayItem: DelegateDisplayItem | undefined

    assessment: AssessmentDto | undefined

    entityGuid: string | undefined

    error?: string | null

    isLoading: boolean
}

function clearState(): UserAssessorState {
    return {
        delegateProxy: new DelegateProxy(),

        delegateGuid: undefined,

        delegateDisplayItem: undefined,

        assessment: undefined,

        entityGuid: undefined,

        error: null,

        isLoading: true,
    }
}

export const useUserAssessorStore = defineStore({
    id: "userAssessorStore",
    state: (): UserAssessorState => clearState(),
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true
                await action()
            } catch (e) {
                console.error("Error during action execution", e)
            } finally {
                this.isLoading = false
            }
        },

        async init(entityGuid: string, assessmentGuid: string, delegateGuid: string): Promise<void> {
            this.clearState()

            const assessmentProxy = new AssessmentProxy()

            const assessmentResult = await assessmentProxy.getByGuid(entityGuid, assessmentGuid)

            if (!assessmentResult.isSuccessful) {
                this.error = "The assessment could not be retrieved"

                return
            }

            const delegateProxy = new DelegateProxy()

            const delegateResult = await delegateProxy.get(entityGuid, assessmentGuid, delegateGuid)

            if (!delegateResult.isSuccessful) {
                this.error = "The delegate could not be retrieved"

                return
            }

            this.delegateDisplayItem = DelegateExtensions.toDelegateDisplayItem(delegateResult.content!)
            this.assessment = assessmentResult.content!
            this.delegateGuid = delegateGuid
            this.entityGuid = entityGuid
        },

        async save(assessors: AssessorDisplayObject[]) {
            const assessorUpsertDtos = assessors.map(ad => ad.assessorUpsertDto)

            const updateResult = await this.delegateProxy.update(
                this.entityGuid!,
                this.assessment!.guid!,
                this.delegateGuid!,
                assessorUpsertDtos)

            if (!updateResult.isSuccessful) {
                this.error = "Could not process the update successfully"
            }
        },

        clearError() {
            this.error = null
        },

        clearState() {
            const assessorStore = useAssessorStore()

            assessorStore.clearState()

            clearState()
        },

        async search(searchString?: string): Promise<void> {
            await this.withLoading(async () => {
            })
        }
    }
})