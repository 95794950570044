<template>
  <div class="flex flex-col items-center justify-center w-full">
    <div class="space-y-8 w-full">
      <NavHeading
          heading="Copy Assessment Type"
          @back="emits('back')"
          class="py-4">

        <div class="flex flex-col items-end">
          <GenericButton class="bg-olive-green text-white" @onSubmit="copy()">Copy</GenericButton>
        </div>
      </NavHeading>

      <Card class="flex flex-col items-center justify-center">

        <div class="font-semibold text-payne-grey items-start">
          Select Entity to copy to
        </div>

        <div ref="dropdown" class="relative z-50 flex cursor-pointer">
          <button
              @click="orgDropDown = !orgDropDown"
              class="disabled' flex items-center py-4 px-6 border-l text-payne-grey focus:outline-none"
          >
            <div class="flex flex-row space-x-4">
              <div v-if="selectedEntity">
                <img :src="getCompanyLogo(selectedEntity)" alt="Logo" class="h-6">
              </div>

              <div>
                {{ selectedEntity?.displayName ?? 'Select an entity to copy to' }}
              </div>
              <div>
                <img
                    :src="orgDropDown ? '/img/arrow_up.svg' : '/img/arrow_down.svg'"
                    class="ml-2 w-6"
                    alt="Toggle Dropdown"
                >
              </div>
            </div>
          </button>

          <div v-show="orgDropDown" class="absolute top-0 w-[30rem] bg-white shadow rounded p-2">
            <div class="flex items-center w-full">
              <input
                  v-model="searchString"
                  type="text"
                  placeholder="Search..."
                  class="p-2 w-full border-2 border-gray-200 rounded-l"
              />
              <img src="/img/search.svg" class="w-6" alt="Search Icon">
            </div>

            <div class="overflow-y-auto max-h-40vh mt-2">
              <table class="w-full">
                <tbody>
                <tr
                    v-for="(entity, index) in filteredEntities.sort((a, b) => a.displayName.localeCompare(b.displayName))"
                    :key="index"
                    @click="selectEntity(entity)"
                    class="min-w-full cursor-pointer hover:bg-gray-100"
                >
                  <td class="px-2 py-2 whitespace-nowrap">
                    <img :src="getCompanyLogo(entity)" alt="Logo" class="h-6">
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    {{ entity.displayName }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="entityError" class="font-semibold text-payne-grey items-start">
          {{entityError}}
        </div>

        <div v-if="entityError">
          <div class="text-red w-full text-center bg-red-lighten-5">
            {{entityError}}
          </div>
        </div>

        <Input
            @value-changed="val => assessmentTypeTitle = val"
            label="Assessment Type Name"
            placeholder="Enter a name for the assessment type"
            :required="true"
            :default-value="assessmentTypeTitle"/>

        <div v-if="titleError">
          <div class="text-red w-full text-center bg-red-lighten-5">
            {{titleError}}
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">

import {onMounted, ref, watch} from "vue";
import {UserProxy} from "@/areas/users/proxy/userProxy";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import GenericButton from "@/shared/components/GenericButton.vue";
import NavHeading from "@/shared/components/NavHeading.vue";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import Card from "@/shared/components/Card.vue";
import Input from "@/components/Atomic/AQuarks/Input.vue";
import {AssessmentTypeCopyDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeCopyDto";

const props = defineProps<{
  assessmentTypeDto: AssessmentTypeDto
  entityGuid: string
}>()

const emits = defineEmits<{
  back: []
  copy: [copyDto: AssessmentTypeCopyDto]
}>()

const userEntities = ref<Entity[]>([])

const filteredEntities = ref<Entity[]>([])

const error = ref<string | undefined>(undefined)

const searchString = ref<string | undefined>(undefined)

const selectedEntity = ref<Entity | undefined>(undefined)

const entityError = ref<string | undefined> (undefined)
const titleError = ref<string | undefined> (undefined)

const assessmentTypeTitle = ref<string | undefined>(undefined)

const orgDropDown = ref<boolean>(false)

const getCompanyLogo = (entity: Entity): string | undefined => {
  if (entity.entityAssets != undefined && entity.entityAssets.length > 0) {
    return entity.entityAssets[0].signedUrl
  } else {
    return '/img/avatarIcon.svg'
  }
}

const selectEntity = (entity: Entity) => {
  selectedEntity.value = entity

  orgDropDown.value = false

  searchString.value = undefined
}

onMounted(async () => {
  const userProxy = new UserProxy()

  const entityFetchResult = await userProxy.getUserEntities()

  if (!entityFetchResult.isSuccessful) {
    error.value = "Could not retrieve user information"
  }

  userEntities.value = entityFetchResult.content!.sort((a, b) => a.displayName.localeCompare(b.displayName))

  filteredEntities.value = userEntities.value

  selectedEntity.value = userEntities.value.find(ue => ue.guid === props.entityGuid)

  assessmentTypeTitle.value = props.assessmentTypeDto.displayName
})

watch (() => assessmentTypeTitle.value, (newVal) =>{
  if (!assessmentTypeTitle.value || assessmentTypeTitle.value.length < 2) {
    assessmentTypeTitle.value = "Please provide a valid title for the assessment type that has more than 1 character"
  }
})

watch (() => searchString.value, (newValue) => {
  if (!newValue || newValue.length < 1) {
    filteredEntities.value = userEntities.value
    return
  }

  filteredEntities.value = userEntities.value.filter(ue => ue.displayName.toLowerCase().includes(newValue.toLowerCase()))
})

const copy = () => {

  if (!selectedEntity.value) {
    entityError.value = "Provide a valid organisation to copy to"
  }

  if (!assessmentTypeTitle.value || assessmentTypeTitle.value.length < 2) {
      titleError.value = "Please provide a valid title for the assessment type that has more than 1 character"
  }

  const copyDto: AssessmentTypeCopyDto = {
    toEntityGuid: selectedEntity.value!.guid!,
    newDisplayName: assessmentTypeTitle.value!,
    assessmentTypeGuid: props.assessmentTypeDto.guid!
  }

  if (!entityError.value && !titleError.value) {
    emits('copy', copyDto)
  }
}

</script>