import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 space-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_OrgDetailBranding = _resolveComponent("OrgDetailBranding")!
  const _component_OrganisationDemographics = _resolveComponent("OrganisationDemographics")!
  const _component_AppLayout = _resolveComponent("AppLayout")!

  return (_openBlock(), _createBlock(_component_AppLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DashboardHeader, { tab: 'ORGANISATION' }),
        _createVNode(_component_OrgDetailBranding),
        (_ctx.hasOrganisationGuid)
          ? (_openBlock(), _createBlock(_component_OrganisationDemographics, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}