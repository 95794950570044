<template>
  <Popover location="start" :close-on-click="true">
    <template v-slot:popover_activator>
      <img src="/img/kebab_menu.svg" alt="more vertical icon" :class="`w-6 h-6 cursor-pointer`">
    </template>
    <v-list-item
        class="p-0 m-0">

      <v-list-item-title
          v-if="assessment.assessment.entityState === AssessmentState.Draft"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('setStatus', AssessmentState.Published)"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 16H3C2.45 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V15C16 15.55 15.55 16 15 16ZM11 1C11 1.55 11.45 2 12 2H14.59L5.46 11.13C5.07 11.52 5.07 12.15 5.46 12.54C5.85 12.93 6.48 12.93 6.87 12.54L16 3.41V6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6V0H12C11.45 0 11 0.45 11 1Z" fill="#53687B"/>
            </svg>
          </div>

          <div class="col-span-3">
            Publish
          </div>

        </div>

      </v-list-item-title>

      <v-list-item-title
          v-if="assessment.assessment.entityState === AssessmentState.Published"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('setStatus', AssessmentState.Active)"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 16H3C2.45 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V15C16 15.55 15.55 16 15 16ZM11 1C11 1.55 11.45 2 12 2H14.59L5.46 11.13C5.07 11.52 5.07 12.15 5.46 12.54C5.85 12.93 6.48 12.93 6.87 12.54L16 3.41V6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6V0H12C11.45 0 11 0.45 11 1Z" fill="#53687B"/>
            </svg>
          </div>

          <div class="col-span-3">
            Activate
          </div>

        </div>

      </v-list-item-title>

      <v-list-item-title
          v-if="assessment.assessment.entityState === AssessmentState.Active"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('setStatus', AssessmentState.Completed)"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5971 0.291956C15.6897 0.384848 15.7632 0.495199 15.8133 0.616689C15.8634 0.73818 15.8892 0.868422 15.8892 0.999957C15.8892 1.13149 15.8634 1.26173 15.8133 1.38322C15.7632 1.50471 15.6897 1.61507 15.5971 1.70796L6.6478 10.708C6.55543 10.8011 6.4457 10.875 6.3249 10.9254C6.20409 10.9758 6.07458 11.0017 5.94379 11.0017C5.813 11.0017 5.68349 10.9758 5.56268 10.9254C5.44188 10.875 5.33215 10.8011 5.23978 10.708L1.26231 6.70796C1.0756 6.52018 0.970703 6.26551 0.970703 5.99996C0.970703 5.73441 1.0756 5.47973 1.26231 5.29196C1.44903 5.10418 1.70227 4.99869 1.96633 4.99869C2.23038 4.99869 2.48362 5.10418 2.67034 5.29196L5.94379 8.58596L14.1891 0.291956C14.2814 0.19883 14.3912 0.124944 14.512 0.0745316C14.6328 0.0241187 14.7623 -0.00183105 14.8931 -0.00183105C15.0239 -0.00183105 15.1534 0.0241187 15.2742 0.0745316C15.395 0.124944 15.5047 0.19883 15.5971 0.291956Z" fill="#7D983C"/>
            </svg>
          </div>

          <div class="col-span-3">
            Complete
          </div>

        </div>

      </v-list-item-title>

      <v-list-item-title
          v-if="isActiveState()"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('setStatus', AssessmentState.Cancelled)"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <img src="/img/delete-grey.svg" alt="delete icon" class="w-4 h-4">
          </div>

          <div class="col-span-3">
            Cancel
          </div>

        </div>

      </v-list-item-title>

      <Divider class="py-2" v-if="assessment.assessment.entityState !== AssessmentState.Completed"/>

      <v-list-item-title
          v-if="isActiveState()"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('edit')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <img src="/img/edit.svg" alt="edit icon" class="w-4 h-4">
          </div>

          <div class="col-span-3">
            Edit Assessment
          </div>

        </div>

      </v-list-item-title>

      <v-list-item-title
          v-if="isActiveState() || assessment.assessment.entityState === AssessmentState.Completed"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('manageDelegates')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2851_27875)">
                <path d="M12 0.75H3C2.175 0.75 1.5 1.425 1.5 2.25V12.75H3V2.25H12V0.75ZM11.25 3.75H6C5.175 3.75 4.5075 4.425 4.5075 5.25L4.5 15.75C4.5 16.575 5.1675 17.25 5.9925 17.25H14.25C15.075 17.25 15.75 16.575 15.75 15.75V8.25L11.25 3.75ZM6 15.75V5.25H10.5V9H14.25V15.75H6Z" fill="#53687B"/>
              </g>
              <defs>
                <clipPath id="clip0_2851_27875">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>

          <div class="col-span-3">
            Manage Delegates
          </div>

        </div>

      </v-list-item-title>

    </v-list-item>
  </Popover>
</template>

<script setup lang="ts">

import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import Popover from "@/components/Atomic/AQuarks/Popover.vue";
import DeleteDemographicModal from "@/components/Atomic/BAtoms/Demographics/DeleteDemographicModal.vue";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import Divider from "@/shared/components/Divider.vue";

const props = defineProps<{
  assessment: AssessmentDisplayObject
}>()

const isActiveState = (): boolean => {
  const activeStates: AssessmentState[] = [
    AssessmentState.Draft,
    AssessmentState.Active,
    AssessmentState.Published]

  return activeStates.includes(props.assessment.assessment.entityState)
}

const emits = defineEmits<{
  setStatus: [AssessmentState]
  edit: []
  reports: []
  manageDelegates: []
}>()

</script>