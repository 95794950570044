<template>
  <div class="w-full">
    <div class="flex flex-row justify-between text-payne-grey">
      <div class="flex flex-row space-x-4 items-center">
        <v-switch
            class="pt-5 pl-2"
            color="dark-blue"
            :model-value="assessmentSettings.limitsEnabled"
            @update:modelValue="assessmentSettings.limitsEnabled = !assessmentSettings.limitsEnabled">
        </v-switch>
        <p class="">Assessor Limits</p>
      </div>
      <div class="flex flex-row space-x-4 items-center">
        <v-switch
            class="pt-5 pl-2"
            color="dark-blue"
            :model-value="assessmentSettings.includeLeader"
            :disabled="!inDraft()"
            @update:modelValue="assessmentSettings.includeLeader = !assessmentSettings.includeLeader">
        </v-switch>
        <p class="">Include a Leader</p>
      </div>
      <div class="flex flex-row space-x-4 items-center">
        <v-switch
            class="pt-5 pl-2"
            color="dark-blue"
            :model-value="assessmentSettings.remindersEnabled"
            @update:modelValue="assessmentSettings.remindersEnabled = !assessmentSettings.remindersEnabled">
        </v-switch>
        <p class="">Automated Email Reminders</p>
      </div>
    </div>
    <div class="flex flex-col w-full">
      <div v-if="assessmentSettings.limitsEnabled" class="flex flex-row text-payne-grey justify-between w-full">
        <div class="flex flex-col w-[40%]">
          <p class="">Minimum Number of Assessors</p>
          <v-slider
              v-model="assessmentSettings.minimumAssessors"
              :disabled="!inDraft()"
              class="w-full mt-8"
              :max="50"
              :min="2"
              :step="1"
              :color="minMaxError ? 'wine-red' : 'olive-green'"
              track-color="#566779"
              thumb-label="always">
          </v-slider>
        </div>
        <div class="flex flex-col w-[40%]">
          <p class="">Maximum Number of Assessors</p>
          <v-slider
              v-model="assessmentSettings.maximumAssessors"
              class="w-full mt-8"
              :max="50"
              :min="2"
              :step="1"
              :color="minMaxError ? 'wine-red' : 'olive-green'"
              track-color="#566779"
              thumb-label="always">
          </v-slider>
        </div>
      </div>

      <div v-if="assessmentSettings.limitsEnabled && minMaxError" class="w-full bg-wine-red rounded-md flex flex-col justify-center items-center text-white">
        {{ minMaxError }}
      </div>

      <div v-if="assessmentSettings.remindersEnabled">
        <p class="text-payne-grey">Reminder frequency</p>
        <v-radio-group v-model="assessmentSettings.reminderFrequency" class="mt-8">
          <v-radio class="px-2" label="Daily" value="Daily"></v-radio>
          <v-radio class="px-2" label="Twice a week" value="TwiceWeekly"></v-radio>
          <v-radio class="px-2" label="Weekly" value="Weekly"></v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, onMounted, ref, Ref, watch} from 'vue';
import {ReminderFrequency} from "@/areas/assessments/model/enums/reminderFrequency";
import {AssessmentSettingValues} from "@/areas/assessments/model/data/AssessmentSettingValues";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";

const props = defineProps<{
  assessmentSettings?: AssessmentSettingValues
  assessment?: AssessmentDisplayObject
}>()

const emit = defineEmits<{
  validateSettings: [AssessmentSettingValues]
  inValidateSettings: []
}>()

const minMaxError: Ref<string | undefined> = ref(undefined)

const assessmentSettings: Ref<AssessmentSettingValues> = ref(
    props.assessmentSettings ?? {

      includeLeader: false,
      remindersEnabled: false,
      limitsEnabled: false,

      reminderFrequency: ReminderFrequency.Weekly,
      minimumAssessors: 2,
      maximumAssessors: 8
    })

const validate = () => {
  if (assessmentSettings.value.minimumAssessors! >= assessmentSettings.value.maximumAssessors!) {
    minMaxError.value = "The minimum assessors cannot be more than the maximum assessors"

    emit('inValidateSettings')
  } else {
    minMaxError.value = undefined

    emit("validateSettings", assessmentSettings.value)
  }

};

const inDraft = (): boolean => {
  const state = props.assessment?.assessment?.entityState

  return (!state || state == AssessmentState.Draft)
}

watch([assessmentSettings], validate, {deep: true});

onMounted(() => {

  validate()
})

</script>