<template>
  <div class="flex bg-menu-bg flex-col min-h-screen">
    <!-- Navbar -->

    <div class="bg-white w-full px-4 flex justify-between items-center shadow">
      <!-- Hamburger menu -->
      <button @click="toggleSidebar" class="p-4 focus:outline-none">
        <img src="/img/menu.svg" alt="Menu">
      </button>

      <div v-if="userState">
        <div ref="dropdown" class="relative z-50 border-l flex cursor-pointer">
          <button
              @click="toggleOrgDropdown"
              class="disabled' flex items-center py-4 px-6 border-l text-payne-grey focus:outline-none"
          >
            <div class="flex flex-row">
              {{ selectedEntity?.displayName }}

              <img v-show="userState?.role === UserRole.SuperAdmin"
                   :src="orgDropdown ? '/img/arrow_up.svg' : '/img/arrow_down.svg'"
                   class="ml-2 w-6"
                   alt="Toggle Dropdown"
              >
            </div>
          </button>

          <div v-show="orgDropdown" class="absolute right-0 w-[30rem] bg-white shadow rounded p-2">
            <div class="flex items-center w-full">
              <input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Search..."
                  class="p-2 w-full border-2 border-gray-200 rounded-l"
              />
              <img src="/img/search.svg" class="w-6" alt="Search Icon">
            </div>

            <div class="overflow-y-auto max-h-40vh mt-2">
              <table class="w-full">
                <tbody>
                <tr
                    v-for="(entity, index) in filteredEntities.sort((a, b) => a.displayName.localeCompare(b.displayName))"
                    :key="index"
                    @click="navigateToEntity(entity)"
                    class="min-w-full cursor-pointer hover:bg-gray-100"
                >
                  <td class="px-2 py-2 whitespace-nowrap"><img :src="getCompanyLogo(entity)" alt="Logo" class="h-6">
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">{{ entity.displayName }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidebar -->
    <div v-show="showSidebar" ref="sidebarRef"
         class="fixed top-0 overflow-y-auto z-50 left-0 drop-shadow-md h-full w-64 bg-white text-black">
      <button @click="toggleSidebar()" class="pt-6 pl-6 top-0 left-0 focus:outline-none">
        <img src="../../../public/img/close.svg" alt="">
      </button>
      <div class="border-b border-t flex flex-row items-center space-x-3 justify-around px-6 py-4 mt-4">
        <div class="flex flex-col items-start">
          <h2 class="font-roboto font-medium text-payne-grey">
            Hi, {{ userState ? `${userState.user.firstName} ${userState.user.lastName}` : '' }}
          </h2>
          <div class="">
            {{ userState ? `${userState.role}` : '' }}
          </div>
        </div>
      </div>

      <!-- Navigation -->
      <div class="p-4 pl-6 space-y-6 overflow-y-auto overflow-x-hidden max-h-[300px] 3xl:max-h-[500px] z-10 3xl:mt-6">
        <div class="pl-2">
          <div v-if="userState && userState.role !== UserRole.User ">
            <div @click="toggleDashboardDropdown" class="flex text-payne-grey rounded-md cursor-pointer py-2 space-x-2">
              <!--              <div class="flex flex-row justify-between items-center w-full">-->

              <div class="grid grid-cols-6 w-full gap-x-2">
                <div class="col-span-1">
                  <img src="/img/dashboard.svg" class="w-6" alt="Dashboard">
                </div>

                <div class="col-span-4">
                  Dashboard
                </div>

                <div class="col-span-1">
                  <img v-if="dashDropdown" src="/img/arrow_up.svg" class="w-6" alt="Up arrow">
                  <img v-else src="/img/arrow_down.svg" class="w-6" alt="Down arrow">
                </div>
              </div>
            </div>
            <div v-show="dashDropdown" class="relative left-0 mt-2 pl-2 space-y-4 bg-white text-black p-2">
              <div @click="selectOrganisation()"
                   :class="{ 'bg-[#A3C586] bg-opacity-20 text-dark-green': isLandingPage }"
                   class="cursor-pointer text-payne-grey rounded-md flex flex-row px-2 space-x-2 py-1">
                <img :src="organisationImgSrc" class="w-4" alt="Organisation">
                <p class="font-roboto">Select Organisation</p>
              </div>
              <div @click="selectAssessmentType()"
                   :class="{ 'bg-[#A3C586] bg-opacity-20 text-dark-green': isDefaultAssessmentTypes }"
                   class="cursor-pointer text-payne-grey rounded-md flex flex-row px-2 space-x-2 py-1">
                <img :src="assessmentTypesImgSrc" class="w-4" alt="Assessment Types">
                <p class="font-roboto">Assessment Types</p>
              </div>
              <div @click="selectDemographics()"
                   :class="{ 'bg-[#A3C586] bg-opacity-20 text-dark-green': isDemographics }"
                   class="cursor-pointer text-payne-grey rounded-md flex flex-row px-2 space-x-2 py-1">
                <img :src="demographicsImgSrc" class="w-5" alt="Demographics">
                <p class="whitespace-nowrap font-roboto">Demographics</p>
              </div>
            </div>
          </div>
        </div>

        <div @click="goToUserAssessments" :class="{ 'bg-[#A3C586] bg-opacity-20 text-dark-green': isMyAssessments }"
             class="grid grid-cols-6 cursor-pointer text-payne-grey rounded-md p-2">

          <div class="col-span-1">
            <img :src="assessmentsImgSrc" class="w-6" alt="Assessments">
          </div>

          <div class="font-roboto col-span-4">My Assessments</div>
        </div>

        <div class="relative pl-2">
          <div @click="toggleProfileDropdown" class="flex cursor-pointer space-x-4">
            <div class="flex flex-row justify-between w-full">
              <div class="grid grid-cols-6 gap-x-2 w-full">
                <div class="col-span-1">
                  <img src="/img/profile.svg" class="w-6" alt="Profile">
                </div>

                <div class="col-span-4">
                  <button class="text-payne-grey font-roboto focus:outline-none">Profile</button>
                </div>
                <div class="col-span-1">
                  <img v-if="profileDropdown" src="/img/arrow_up.svg" class="w-6" alt="Up arrow">
                  <img v-else src="/img/arrow_down.svg" class="w-6" alt="Down arrow">
                </div>
              </div>
            </div>
          </div>
          <div v-show="profileDropdown" class="relative left-0 mt-2 bg-white text-black p-2">
            <div @click="changePassword()" class="cursor-pointer flex flex-row space-x-2 py-1">
              <img src="/img/password_lock.svg" class="w-5" alt="Password icon">
              <p class="text-payne-grey font-roboto">Change Password</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Section -->
      <div :class="footerPositionClass" class="bottom-0 left-0 w-full">
        <button @click="logout()" class="flex items-center space-x-4 mb-6 pl-12">
          <img src="/img/logout.svg" alt="Logout icon">
          <div class="font-roboto text-payne-grey">Log out</div>
        </button>
        <div class="px-4">
          <p class="mx-auto px-4 py-2 text-payne-grey rounded-md bg-menu-bg font-roboto">Need help? <br> <a
              href="mailto:support@ivolve.tech" class="text-olive-green text-sm font-medium">Email
            support@ivolve.tech</a></p>
        </div>
        <img src="/img/logo.svg" class="mt-4 w-full border-t p-4" alt="Company Logo">
      </div>
    </div>

    <!-- This is where the child content will be injected -->
    <div
        :class="isLandingPage ? 'flex flex-col justify-around space-y-8 items-center flex-grow p-6' : ''">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  Ref,
  watch
} from 'vue';
import bus from '@/bus';
import {useRoute} from 'vue-router';
import {useUserStore} from '@/areas/users/store/userStore'
import {Entity} from '@/areas/entities/model/data/dtos/entity';
import Tooltip from '@/components/Atomic/AQuarks/Tooltip.vue'
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {UserState} from "@/areas/users/model/state/UserState";
import router from "@/router";
import {EntityTypeEnum} from "@/areas/entities/model/enums/entityTypeEnum";

export default defineComponent({
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tooltip
  },
  setup() {
    const route = useRoute();
    const userStore = useUserStore();

    const userState = ref<UserState | null>(null)
    const entityItems = ref<Entity[]>([]);

    const showSidebar = ref<boolean>(false);
    const orgDropdown = ref<boolean>(false);
    const dropdown: Ref<HTMLElement | null> = ref(null);
    const searchQuery = ref<string>('');

    const profileDropdown = ref<boolean>(false);
    const dashDropdown = ref<boolean>(false);
    const selectedEntity = ref<Entity | null>(null);
    const sidebarRef = ref<HTMLElement | null>(null);

    const screenHeight = ref(window.innerHeight);

    const footerPositionClass = computed(() => {
      if (screenHeight.value <= 640) {
        return 'relative';
      } else if (screenHeight.value > 640) {
        return 'absolute';
      }
    });

    const handleClickOutsideSidebar = (event: MouseEvent) => {
      if (sidebarRef.value && event.target && !sidebarRef.value.contains(event.target as Node)) {
        showSidebar.value = false;
        nextTick(() => {
          document.removeEventListener('click', handleClickOutsideSidebar, true);
        });
      }
    };

    const toggleSidebar = () => {
      showSidebar.value = !showSidebar.value;

      if (showSidebar.value) {
        document.addEventListener('click', handleClickOutsideSidebar, true);
      } else {
        document.removeEventListener('click', handleClickOutsideSidebar, true);
      }
    };

    const fetchEntities = async () => {
      const userEntities = await userStore.listUserEntities()

      if (!userEntities.isSuccessful) {
        console.error(`Could not fetch the list of entities from the remote database: ${userEntities.error}`)
        return
      }

      entityItems.value = userEntities.content!
    };

    function toggleOrgDropdown() {
      if (userState.value && userState.value.role === UserRole.User) {
        return
      }

      orgDropdown.value = !orgDropdown.value;
      if (orgDropdown.value) {
        window.addEventListener('click', handleClickOutside, true);
      } else {
        window.removeEventListener('click', handleClickOutside, true);
      }
    }

    function handleClickOutside(event: MouseEvent) {
      if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
        orgDropdown.value = false;
        nextTick(() => {
          window.removeEventListener('click', handleClickOutside);
        });
      }
    }

    function getCompanyLogo(entity: Entity): string | undefined {
      if (entity.entityAssets != undefined && entity.entityAssets.length > 0) {
        return entity.entityAssets[0].signedUrl
      } else {
        return '/img/avatarIcon.svg'
      }
    }

    async function navigateToEntity(entity: Entity) {
      toggleOrgDropdown()

      await userStore.setActiveEntity(entity)

      if (entity.entityType == EntityTypeEnum.Root) {
        await router.push(`/`)
      } else {
        await router.push(`/dashboard/organisation/${entity.guid}`)

        location.reload()
      }
    }

    const setEntity = async () => {
      let entityGuid = route.params.entityGuid as string

      if (!entityGuid || entityGuid === '') {
        entityGuid = route.params.organisationGuid as string
      }

      if (entityGuid && entityGuid !== '') {
        await userStore.setActiveEntityByGuid(entityGuid);
      }
    }

    const toggleProfileDropdown = () => {
      profileDropdown.value = !profileDropdown.value
    }

    const toggleDashboardDropdown = () => {
      dashDropdown.value = !dashDropdown.value
    }

    const getUserState = async () => {
      userState.value = await userStore.getState()
    }

    //Todo: This is probably one of the worst ways to manage the state of a sidebar. We need to do this properly
    const isLandingPage = computed(() => route.path === '/')

    const isMyAssessments = computed(() => route.path.startsWith('/dashboard/assessments'));

    const isDemographics = computed(() => route.path.startsWith('/dashboard/demographics'));

    const isDefaultAssessmentTypes = computed(() => route.path.startsWith('/dashboard/assessment/types/default'));

    const isRatingScale = computed(() => route.path.startsWith('/dashboard/rating/scale'));

    const filteredEntities = computed(() => {
      if (!searchQuery.value) {
        return entityItems.value;
      }
      return entityItems.value.filter((entity: Entity) =>
          entity.displayName?.toLowerCase().includes(searchQuery.value.toLowerCase())
      )
    })

    onMounted(async () => {
      await setEntity()

      await getUserState();

      await fetchEntities();

      if (selectedEntity.value == null && userState.value != undefined) {
        selectedEntity.value = userState.value?.activeEntity
      }
      // window.addEventListener('click', handleClickOutside);
      bus.on('toggle-org-dropdown', toggleOrgDropdown);
    });

    onBeforeMount(async () => {
      await fetchEntities();
    })

    onBeforeUnmount(() => {
      // document.removeEventListener('click', handleClickOutsideSidebar, true);
      // window.removeEventListener('click', handleClickOutside);

      bus.off('toggle-org-dropdown', toggleOrgDropdown);
    });

    watch(() => userStore.state?.activeEntity, (newValue, oldValue) => {
      selectedEntity.value = newValue as Entity
    });

    return {
      entityItems,
      orgDropdown,
      showSidebar,
      searchQuery,
      isLandingPage,
      selectedEntity,
      profileDropdown,
      dashDropdown,
      filteredEntities,
      isMyAssessments,
      sidebarRef,
      isDemographics,
      isDefaultAssessmentTypes,
      isRatingScale,
      userState,
      dropdown,
      footerPositionClass,
      UserRole,

      toggleSidebar,
      toggleOrgDropdown,
      toggleProfileDropdown,
      toggleDashboardDropdown,
      navigateToEntity,
      getCompanyLogo
    }
  },
  computed: {
    organisationImgSrc() {
      return this.isLandingPage ? '/img/organisation-green.svg' : '/img/organisation.svg';
    },
    assessmentsImgSrc() {
      return this.isMyAssessments ? '/img/assessments-green.svg' : '/img/assessments.svg';
    },
    assessmentTypesImgSrc() {
      return this.isDefaultAssessmentTypes ? '/img/ivolve-assessments-green.svg' : '/img/ivolve-assessments.svg';
    },
    demographicsImgSrc() {
      return this.isDemographics ? '/img/demographics-green.svg' : '/img/demographics.svg';
    },
    ratingScaleImgSrc() {
      return this.isRatingScale ? '/img/default-rating-green.svg' : '/img/default-rating.svg';
    }
  },
  methods: {
    logout() {
      //@ts-ignore
      this.$keycloak.logout();
    },
    changePassword() {
      const keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL;
      const realmName = process.env.VUE_APP_KEYCLOAK_REALM;
      const clientId = process.env.VUE_APP_KEYCLOAK_CLIENT_ID;
      const redirect_uri = process.env.VUE_APP_BASE_APP_URL;
      const changePasswordUrl = `${keycloakUrl}/realms/${realmName}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD&code_challenge=v3edtsDSqpv8xCVtvXU1KxuJZ1soLQp3ZzOz2SyhjMo&code_challenge_method=plain`;

      window.location.href = changePasswordUrl;
    },
    goToUserAssessments() {
      this.$router.push(`/dashboard/entities/${this.userState?.activeEntity.guid}/users/assessments`)
    },
    selectOrganisation() {
      this.$router.push('/');
    },
    selectAssessmentType() {
      this.$router.push(`/dashboard/menus/entities/${this.userState?.activeEntity.guid}/assessments/types`)
    },
    selectDemographics() {
      this.$router.push('/dashboard/demographics/default')
    }
  }
});
</script>

<style scoped>
/* This styles the scrollbar track (the part the thumb slides in) */
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

/* This styles the scrollbar thumb (the part you drag) */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* This sets the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Optionally, you can also style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>