<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="flex flex-row justify-between">
      <h1 class="font-roboto font-bold text-payne-grey text-2xl">
        {{ displayText }}
      </h1>

      <Button
          @click="deleteOrganisation"
          v-if="adminPriv && existingOrganisation && tab === 'ORGANISATION' && activeEntity?.entityType !== EntityTypeEnum.Root "
          imageSrc="/img/delete.svg"
          buttonText="Delete Organisation"
          class="uppercase rounded-md drop-shadow-sm text-red ring-inset ring-1 bg-white ring-red"
      />
    </div>
    <NavTabs :tabs="tabs"/>
    <DeleteModal v-if="showDeleteOrganisationModal" class="absolute"></DeleteModal>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject, onMounted, ref} from 'vue';

import NavTabs from '@/components/Atomic/AQuarks/NavTabs.vue';
import {useRoute, useRouter} from 'vue-router';
import {AdminTabs, IOrganisationTabs} from '@/types/shared'
import Button from '@/components/Atomic/AQuarks/Button.vue';
import DeleteModal from '@/components/Atomic/BAtoms/DeleteModal.vue';
import {useEntityStore} from '@/areas/entities/store/entityStore';
import bus from '@/bus';
import {useUserStore} from "@/areas/users/store/userStore";
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {EntityTypeEnum} from "@/areas/entities/model/enums/entityTypeEnum";

export default defineComponent({
  name: 'DashboardHeader',
  components: {
    NavTabs,
    Button,
    DeleteModal
  },
  props: {
    tab: {
      type: String as () => unknown as () => AdminTabs,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const entityStore = useEntityStore();
    const userStore = useUserStore();

    const activeEntity = ref<Entity | undefined>(undefined)

    const existingOrganisation = ref(false);
    const showDeleteOrganisationModal = ref<boolean>(false);

    const adminPriv = ref<boolean>(false);

    const orgGuid = route.params.organisationGuid as string ?? route.params.entityGuid as string
    const displayText = ref<string>('');

    const tabs = ref<IOrganisationTabs[]>([
      {name: 'Assessments', href: `/dashboards/entities/${orgGuid && orgGuid}/assessments`, current: false},
      {name: 'Organisation', href: `/dashboard/organisation/${orgGuid && orgGuid}`, current: false},
      {name: 'Employees', href: `/dashboard/employees/${orgGuid && orgGuid}`, current: false},
      {name: 'Assessment Types', href: `/dashboard/entities/${orgGuid && orgGuid}/assessments/types`, current: false},
    ]);

    if (orgGuid) {
      existingOrganisation.value = !!existingOrganisation;
    }

    const checkUserState = async () => {
      const userState = await userStore.getState()

      activeEntity.value = userState.activeEntity
      displayText.value = activeEntity.value!.displayName === 'Select Organisation' || activeEntity.value!.displayName === 'iVolve Root Admin' ? 'Create Organisation' : activeEntity.value.displayName;

      adminPriv.value = userState.role == UserRole.SuperAdmin
    }

    onMounted(() => {
      checkUserState()
      bus.on('cancel-delete', cancelDeleteOrganisation)
    });

    const cancelDeleteOrganisation = (): void => {
      showDeleteOrganisationModal.value = false;
    }

    return {
      tabs,
      adminPriv,
      orgGuid,
      entityStore,
      existingOrganisation,
      showDeleteOrganisationModal,
      activeEntity,
      displayText,
      EntityTypeEnum
    }
  },
  methods: {
    deleteOrganisation() {
      this.showDeleteOrganisationModal = true;
    },
    cancelDeleteOrganisation() {
      this.showDeleteOrganisationModal = false;
    }
  }
});

</script>