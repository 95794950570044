import axiosInstance from "@/axiosConfig";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {Operations} from "@/areas/helpers/responses/operations";
import {ProblemDetails} from "@/areas/helpers/responses/models/problemDetails";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";

export class DemographicsProxy {

  public async list(entityGuid: string) : Promise<OperationResult<Demographic[]>> {
    const uri = `/entities/${entityGuid}/demographics?showDisabled=true`

    try {
      const response = await axiosInstance.get<Demographic[]>(uri);

      return Operations.ToSuccessResponse(response.data);
    } catch (error: any) {
      if (error.response && error.response.status != 500) {
        const response = error.response;

        const problemDetails: ProblemDetails = {
          detail: response.data.detail || "Unknown error",
          status: response.status,
          type: response.data.type,
        };

        return Operations.ToErrorResponseFromProblemDetails(problemDetails);
      }

      return Operations.ToErrorResponse("RequestFailure");
    }
  }

  public async create(entityGuid: string, demographic: Demographic) : Promise<OperationResult<Demographic>> {
    const uri = `/entities/${entityGuid}/demographics`

    try {
      const response = await axiosInstance.post<Demographic>(uri, demographic)

      return Operations.ToSuccessResponse(response.data);
    } catch (error: any) {

      if (error.response && error.response.status != 500) {
        const response = error.response
        
        const problemDetails: ProblemDetails = {
            detail: response.data.detail || "Unknown error",
            status: response.status,
            type: response.data.type,
        }

        return Operations.ToErrorResponseFromProblemDetails(problemDetails)
      }

      return Operations.ToErrorResponse("RequestFailure")
    }
  }

  public async update(entityGuid: string, demographicGuid: string, demographic: Demographic) : Promise<OperationResult<Demographic>> {
    const uri = `/entities/${entityGuid}/demographics/${demographicGuid}`

    try {
      const response = await axiosInstance.put<Demographic>(uri, demographic)

      return Operations.ToSuccessResponse(response.data)

    } catch (error : any) {
      if (error.response && error.response.status != 500) {
          const response = error.response
          
          const problemDetails: ProblemDetails = {
              detail: response.data.detail || "Unknown error",
              status: response.status,
              type: response.data.type,
          }

          return Operations.ToErrorResponseFromProblemDetails(problemDetails)
      }

      return Operations.ToErrorResponse("RequestFailure")
    }
  }

  public async toggleEnable(entityGuid: string, demographicGuid: string) : Promise<OperationResult<Demographic>> {
    const uri = `/entities/${entityGuid}/demographics/${demographicGuid}/states`

    try {
      const response = await axiosInstance.put<Demographic>(uri)

      return Operations.ToSuccessResponse(response.data)

    } catch (error : any) {
      if (error.response && error.response.status != 500) {
          const response = error.response
          
          const problemDetails: ProblemDetails = {
              detail: response.data.detail || "Unknown error",
              status: response.status,
              type: response.data.type,
          }

          return Operations.ToErrorResponseFromProblemDetails(problemDetails)
      }

      return Operations.ToErrorResponse("RequestFailure")
    }
  }

  public async deleteDemographic(organisationGuid: string, demographicGuid: string) : Promise<OperationResult<Demographic>> {
    const uri = `/entities/${organisationGuid}/demographics/${demographicGuid}`

    try {
      const response = await axiosInstance.delete<Demographic>(uri);

      return Operations.ToSuccessResponse(response.data)

    } catch (error : any) {
      if (error.response && error.response.status != 500) {
          const response = error.response
          
          const problemDetails: ProblemDetails = {
              detail: response.data.detail || "Unknown error",
              status: response.status,
              type: response.data.type,
          }

          return Operations.ToErrorResponseFromProblemDetails(problemDetails)
      }

      return Operations.ToErrorResponse("RequestFailure")
    }
  }
}
