export const colors = {
    primary: '#000000',
    'daewoo': '#1F9CDE',
    'red': '#F72323',
    'olive-green': '#7D983C',
    'payne-grey': '#53687B',
    'pastel-green': '#A3C586',
    'dark-green': '#5C7544',
    'blue': '#698C9A',
    'light-grey': '#C5CCD7',
    'dark-blue': '#4164AF',
    'yellow': '#FFCE34',
    'light-green': '#9FCC3B',
    'wine-red': '#9B1B1F',
    'dark-red': '#831F27',
    'white': '#FFFFFF',
    'bg-color': '#F7F7F7',
    'box-bg': '#EBEBEB',
    'menu-bg': '#F4F6F8',
    'box-stroke': '#D8D8D8',
    'payne': '#566779'
  }