<template>
  <div v-if="!domainStore.isLoading && domainStore.activeDomain" class="flex flex-col space-y-4">
    <NavHeading
        heading="Manage Domains"
        @back="back()"
        class="py-4">

      <div class="flex flex-col items-end">
        <GenericButton class="bg-olive-green text-white" :disabled="!canEdit()" @onSubmit="async () => await save()">Save Domain
        </GenericButton>
      </div>
    </NavHeading>

    <Card class="p-0">
      <TabPropertyNavigation :selected-tab="selectedTab" :tabs="tabs" @tabChange="val => onTabChange(val, false)"/>
    </Card>

    <Card class="flex flex-row items-center justify-between text-payne-grey px-4">
      <div class="flex-1 font-semibold">
        Domain Color:
      </div>

      <div class="w-1/2">
        <ColorPicker
            v-model="domainColor"
            placeholder="Domain Color"
            :color="domainColor"
            :disabled="!canEdit()"
        />

        <div v-if="domainColorError" class="text-red w-full text-center bg-red-lighten-5">
          {{ domainColorError }}
        </div>
      </div>
    </Card>

    <Card class="flex flex-col space-y-4">
      <QuestionManager
          v-for="question of domainStore.activeDomain.questions" :key="question.questionDto.key"
          :question="question"
          :is-open="!!(openQuestion) && question.questionDto.key == openQuestion"
          :can-edit="canEdit()"
          :validate="validate"
          @isOpen="val => setIsOpen(question, val)"
          @update="val => updateQuestion(val)"
      />
    </Card>
  </div>

  <PopupConfirmation
      v-if="moveAway"
      title="Unsaved Changes"
      @confirm="confirm"
      @cancel="moveAway = undefined">

    There are unsaved changes on this domain. Are you sure you want to continue?
  </PopupConfirmation>

  <PopupErrorMessage
      v-if="domainStore.error"
      :message="domainStore.error"
      @closed="domainStore.clearError()"/>


  <LoadingCard :is-loading="domainStore.isLoading"/>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import {
  getDomainDisplayTitle,
  useAssessmentTypeDomainStore
} from "@/areas/assessmentTypes/store/assessmentTypeDomainStore";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import GenericButton from "@/shared/components/GenericButton.vue";
import NavHeading from "@/shared/components/NavHeading.vue";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import Card from "@/shared/components/Card.vue";
import ColorPicker from "@/shared/components/inputs/ColorPicker.vue";
import QuestionManager from "@/areas/assessmentTypes/components/QuestionManager.vue";
import {AssessmentTypeState} from "@/areas/assessmentTypes/model/data/enums/AssessmentTypeState";
import {QuestionManageDo} from "@/areas/assessmentTypes/model/dos/QuestionManageDo";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import TabPropertyNavigation, {TabProperty} from "@/shared/components/TabPropertyNavigation.vue";

const domainStore = useAssessmentTypeDomainStore()

const props = defineProps<{
  entityGuid: string
  assessmentType: AssessmentTypeDto
}>()

const emits = defineEmits<{
  back: []
}>()

const tabs = ref<TabProperty[]>([])

const selectedTab = ref<TabProperty | undefined>(undefined)

const moveAway = ref<TabProperty | undefined>(undefined)

const validate = ref<boolean>(false)

const openQuestion = ref<string | undefined>(undefined)

const domainColor = ref<string | undefined>(undefined)

const domainColorError = ref<string | undefined>(undefined)

const ignoreChanges = ref<boolean>(false)

const onTabChange = (tab: TabProperty, force: boolean = false) => {
  ignoreChanges.value = true

  if (domainStore.activeDomain?.isChanged && !force) {
    moveAway.value = tab

    ignoreChanges.value = false
    return
  }

  moveAway.value = undefined

  domainStore.setActiveDomainByName(tab.name)

  const color = domainStore.activeDomain?.domainDto.colour

  domainColor.value = color == '' ? undefined : color

  selectedTab.value = tab

  validate.value = false

  openQuestion.value = undefined

  domainColorError.value = undefined
}

const setIsOpen = (question: QuestionManageDo, isOpen: boolean) => {
  if (!isOpen) {
    openQuestion.value = undefined
    return
  }

  openQuestion.value = question.questionDto.key
}

const canEdit = (): boolean => {
  return props.assessmentType.entityState! == AssessmentTypeState.Draft
}

const updateQuestion = (questionManageDo: QuestionManageDo) => {
  domainStore.setQuestionValue(questionManageDo)

  selectedTab.value!.edited = domainStore.activeDomain!.isChanged;
}

const back = () => {
  const editedTab = tabs.value.find(x => x.edited)

  if (editedTab) {
    moveAway.value = selectedTab.value
  } else {
    emits('back')
  }
}

const confirm = () => {
  if (moveAway.value?.name != selectedTab.value?.name) {
    onTabChange(moveAway.value!, true)

    moveAway.value = undefined

    return
  }

  moveAway.value = undefined

  emits('back')
}

const save = async () => {
  validate.value = true

  await domainStore.save()

  if (!domainStore.error) {
    Toast.success("Successfully updated Domain", toastOptions)

    selectedTab.value!.edited = false

    validate.value = false
  }
}

onMounted(async () => {
  await domainStore.init(props.entityGuid, props.assessmentType.guid!)

  tabs.value = domainStore.domainManageDos.map(d => {
    return {
      edited: false,
      name: getDomainDisplayTitle(d)
    }
  })

  selectedTab.value = tabs.value[0]

  domainStore.setActiveDomainByName(tabs.value[0].name)

  domainColor.value = domainStore.activeDomain?.domainDto.colour

  onTabChange(selectedTab.value, true)
})

watch(() => validate.value, (newValue) => {
  if (newValue) {
    if (!domainColor.value) {
      domainColorError.value = "Please select a color for the domain in question dd"
    }
  }
})


watch(() => domainColor.value, (val) => {
  if ((!val && !ignoreChanges.value) || val === '') {
    domainColorError.value = "Please select a color for the domain in question"
  } else {
    domainColorError.value = undefined
  }

  ignoreChanges.value = false

  domainStore.setColor(val)
})
</script>