import {defineStore} from 'pinia'
import {EntityUserProxy} from "@/areas/entities/proxy/entityUserProxy";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import keycloak from "@/keycloak";
import {DelegateProxy} from "@/areas/delegates/proxy/delegateProxy";
import {AssessorExtensions} from "@/areas/assessors/extensions/AssessorExtensions";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import {DelegateExtensions} from "@/areas/delegates/extensions/DelegateExtensions";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {DelegateState} from "@/areas/delegates/model/enums/delegateState";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";

export interface AssessorStoreState {
    userEntityProxy: EntityUserProxy

    delegateProxy: DelegateProxy

    assessmentDo: AssessmentDisplayObject | undefined

    assessorDisplayObjects: AssessorDisplayObject[]

    assessorResults: AssessorDisplayObject | undefined

    entityGuid: string | undefined

    error: string | undefined

    isLoading: boolean

    delegateDisplayItem: DelegateDisplayItem | undefined
}

function clearStore(): AssessorStoreState {
    return {
        userEntityProxy: new EntityUserProxy(),

        delegateProxy: new DelegateProxy(),

        assessmentDo: undefined,

        assessorDisplayObjects: [],

        assessorResults: undefined,

        entityGuid: undefined,

        error: undefined,

        isLoading: true,

        delegateDisplayItem: undefined
    }
}

export const useAssessorStore = defineStore({
    id: "assessorStore",
    state: (): AssessorStoreState => clearStore(),
    getters: {
        inStore: (state) => {
            return (employee: EmployeeDto): boolean => {
                return state.assessorDisplayObjects.some(ado =>
                    ado.employee.user.guid == employee.user.guid)
            }
        },

        canSave: (state) => {
            return (): boolean => {
                return state.assessmentDo!.delegate!.selectOwnAssessors
            }
        }
    },
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async initByGuids(entityGuid: string, assessmentGuid: string, delegateGuid: string): Promise<void> {
            await this.withLoading(async () => {
                const delegateFetchResult = await this.delegateProxy.get(
                    entityGuid,
                    assessmentGuid,
                    delegateGuid);

                if (!delegateFetchResult.isSuccessful) {
                    this.error = "Delegate Retrieval failed"

                    return
                }

                const assessmentDisplayObject = AssessorExtensions.castToAssessmentDisplayObject(delegateFetchResult.content!)

                const delegateDisplayObject = DelegateExtensions.toDelegateDisplayItem(delegateFetchResult.content!)

                await this.init(entityGuid, assessmentDisplayObject, [], delegateDisplayObject)
            })
        },

        async init(entityGuid: string, assessmentDo: AssessmentDisplayObject, draftAssessors: AssessorDisplayObject[], delegate?: DelegateDisplayItem): Promise<void> {
            await this.withLoading(async () => {
                this.clearState()

                this.assessmentDo = assessmentDo
                this.entityGuid = entityGuid

                this.assessorDisplayObjects = draftAssessors
                this.delegateDisplayItem = delegate

                if (!delegate?.delegateDto?.assessors) {
                    return
                }

                let assessorDisplayObjects: AssessorDisplayObject[] = []

                const assessors = delegate.delegateDto.assessors

                for (const assessor of assessors) {
                    if (assessor.entityState == 'Removed') {
                        continue
                    }

                    const employeeFetchResult = await this.userEntityProxy.getUser(entityGuid, assessor.user!.guid!)

                    if (!employeeFetchResult.isSuccessful) {
                        this.error = `Could not retrieve the employee details of the user: ${assessor.guid}`
                        return
                    }

                    const employee = employeeFetchResult.content!

                    const assessorDisplayObject = AssessorExtensions.toAssessorDisplayObject(employee, assessor)

                    assessorDisplayObjects.push(assessorDisplayObject)
                }

                this.assessorDisplayObjects.push(...assessorDisplayObjects)
            })
        },

        async addAssessor(participantDo: ParticipantDo) {
            participantDo.employees.forEach(e => {
                const assessorDisplayObject: AssessorDisplayObject = {
                    employee: e,
                    assessorUpsertDto: {
                        userGuid: e.user.guid!
                    },
                    assessorState: AssessorState.Draft
                }

                this.upsertAssessor(assessorDisplayObject)
            })
        },

        async setRelationship(assessor: AssessorDisplayObject, relationship: AssessorRelationshipType) {
            const index = this.assessorDisplayObjects.findIndex(as => as.employee.user.guid == assessor.employee.user.guid)

            if (index === -1) {
                return
            }

            this.assessorDisplayObjects[index].assessorUpsertDto.assessorRelationshipType = relationship
            this.assessorDisplayObjects[index].error = undefined
        },

        async viewResults(assessor: AssessorDisplayObject) {
            this.assessorResults = assessor
        },

        clearResults() {
            this.assessorResults = undefined
        },

        upsertAssessor(assessorDisplayObject: AssessorDisplayObject) {
            const existingAssessor = this.assessorDisplayObjects.find(ado =>
                ado.employee.user.guid == assessorDisplayObject.employee.user.guid)

            if (existingAssessor) {
                return
            }

            const loggedInUser = keycloak.tokenParsed;

            if (loggedInUser?.sub! == assessorDisplayObject.assessorUpsertDto.userGuid) {
                this.error = "You cannot add yourself to the list"
                return
            }

            this.assessorDisplayObjects.push(assessorDisplayObject)
        },

        deleteAssessor(assessorDisplayObject: AssessorDisplayObject) {
            this.assessorDisplayObjects = this
                .assessorDisplayObjects
                .filter(ado =>
                    ado.assessorUpsertDto.userGuid != assessorDisplayObject.assessorUpsertDto.userGuid)
        },

        update(forceValidation: boolean) {
            this.clearError()

            if (this.delegateDisplayItem?.delegateType !== DelegateType.Team) {
                this.validateRelationships()

                if (this.error) {
                    return
                }
            }

            if (this.delegateDisplayItem!.delegateType == DelegateType.Team ||
                (this.delegateDisplayItem?.delegateDto && this.delegateDisplayItem.delegateDto.entityState == DelegateState.Ready) ||
                forceValidation) {

                this.validateSave()
            }
        },

        validateRelationships() {
            let relationshipError = false

            this.assessorDisplayObjects.forEach((assessor, index) => {
                if (!assessor.assessorUpsertDto.assessorRelationshipType && !(this.delegateDisplayItem?.individual?.guid == assessor.employee.user.guid)) {
                    this.assessorDisplayObjects[index].error = "Please select a relationship for this assessor"

                    relationshipError = true
                }
            })

            if (relationshipError) {
                this.error = "Please review assessors. Some assessors do not have a relationship set"
            }
        },

        validateSave() {
            this.clearError()

            const assessmentDetail = this.assessmentDo!.detail

            const minAssessors = assessmentDetail.minimumAssessors ?? 2

            const totalAssessors = this.assessorDisplayObjects.length

            if (totalAssessors < minAssessors) {
                this.error = `You currently have ${totalAssessors} assessors and you need a minimum of ${minAssessors} assessors. Please add more assessors`
                return
            }

            if (assessmentDetail.maximumAssessors && totalAssessors > assessmentDetail.maximumAssessors) {
                this.error = `You currently have ${totalAssessors} assessors and can only have a maximum of ${minAssessors} assessors. Please remove assessors`
                return
            }

            if (assessmentDetail.includeLeader) {
                const containsLeader = this
                    .assessorDisplayObjects
                    .some(ado => ado.assessorUpsertDto.assessorRelationshipType == AssessorRelationshipType.Leader)

                if (!containsLeader) {
                    this.error = "You need to add at least one leader to this assessment"
                    return
                }
            }
        },

        async sendReminder(assessor: AssessorDisplayObject) {
            const proxy = new AssessorProxy()

            const result = await proxy.sendReminder(
                this.entityGuid!,
                this.assessmentDo?.assessment!.guid!,
                this.delegateDisplayItem?.delegateDto!.guid!,
                assessor.assessor?.guid!)

            if (!result.isSuccessful) {
                this.error = "Could not send the reminder at this time"
                console.warn(`Could not send reminder: ${result.error}`)
            }
        },

        clearError() {
            this.error = undefined
        },

        clearState() {
            clearStore()
        },

        async search(searchString?: string): Promise<void> {
            await this.withLoading(async () => {
            })
        }
    }

});