<template>
  <SearchInputCard
      v-if="!userAssessmentStore.isLoading"
      class="py-2 w-full"
      @searchUpdate="onSearchChange"
  />

  <!-- This is for mobile to show a card -->
  <div
      v-if="!userAssessmentStore.isLoading"
      v-for="userAssessment in filteredResult"
      :key="userAssessment.assessment.guid"
      class="flex flex-col space-y-2 w-full">

    <UserAssessmentCard
        :user-assessment-dto="userAssessment"
        @accept="async () => await accept(userAssessment)"
        @decline="async() => await decline(userAssessment)"
        @complete="async() => await complete(userAssessment)"
    />
  </div>

  <div v-if="userAssessmentStore.userAssessments.length === 0 && !userAssessmentStore.isLoading">

    <Card>
      <div class="py-4 font-roboto text-center text-payne-grey">
        You have not been invited to complete any Assessments.
      </div>
    </Card>

  </div>

  <LoadingCard :is-loading="userAssessmentStore.isLoading"></LoadingCard>

  <PopupErrorMessage
      v-if="userAssessmentStore.error"
      :message="userAssessmentStore.error"
      @closed="userAssessmentStore.clearError()"
  />

</template>

<script setup lang="ts">

import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import Card from "@/shared/components/Card.vue";
import {defineEmits, onMounted, ref, watch} from "vue";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import {useUserAssessmentStore} from "@/areas/userAssessments/stores/UserAssessmentStore";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import UserAssessmentCard from "@/areas/userAssessments/components/UserAssessmentCard.vue";
import {UserAssessmentDto} from "@/areas/assessments/model/dtos/UserAssessmentDto";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import SearchInputCard from "@/shared/components/SearchInputCard.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";

const props = defineProps<{
  entityGuid: string
}>()

const emit = defineEmits<{
  onClickRow: [assessment: AssessmentDisplayObject]
  startAssessment: [UserAssessmentDto]
}>()

const searchTerm = ref<string | undefined>(undefined)

const userAssessmentStore = useUserAssessmentStore()

const filteredResult = ref<UserAssessmentDto[]>(userAssessmentStore.userAssessments)

const accept = async (userAssessment: UserAssessmentDto) => {
  await userAssessmentStore.transition(userAssessment, AssessorState.Accepted)
}

const decline = async (userAssessment: UserAssessmentDto) => {
  await userAssessmentStore.transition(userAssessment, AssessorState.Declined)
}

const complete = async (userAssessment: UserAssessmentDto) => {
  emit('startAssessment', userAssessment)
}

const onSearchChange = (searchValue: string | undefined) => {
  const lowerTerm = searchValue?.toLowerCase()

  if (!lowerTerm || lowerTerm.length === 0) {
    filteredResult.value = userAssessmentStore.userAssessments
    return
  }

  filteredResult.value = userAssessmentStore.userAssessments.filter(ua =>
      ua.delegate.displayName.toLowerCase().includes(lowerTerm) ||
      ua.assessmentDetail.title.toLowerCase().includes(lowerTerm))
}

watch (() => userAssessmentStore.userAssessments, () => {
  filteredResult.value = userAssessmentStore.userAssessments

  onSearchChange(searchTerm.value)
})

onMounted(async () =>{
  searchTerm.value = undefined

  await userAssessmentStore.init(props.entityGuid)
})

</script>