<template>
  <div v-if="isModalOpen">
    <Modal
        justify="start"
        :modal-width="modalWidth"
        classes="py-0 px-0 modal"
        :show-x-icon="false"
        :is-modal-open="isModalOpen"
        class="ml-0 "
        :modal-title="`Are you sure?`">
      <template v-slot:modal_activator>
        <div class="cursor-pointer w-full">
          <img src="/img/delete-grey.svg" alt="delete icon" class="w-6 h-6 d-inline-block pr-3">
          <span class="pl-1 pr-2">Delete {{ user }}</span>
        </div>
      </template>
      <div class="space-y-4">
        <p class="font-roboto text-payne-grey mt-4">
          You are about to permanently delete <span class="font-roboto font-bold">{{ user }}</span>
          This action <span class="font-roboto font-bold">cannot be undone.</span>
        </p>

        <div class="flex flex-col border-b pb-4 justify-start space-y-2 mt-4 text-payne-grey">
          <p>Type "{{ confirmationText }}" below to confirm</p>

          <input v-model="inputText" type="text" class="w-full border rounded">
        </div>

        <div class="flex justify-between space-x-2">
          <v-btn class="flex-grow bg-white text-payne-grey " @click="handleCancel">
            Cancel
          </v-btn>
          <v-btn class="flex-grow bg-red text-white"
                 :disabled="inputText !== confirmationText"
                 @click="handleDelete">
            Delete
          </v-btn>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script lang="ts">


import {defineComponent, PropType, ref} from "vue";
import Modal from "@/components/Atomic/AQuarks/Modal.vue";

export default defineComponent({
  components: {
    Modal
  },
  props: {
    justify: {
      type: String as PropType<string>,
      default: "start",
      required: false
    },

    modalWidth: {
      type: Number as PropType<number>,
      default: 25,
      required: false
    },

    user: {
      type: String as PropType<string>,
      default: "Are you sure you want to delete",
      required: false
    },

    confirmationText: {
      type: String as PropType<string>,
      default: "delete",
      required: false
    }
  },
  emits: {
    'cancelled': null,
    'deleted': null
  },
  data() {
    return {
      inputText: ''
    };
  },
  setup(_, {emit}) {
    const isModalOpen = ref<boolean>(true);

    function handleDelete() {
      emit('deleted')
    }

    function handleCancel() {
      isModalOpen.value = false;
    }

    return {
      handleDelete,
      handleCancel,

      isModalOpen
    }
  }
})
</script>