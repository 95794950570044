<template>
  <div class="flex flex-col pt-4 space-y-4 touch-manipulation">
    <div v-if="showHeader" class="grid grid-cols-8 justify-between text-center text-payne-grey">
      <div class="col-span-2 flex justify-start items-center">
        <BackButton heading="EXIT" @back="exit(false)" class="text-xl"/>
      </div>

      <div class="col-span-4 flex flex-col space-y-2 text-center">
        <HeadingPrimary>
          {{ assessmentDisplayObject.detail.title }}
        </HeadingPrimary>
      </div>
    </div>

    <div v-if="!questionStore.isLoading" class="space-y-2">
      <div class="bg-white border-b-4 border-b-dark-blue text-center text-lg py-2 text-dark-blue rounded-md">
        {{ getHeading() }}
      </div>

      <AnswerComplete
          v-if="questionStore.state == AnswerState.Completed"
          :domain="questionStore.activePage!"
          :validate="validateQuestionComplete"
          :delegate="delegate"
          :readonly="readonly"
          :comments="questionStore.commentResults"
          :individual="delegate.individual"
          @valid="validCompleteComment"
          @invalid="validQuestionComplete = false"
      />

      <AnswerWelcome
          v-if="questionStore.state == AnswerState.NotStarted"
          :delegate="props.delegate"
          :assessment-type="questionStore.assessmentType!"/>

      <div v-if="questionStore.state == AnswerState.InProgress" class="space-y-4">
        <Card class="space-y-2 text-payne-grey items-center text-left">
          <div v-if="questionStore.activePage?.domainType == DomainType.Welcome">
            <strong>Rating Options</strong>
            <ol>
              <li>1: Toxic</li>
              <li>2: Strained</li>
              <li>3: Neutral</li>
              <li>4: Satisfactory</li>
              <li>5: Amicable</li>
              <li>6: Positive</li>
              <li>7: Strong</li>
              <li>8: Very Strong</li>
              <li>9: Excellent</li>
              <li>10: Outstanding</li>
            </ol>
          </div>

          <div v-if="questionStore.activePage?.domainType == DomainType.AssessDomain">
            <strong>Rating Options</strong>
            <ol>
              <li>1: Never</li>
              <li>2: Almost never</li>
              <li>3: Rarely</li>
              <li>4: Occasionally</li>
              <li>5: Sometimes</li>
              <li>6: Fairly often</li>
              <li>7: Often</li>
              <li>8: Usually</li>
              <li>9: Almost always</li>
              <li>10: Always</li>
            </ol>
          </div>
        </Card>

        <AnswerCard
            v-for="question in questionStore.activePage?.questions"
            :key="question.guid"
            :question="question"
            :delegate="delegate"
            :individual="delegate.individual"
            :readonly="props.readonly"
            :validate="validate"
            :show-heart="questionStore.activePage?.domainType != DomainType.Welcome"
            @rate="rating => questionStore.setRating(question, rating)"
            :lowest-heading="questionStore.activePage?.domainType == DomainType.Welcome ? 'Toxic' : 'Never'"
            :highest-heading="questionStore.activePage?.domainType == DomainType.Welcome ? 'Outstanding' : 'Always'"
        />

        <!--      Comment section-->
        <Card class="p-4">
          <div class="relative border py-2">

            <div class="absolute top-0 left-2 -translate-y-1/2 bg-white text-payne-grey pr-2">
              Provide a reason for your ratings above
            </div>

            <div class="pt-1">
              <textarea
                  v-model="comment"
                  class="w-full h-32 p-2 resize-none overflow-auto focus:outline-none"
                  placeholder="Your text here..."
                  :disabled="props.readonly"/>
            </div>

            <div v-if="validate && comment.length < 10">
              <div class="text-red w-full text-center bg-red-lighten-5">
                Please provide an answer with more than 10 characters.
              </div>
            </div>

          </div>
        </Card>
      </div>

      <!--      This is to add padding for the back and next buttons at the bottom of the screen-->
      <div class="pb-12"></div>

      <div class="fixed inset-x-0 bottom-0 flex justify-center">
        <div
            class="w-full md:max-w-2xl flex flex-row justify-between items-center text-payne-grey px-4 space-x-8 py-2 bg-menu-bg">
          <div
              @click="back(false)"
              class="text-uppercase font-semibold hover:cursor-pointer md:text-lg"
              :class="{
                  'text-gray-400': questionStore.activeIndex == 0,
                  'underline text-olive-green': questionStore.activeIndex >= 0
              }">
            Back
          </div>

          <div class="flex-grow-1">
            <v-progress-linear
                :model-value="questionStore.state == AnswerState.NotStarted ? 0 : (questionStore.activeIndex + 1)/(questionStore.resultState.length + 1) * 100"
                height="6"
                rounded="rounded"
                color="olive-green"
            />
          </div>

          <div
              @click="next"
              class="text-olive-green text-uppercase font-semibold underline md:text-lg"
              :class="{
                'text-payne-grey hover:cursor-not-allowed' : questionStore.state == AnswerState.Completed && props.readonly,
                'hover:cursor-pointer': !props.readonly,
              }">
            {{ getNextHeading() }}
          </div>

        </div>
      </div>

    </div>

    <LoadingCard :is-loading="questionStore.isLoading"/>

    <PopupMessageDisplay v-if="showBackPopup">
      <div class="flex flex-row justify-center items-center">
        <div class="md:max-w-2xl bg-white flex flex-col px-4 rounded-t-md md:rounded-md pt-4 space-y-4">
          <div class="text-payne-grey text-center">
            You have made changes on this screen. If you move away your changes will be discarded
          </div>

          <div class="flex flex-row justify-between space-x-2 pb-2">
            <GenericButton class="bg-gray-400" @click="back(true)" bg-color="bg-red">Discard</GenericButton>
            <GenericButton class="bg-olive-green" @click="showBackPopup=false">Cancel</GenericButton>
          </div>

        </div>
      </div>
    </PopupMessageDisplay>


    <PopupMessageDisplay v-if="showExitPopup">
      <div class="flex flex-row justify-center items-center">
        <div class="md:max-w-2xl bg-white flex flex-col px-4 rounded-t-md md:rounded-md pt-4 space-y-4">
          <div class="text-payne-grey text-center">
            You have not completed the assessment. To continue the assessment at a later stage, please follow the link
            on the email that was sent to you
          </div>

          <div class="flex flex-row justify-between space-x-2 pb-2">
            <GenericButton class="bg-gray-400" @click="exit(true)">Exit</GenericButton>
            <GenericButton class="bg-olive-green" @click="showExitPopup=false">Back</GenericButton>
          </div>

        </div>
      </div>
    </PopupMessageDisplay>

  </div>

  <PopupErrorMessage
      v-if="questionStore.error"
      :message="questionStore.error"
      @closed="questionStore.clearError()"
  />

  <PopupErrorMessage
      v-if="showValidationPopup"
      message="One or more questions have not been completed. Please scroll up and complete all required questions."
      @closed="showValidationPopup=false"
  />

</template>

<script setup lang="ts">

import {useQuestionStore} from "@/areas/userAssessments/stores/QuestionStore";
import {onMounted, Ref, ref, watch} from "vue";
import HeadingPrimary from "@/shared/components/HeadingPrimary.vue";
import AnswerCard from "@/areas/userAssessments/components/AnswerCard.vue";
import PopupMessageDisplay from "@/shared/components/PopupMessageDisplay.vue";
import GenericButton from "@/shared/components/GenericButton.vue";
import Card from "@/shared/components/Card.vue";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import {AnswerState} from "@/areas/userAssessments/models/enums/AnswerState";
import AnswerComplete from "@/areas/userAssessments/components/AnswerComplete.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {DomainType} from "@/areas/assessmentTypes/model/data/enums/DomainType";
import BackButton from "@/shared/components/BackButton.vue";
import AnswerWelcome from "@/areas/userAssessments/components/AnswerWelcome.vue";

const props = defineProps<{
  entityGuid: string
  assessmentDisplayObject: AssessmentDisplayObject
  assessor: AssessorDto,
  delegate: DelegateDto,
  readonly: boolean
  showHeader: boolean
}>()

const emit = defineEmits<{
  saved: []
  exit: []
}>()

const questionStore = useQuestionStore()

const showBackPopup = ref(false)

const showExitPopup = ref(false)

const comment: Ref<string> = ref(questionStore.activePage?.comments ?? '')

const validate: Ref<boolean> = ref(false)

const showValidationPopup: Ref<boolean> = ref(false)

const validQuestionComplete = ref<boolean>(false)

const validateQuestionComplete = ref<boolean>(false)

const towardComment = ref<string>('')

const awayComment = ref<string>('')

const exit = (confirmed: boolean) => {
  if (!confirmed && !props.readonly) {
    showExitPopup.value = true
    return
  }

  showExitPopup.value = false
  emit('exit')
}

const getNextHeading = () => {
  if (questionStore.state != AnswerState.Completed) {
    return "Next"
  }

  if (props.readonly) {
    return ''
  }

  return 'Finish'
}

const back = (confirmed: boolean) => {
  showBackPopup.value = false

  if (questionStore.activePage?.isUpdated && !confirmed) {
    showBackPopup.value = true
    return
  }

  questionStore.back()
}

const next = async () => {
  validate.value = false
  showValidationPopup.value = false

  switch (questionStore.state) {
    case AnswerState.NotStarted:
      await questionStore.next("")
      break
    case AnswerState.InProgress:
      if (!questionStore.isValidState(comment.value)) {
        validate.value = true;

        showValidationPopup.value = true

        return
      }

      await questionStore.next(comment.value)
      break
    case AnswerState.Completed:
      await complete()
  }
}

const validCompleteComment = (newTowardComment: string, newAwayComment: string) => {
  towardComment.value = newTowardComment

  awayComment.value = newAwayComment

  validQuestionComplete.value = true;
}

const complete = async () => {
  if (props.readonly) {
    return
  }

  validateQuestionComplete.value = false

  if (!validQuestionComplete.value) {
    validateQuestionComplete.value = true

    return
  }

  await questionStore.complete(towardComment.value, awayComment.value)

  if (!questionStore.error) {
    emit('saved')
  }
}

const getHeading = (): string => {
  switch (questionStore.state) {
    case AnswerState.NotStarted:
      return "Welcome"
    case AnswerState.Completed:
      return "Complete"
    default:
      return questionStore.activePage?.name!
  }
}

watch(() => questionStore.activePage, (newValue) => {
  if (newValue?.comments != comment.value) {
    comment.value = newValue?.comments ?? ''
  }
})

onMounted(async () => {
  await questionStore.init(props.entityGuid, props.assessmentDisplayObject, props.assessor, props.delegate)
})

</script>
