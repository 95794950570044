<template>
    <span :key="guid" class="inline-flex items-center gap-x-0.5 rounded-md bg-payne-grey px-2 py-1 text-xs font-medium text-white">
      {{ text }}
      <button type="button" @click="handleRemoveCategory(index)" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:white/20">
        <span class="sr-only">Remove</span>
        <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-white/50 group-hover:stroke-white/75">
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span class="absolute -inset-1"></span>
      </button>
    </span>
  </template>
  <script lang="ts">
  import { defineComponent } from 'vue';
  import {IDeleteRangeOrCategory} from "@/areas/demographics/model/data/demographics";
  export default defineComponent({
      name: 'Badge',
      props:{
        text: {
          type: String,
          required: true
        },
        guid: {
          type: String,
          required: true
        },
        index: {
          type: Number,
          default: 0
        },
      },
      setup(props, context){
        const handleRemoveCategory = (index: number) =>{
          const category: IDeleteRangeOrCategory = {
            index,
            targetGuid:  props.guid,
            text: props.text
          }   
          
          context.emit('categoryRemoved', category)
        }

        return {
          handleRemoveCategory
        }
      }
  })
  </script>