<template>
  <div class="w-full overflow-x-auto min-w-full align-middle space-y-4 pb-8">
    <div class="flex flex-row items-center rounded-md shadow-sm w-1/3">

      <input type="text" name="assessment-search"
             v-model="searchTerm"
             class="block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-[#AAAAAA] focus:ring-2
                                focus:ring-inset  sm:text-sm sm:leading-6" placeholder="Search"/>

      <div class="flex items-center p-2">
        <img src="/img/search.svg" class="w-6 h-6" alt="">
      </div>

    </div>

    <table class="min-w-full divide-y divide-gray-300 border border-payne-grey font-roboto text-payne">

      <thead class="bg-box-bg font-bold text-base leading-5 border-payne-grey">

      <tr>
        <th scope="col" class="min-w-[140px] py-3.5 px-3 text-left text-payne">Assessment Title</th>
        <th scope="col" class="min-w-[250px] px-3 py-3.5 text-left text-payne">Assessment Type</th>
        <th scope="col" class="min-w-[140px] px-3 py-3.5 text-left text-payne">Start</th>
        <th scope="col" class="min-w-[140px] px-3 py-3.5 text-left text-payne">End</th>
        <th scope="col" class="min-w-[140px] px-3 py-3.5 text-left text-payne">Status</th>
        <th scope="col" class="max-w-[10px] px-3 py-3.5 text-left text-payne"></th>
      </tr>

      </thead>

      <tbody class="divide-y divide-gray-200 bg-white font-normal text-sm leading-5">
      <tr v-for="assessment in filteredAssessments"
          :key="assessment?.guid">

        <td class="whitespace-nowrap py-4 px-3 text-payne">
          {{ assessment.detail.title }}
        </td>

        <td class="whitespace-nowrap px-3 py-4 text-payne">
          {{ assessment.assessment.entityAssessmentType!.assessmentType.displayName }}
        </td>

        <td class="whitespace-nowrap px-3 py-4 text-payne">
          {{ DateTime.fromISO(assessment.detail.startDate.toString()).toFormat('dd/MM/yyyy') }}
        </td>

        <td class="whitespace-nowrap px-3 py-4 text-payne">
          {{ DateTime.fromISO(assessment.detail.endDate.toString()).toFormat('dd/MM/yyyy') }}
        </td>

        <td class="whitespace-nowrap px-3 py-4 text-payne">
          <Pill
              :color="extensions.mapColorsForAssessmentState(assessment.assessment.entityState)[0]"
              class="py-1 sm:w-full xl:w-1/2">

            {{ extensions.mapColorsForAssessmentState(assessment.assessment.entityState)[1] }}
          </Pill>

        </td>

        <td class="max-w-[10px] py-3.5 text-left text-payne">
          <AssessmentListPopover
              v-show="assessment.assessment.entityState !== AssessmentState.Cancelled"
              :assessment="assessment"
              @edit="emits('edit', assessment)"
              @setStatus="value => setStatus(assessment, value)"
              @reports="emits('reports', assessment)"
              @manageDelegates="emits('manageDelegates', assessment)"
          >
          </AssessmentListPopover>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import {Ref, ref, watch} from 'vue'
import {DateTime} from 'luxon';
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import Pill from "@/shared/components/Pill.vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import AssessmentListPopover from "@/areas/assessments/components/AssessmentListPopover.vue";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";

const props = defineProps<{
  assessments: AssessmentDisplayObject[]
}>()

const emits = defineEmits<{
  edit: [AssessmentDisplayObject]
  reports: [AssessmentDisplayObject]
  manageDelegates: [AssessmentDisplayObject]
  setStatus: [AssessmentDisplayObject, AssessmentState]
}>()

const filteredAssessments = ref<AssessmentDisplayObject[]>(props.assessments)

const searchTerm: Ref<string> = ref<string>('')

const changingState = ref<[AssessmentDisplayObject, AssessmentState] | undefined>(undefined)

const setStatus = (assessment: AssessmentDisplayObject, state: AssessmentState) => {
  emits('setStatus', assessment, state)
}

const extensions = new AssessmentDOExtensions()

watch(() => searchTerm.value, (newValue, _) => {

  filteredAssessments.value = props.assessments.filter(fa =>
      fa.detail.title.toLowerCase().includes(newValue.toLowerCase()) ||
      extensions.mapColorsForAssessmentState(fa.assessment.entityState)[1].toLowerCase().includes(newValue.toLowerCase()) ||
      fa.assessment.entityAssessmentType!.assessmentType!.displayName.toLowerCase().includes(newValue.toLowerCase()))
})

</script>