<template>
  <div v-if="organisationDetailsError" class="absolute z-50 top-[30%]">
    <div class="bg-white text-payne-grey mx-auto drop-shadow-md rounded-md w-3/4">
      <div class="border-b px-6">
        <h1 class="text-lg font-roboto font-medium mb-4">Organisation Not Found</h1>
      </div>
      <div class="border-b">
        <p class="mb-6 px-6 py-6">The organisation you are looking for cannot be found. Please select the button to
          return to the previous page.</p>
      </div>
      <div class="flex flex-row justify-center px-4">
        <button @click="closeModal" class="py-2 px-4 my-4 w-1/2 bg-olive-green text-roboto text-white rounded">BACK
        </button>
      </div>
    </div>
  </div>
  <div class="flex flex-col z-20 font-roboto w-full space-y-4">
    <h1 class="uppercase text-[18px] text-payne-grey font-bold">Organisation Details & Branding</h1>
    <div class="bg-white rounded-md drop-shadow-md p-6">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-row justify-between">
          <div class="flex flex-col space-y-4 w-[45%]">
            <div>
              <p class="text-md text-payne-grey pb-5">Organisation Name</p>
            </div>
            <div class="relative">
              <input v-model='entity.displayName' :disabled="!adminPriv" type="text"
                     class="peer block w-full border-0 py-1.5 font-roboto text-payne-grey focus:ring-0"
                     placeholder="Organisation Name"/>
              <div
                  class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
                  aria-hidden="true"/>
            </div>
            <p v-show="organisationNameError !== ''" class="text-red">Organisation name is required</p>
            <div v-if="guid" class="flex flex-col space-y-4">
              <div class="text-payne-grey">
                <p class="text-md mt-6">Organisation Logo</p>
                <p class="text-xs mt-1">Upload your brand's primary logo</p>
              </div>
              <div class="flex flex-row space-x-4 justify-start">
                <img class="w-16 h-16 bg-[#dddddd]" :src="imageUrl || '/img/avatarLogo.svg'" alt="">
                <div v-if="adminPriv" class="flex flex-col justify-between">
                  <input type="file" ref="fileInput" accept="image/jpeg, image/png, image/gif" @change="onFileChange"
                         class="hidden"/>
                  <Button :disabled="!adminPriv" @click="triggerFileInput" buttonText="Upload Logo"
                          class="bg-white text-olive-green ring-inset ring-1 ring-olive-green w-2/3"/>
                  <p class="text-xs">Recommended size - 512 x 288px</p>
                </div>
                <div v-if="showImageCropper"
                     class="bg-white py-6 absolute left-[10%] top-0 z-50 drop-shadow-md rounded-sm">
                  <div class="flex flex-row border-b justify-between">
                    <p class="pl-6 pb-5 font-roboto text-payne-grey">
                      {{ `${selectedLogoFileType === 'image/gif' ? 'Upload' : 'Crop image and upload'}` }}</p>
                    <img @click="closeCropper()" src="../../../../public/img/close.svg"
                         class="w-6 mr-6 mb-5 cursor-pointer" alt="">
                  </div>

                  <vue-cropper v-if="selectedLogoFileType !== 'image/gif'"
                               :src="imageUrl"
                               ref="cropper"
                               :options="cropperOptions"
                               class="w-3/4 px-6 mt-4 bg-white"
                               style="height: 200px;"/>
                  <div v-else class="flex flex-row justify-center">
                    <img class="w-3/4 lpx-6 mt-4 bg-white" :src="imageUrl" style="height:200px" alt="preview">
                  </div>

                  <!-- Crop Button -->
                  <div class="px-6">
                    <button @click="cropImage"
                            class="mt-4 w-full bg-olive-green font-roboto font-bold text-white p-2 rounded">
                      {{ selectedLogoFileType === 'image/gif' ? 'Upload' : 'Crop and Upload' }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-4 w-[45%]">
            <div class="text-payne-grey">
              <p class="text-md">Brand Colour</p>
              <p class="text-xs mt-1">Applicable to branded email templates</p>
            </div>
            <div class="flex flex-row w-full items-center relative">
              <input :disabled="!adminPriv" v-model="entity.brandColour" type="text"
                     class="block w-full border-0 py-1.5 font-roboto text-payne-grey focus:ring-0"
                     placeholder="Brand Colour"/>
              <div
                  class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
                  aria-hidden="true"></div>
              <div @click="toggleColorPicker($event)" id="chooseColourButton"
                   :style="{ backgroundColor: entity?.brandColour }"
                   class="absolute right-2 border border-black w-6 h-6 rounded-sm drop-shadow-sm z-50"></div>
              <div v-if="showPicker" ref="colorPicker"
                   class="absolute z-50 drop-shadow-md rounded-md bg-white right-10 top-[25%] shadow-lg">
                <v-color-picker hide-inputs v-model="entity.brandColour"></v-color-picker>
              </div>
            </div>
            <p v-show="organisationBrandColourError !== ''" class="text-red">Organsation brand colour is required</p>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div></div>
          <Button
              v-if="adminPriv"
              @click="onSubmit()"
              :class="{ 'bg-light-grey cursor-not-allowed': isOrganisationNameEmpty || disabledTimer } "
              class="bg-olive-green text-white uppercase"
              buttonText="Save Changes"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, Ref, ref, watch} from 'vue';
import Button from '@/components/Atomic/AQuarks/Button.vue';
import VueCropper from 'vue-cropperjs';
import {useRoute, useRouter} from 'vue-router';
import 'cropperjs/dist/cropper.css';
import Toast from '@/Toast';
import {useEntityStore} from '@/areas/entities/store/entityStore';
import {Entity} from '@/areas/entities/model/data/dtos/entity';
import {useUserStore} from "@/areas/users/store/userStore";
import {UserRole} from "@/areas/users/model/enums/UserRole";

type OrgDetailBrandingData = {
  showPicker: boolean;
  iconImageUrl: string | null;
  selectedLogoFileType: string;
  cropperOptions: {
    aspectRatio: number;
    viewMode: number;
    crop: boolean;
  };
  disabledTimer: boolean;
};

export default defineComponent({
  name: 'OrgDetailBranding',
  components: {
    Button,
    VueCropper
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const userStore = useUserStore();

    const fileInput: Ref<HTMLInputElement | null> = ref(null);
    const iconFileInput: Ref<HTMLInputElement | null> = ref(null);
    const imageUrl: Ref<string | undefined> = ref(undefined);
    const showImageCropper: Ref<boolean> = ref(false);
    const adminPriv = ref<boolean>(false);

    const activeEntity = ref<Entity | undefined>(undefined)
    const entity = ref<Entity>({guid: '', displayName: '', brandColour: '#7D983C'} as Entity)

    let organisationNameError = ref<string>("");
    let organisationBrandColourError = ref<string>("");
    let organisationDetailsError = ref<boolean>(false);

    const guid = route.params.organisationGuid as string;

    async function fetchOrganisationDetails(entityGuid: string) {
      const entityResult = await entityStore.fetchEntity(entityGuid);

      entity.value = entityResult.content!

      if (entity.value.entityAssets != undefined && entity.value.entityAssets.length > 0) {
        imageUrl.value = entity.value.entityAssets[0].signedUrl!;
      } else {
        imageUrl.value = '/img/avatarLogo.svg';
      }
    }

    const checkRoles = async () => {
      var state = await userStore.getState()

      activeEntity.value = state.activeEntity

      switch (state.role) {
        case UserRole.SuperAdmin:
          adminPriv.value = true
          break

        case UserRole.OrganisationAdmin:
          adminPriv.value = false
          break

        case UserRole.User:
          await router.push(`/dashboard/assessments/${state.activeEntity.guid}`)
      }
    }

    const load = async () => {
      await checkRoles()

      if (guid) {
        await fetchOrganisationDetails(guid)
      } else {
        localStorage.removeItem('demographics')
      }
    }

    onMounted(async () => {
      await load()
    });

    const isOrganisationNameEmpty = computed(() => entity.value?.displayName === '');

    return {
      adminPriv,
      fileInput,
      iconFileInput,
      entityStore,
      activeEntity,
      entity,
      organisationNameError,
      organisationBrandColourError,
      isOrganisationNameEmpty,
      organisationDetailsError,
      guid,
      imageUrl,
      showImageCropper,

      userStore
    }
  },

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    async onSubmit() {
      const SaveOrgToast = Toast

      const org = this.entity

      const activeEntity = this.activeEntity

      let orgName = org?.displayName?.trim();

      if (!orgName) {
        this.organisationNameError = "Organisation name is required";
        return;
      } else {
        this.organisationNameError = "";
        org.displayName = orgName; // Update the organization name with the trimmed value
      }

      if (!org?.displayName) {
        this.organisationNameError = "Organisation name is required";
        return;
      } else {
        this.organisationNameError = "";
      }
      if (!org.brandColour) {
        this.organisationBrandColourError = "Organisation brand colour is required";
        return;
      } else {
        this.organisationBrandColourError = "";
      }

      this.disabledTimer = true


      try {
        if (org.guid) {
          let organisationUpdateResult = await this.entityStore.updateEntity(org as Entity)

          if (!organisationUpdateResult.isSuccessful) {
            SaveOrgToast.error('Something went wrong while updating the organisation')
            return
          }

          SaveOrgToast.success('Organisation successfully updated')
          return
        } else {

          const entityCreateResult = await this.entityStore.createEntity(activeEntity!.guid!, this.entity);

          if (!entityCreateResult.isSuccessful) {
            // handle error
            SaveOrgToast.error('Organisation name already exists')
            return
          }

          await this.userStore.setActiveEntity(entityCreateResult.content!)

          //only reload the page on organisation creation to get the default demographics
          window.location.href = `/dashboard/organisation/${entityCreateResult.content!.guid}`
        }
      } finally {
        await new Promise(resolve => setTimeout(resolve, 300));
        this.disabledTimer = false
      }
    },
    triggerFileInput() {
      if (this.fileInput) {
        this.fileInput.value = '';

        this.fileInput!.click();
      }
    },
    closeModal() {
      this.organisationDetailsError = false;
      this.$router.push('/');
    },
    //On logo select
    onFileChange(e: Event) {
      const target = e.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;
      if (file) {
        this.selectedLogoFileType = file.type;
        if (this.selectedLogoFileType === 'image/gif') {
          // disable cropping for gif
          this.cropperOptions.viewMode = 0
          this.cropperOptions.crop = false
        } else {
          this.cropperOptions.viewMode = 1
          this.cropperOptions.crop = true
        }
        const reader = new FileReader();
        reader.onload = (event) => {
          this.showImageCropper = true;
          this.imageUrl = event.target?.result as string;
        };
        reader.readAsDataURL(file);
      } else {
        this.selectedLogoFileType = '';
      }
    },
    //On logo crop
    cropImage() {
      if (this.selectedLogoFileType === 'image/gif') {
        this.uploadLogo(this.fileInput!.files![0] as Blob)
        return
      }
      if (this.$refs.cropper) {
        const cropper = this.$refs.cropper as any;

        cropper.getCroppedCanvas().toBlob((blob: Blob | null) => {
          if (blob) {
            this.uploadLogo(blob);
          }
        });
      }
    },
    async uploadLogo(blob: Blob) {
      const logoUploadResult = await this.entityStore.uploadLogo(blob, this.selectedLogoFileType, this.guid);

      if (logoUploadResult.isSuccessful) {
        Toast.success("Logo has been uploaded")
        this.closeCropper();
      }
      if (!logoUploadResult.isSuccessful) {
        Toast.error(`Failed to upload the logo: ${logoUploadResult.error!}`)
        this.closeCropper();
      }
    },
    //Close logo cropper
    closeCropper() {
      this.showImageCropper = false;
    },
    //Hidden icon input (later functionality)
    triggerIconFileInput() {
      if (this.iconFileInput) {
        this.iconFileInput.value = '';
        this.iconFileInput.click();
      }
    },
    //Hidden functionality for icon (later functionality)
    onIconFileChange(e: Event) {
      const target = e.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.iconImageUrl = event.target?.result as string;
        };
        reader.readAsDataURL(file);
      }
    },
    //Hidden functionality for icon (later functionality)
    cropIconImage() {
      if (this.$refs.cropper) {
        const cropper = this.$refs.cropper as any;
        const croppedImage = cropper.getCroppedCanvas().toDataURL();
        // Send org logo to API
        this.closeIconCropper();
      }
    },
    //Hidden functionality for icon (later functionality)
    closeIconCropper() {
      this.iconImageUrl = null;
    },
    handleClickOutside(event: MouseEvent) {
      const colorPickerElement = this.$refs.colorPicker as HTMLElement;
      const target = event.target as HTMLElement;

      if (colorPickerElement && target && !colorPickerElement.contains(target)) {
        this.showPicker = false;
      }
    },
    toggleColorPicker(event: MouseEvent) {
      if (this.adminPriv) {
        event.stopPropagation();
        this.showPicker = !this.showPicker;
        if (this.showPicker) {
          document.addEventListener('click', this.handleClickOutside);
        } else {
          document.removeEventListener('click', this.handleClickOutside);
        }
      }
    },
  },
  data(): OrgDetailBrandingData {
    return {
      showPicker: false,
      iconImageUrl: null,
      selectedLogoFileType: '',
      cropperOptions: {
        aspectRatio: 16 / 9,
        viewMode: 0,
        crop: false,
      },
      disabledTimer: false,
    };
  },
});

</script>

