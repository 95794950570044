<template>
  <div class="w-full mx-auto sm:max-w-2xl min-h-full">
    <NavHeading
        v-if="!addAssessor && !assessorStore.assessorResults"
        :heading="`Manage Assessors - ${delegate.displayName}`"
        @back="emits('back')"
        class="py-4">

      <div class="flex flex-col items-end">
        <AddButton
            :disabled="[AssessmentState.Completed, AssessmentState.Cancelled].includes(assessment.entityState)"
            @add="addAssessor = true"/>
      </div>
    </NavHeading>

    <div v-if="!assessorStore.isLoading && !assessorStore.assessorResults">
      <AssessorList
          heading="My Assessors"
          :require-relationship="delegate.delegateType == DelegateType.Individual"
          :entity-guid="entityGuid"
          :addAssessor="addAssessor"
          :delegate="delegate"
          @back="addAssessor = false"
          @update="updateAssessors"
          @viewResults="viewResults"
      />

      <div class="pb-8"></div>

      <SaveButton
          v-if="!addAssessor"
          @save="save()"
          text="Update"
          :is-loading="false"
          :disabled="!updated"
      />
    </div>

    <div v-if="!assessorStore.isLoading && assessorStore.assessorResults">
      <AssessorViewAnswers
          :delegate="assessorStore.delegateDisplayItem?.delegateDto!"
          :assessor="assessorStore.assessorResults.assessor!"
          :assessment-display-object="assessorStore.assessmentDo!"
          :entity-guid="assessorStore.entityGuid!"
          @back="assessorStore.clearResults()"
      />
    </div>

    <LoadingCard :is-loading="assessorStore.isLoading" class="pt-2"/>

    <PopupErrorMessage
        v-if="assessorStore.error"
        :message="assessorStore.error"
        @closed="assessorStore.clearError()"
    />
  </div>
</template>

<script setup lang="ts">

import {useAssessorStore} from "@/areas/assessors/store/AssessorStore";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import {onMounted, ref} from "vue";
import NavHeading from "@/shared/components/NavHeading.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import AssessorList from "@/areas/assessors/components/AssessorList.vue";
import SaveButton from "@/shared/components/SaveButton.vue";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import AddButton from "@/shared/components/AddButton.vue";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import AssessorViewAnswers from "@/areas/assessors/components/AssessorViewAnswers.vue";

const assessorStore = useAssessorStore();

const props = defineProps<{
  delegate: DelegateDisplayItem
  assessment: AssessmentDto
  draftAssessors: AssessorDisplayObject[]
  entityGuid: string
  forceValidation: boolean
}>()

const emits = defineEmits<{
  save: [AssessorDisplayObject[]]
  back: []
}>()

const addAssessor = ref<boolean>(false)

const updated = ref<boolean>(false)

const updateAssessors = () => {
  addAssessor.value = false

  updated.value = true
}

const viewResults = (assessor: AssessorDisplayObject) => {
  assessorStore.viewResults(assessor)
}

const save = () => {
  assessorStore.update(props.forceValidation)

  if (assessorStore.error) {
    return
  }

  emits('save', assessorStore.assessorDisplayObjects)
}

onMounted(() => {
  const assessmentExtensions = new AssessmentDOExtensions()

  assessorStore.init(props.entityGuid, assessmentExtensions.castToAssessmentDisplayObject(props.assessment), props.draftAssessors, props.delegate)
})

</script>