<template>
  <AppLayout>
    <NewEmployee />
  </AppLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppLayout from '@/components/Layouts/AppLayout.vue';
import NewEmployee from '@/components/Atomic/CMolecules/NewEmployee.vue';

export default defineComponent({
  name: 'EmployeesView',
  components: {
    AppLayout,
    NewEmployee
  },
})
</script>

<style>

</style>