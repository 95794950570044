<template>
  <section id="demographics" class="text-payne-grey w-full" v-if="!organisationCreated">
    <div class="flex flex-col justify-between pb-5">
      <div class="flex flex-row justify-between items-center">
        <div>
          <p class="uppercase font-medium leading-{21px} font-roboto text-lg tracking-{0.25px}">organisation
            demographics</p>
          <p class="font-normal leading-{21px} font-roboto text-sm tracking-{0.25px}">
            This is a list of the {{ organisation?.name ? organisation.name : "organisation's" }} pre-defined
            demographics. You may configure them if needed.
          </p>
        </div>
        <div>
          <Modal modal-description="This demographic will be set to the organisation's requirements">
            <template v-slot:modal_activator>
              <Button class="bg-olive-green text-white uppercase" buttonText="Add Demographic" customStyle="w-6 h-6"
                      imageSrc="/img/plus.svg"/>
            </template>
            <v-row>
              <DemographicForm/>
            </v-row>
          </Modal>
        </div>
      </div>
      <div class="w-full mt-6">
        <DemographicsTable/>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import DemographicsTable from '@/components/Atomic/AQuarks/DemographicsTable.vue'
import Modal from '@/components/Atomic/AQuarks/Modal.vue';
import Button from '@/components/Atomic/AQuarks/Button.vue';
import DemographicForm from '@/components/Atomic/BAtoms/Demographics/DemographicForm.vue';

export default defineComponent({
  name: 'OrganisationDemographicsView',
  components: {
    DemographicsTable,
    Modal,
    DemographicForm,
    Button
  }
})
</script>