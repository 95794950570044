import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4 w-full" }
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = { class: "font-roboto font-bold text-payne-grey text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_NavTabs = _resolveComponent("NavTabs")!
  const _component_DeleteModal = _resolveComponent("DeleteModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.displayText), 1),
      (_ctx.adminPriv && _ctx.existingOrganisation && _ctx.tab === 'ORGANISATION' && _ctx.activeEntity?.entityType !== _ctx.EntityTypeEnum.Root )
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            onClick: _ctx.deleteOrganisation,
            imageSrc: "/img/delete.svg",
            buttonText: "Delete Organisation",
            class: "uppercase rounded-md drop-shadow-sm text-red ring-inset ring-1 bg-white ring-red"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NavTabs, { tabs: _ctx.tabs }, null, 8, ["tabs"]),
    (_ctx.showDeleteOrganisationModal)
      ? (_openBlock(), _createBlock(_component_DeleteModal, {
          key: 0,
          class: "absolute"
        }))
      : _createCommentVNode("", true)
  ]))
}