<template>
  <Card bg-color="bg-white"
        :class="selected ? '' : 'hover:cursor-pointer'"
        class="py-2 space-y-2"
        @click="clicked"
  >
    <div class="flex flex-row w-full">
      <div v-if="selectable" class="flex flex-col justify-center items-center">
        <v-checkbox-btn
            color="olive-green"
            v-model="selected"
            :disabled="disabled"
        />
      </div>

      <div class="flex flex-col w-full">
        <EmployeeInfo :employee="employee" :relationship="assessorRelationship"></EmployeeInfo>

        <slot></slot>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/components/Card.vue";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import EmployeeInfo from "@/areas/employees/components/EmployeeInfo.vue";
import {onMounted, ref, watch} from "vue";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";

const props = defineProps<{
  assessorRelationship?: AssessorRelationshipType
  employee: EmployeeDto
  isSelected: boolean
  selectable: boolean
  disabled: boolean
}>()

const emit = defineEmits<{
  selected: []
  deselected: []
}>()

const selected = ref<boolean>(false)

const clicked = () => {
  if (!props.selectable) {
    emit('selected')
  }
}

watch(() => selected.value, (newValue) => {
  if (newValue) {
    emit('selected')
  } else {
    emit('deselected')
  }
})

watch(() => props.isSelected, (newValue) => {
  selected.value = newValue
})

onMounted(() => {
  selected.value = props.isSelected
})

</script>