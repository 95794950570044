import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "8" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "max-width" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_pagination, {
                      modelValue: $data.page,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.page) = $event)),
                      length: $props.totalRows,
                      "active-color": "olive-green",
                      color: "#000000",
                      "show-first-last-page": true,
                      "total-visible": "10",
                      "update:modelValue": _ctx.$emit('pageChanged', $data.page-1)
                    }, null, 8, ["modelValue", "length", "update:modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}