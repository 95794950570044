<template>
  <div class="flex flex-col space-y-4">
    <Card class="text-payne-grey text-sm-body-1">
      <div class="py-2">
        In a workspace relationship, certain behaviours from one person or team activate a threat response and put the
        other person or team in an <span class="text-red">AWAY STATE (leaves them feeling frustrated, disengaged and switched off)</span>.
        Conversely,
        other behaviours invoke a reward response placing the other person or team in a
        <span class="text-green-500">TOWARD STATE (leaves them feeling energised, engaged and switched on).</span>
      </div>
    </Card>

    <Card class="space-y-4">
      <div>
        {{ parseText(getStatement(props.domain.questions[0]).head!) }}
      </div>

      <div>
        <div class="border-2 rounded-md">
        <textarea
            v-model="towardComment"
            class="w-full h-32 p-2 resize-none overflow-auto focus:outline-none"
            placeholder="Your answer"
            :disabled="readonly"
        />
        </div>

        <div v-if="!validToward" class="text-red">
          This is a required field. Please ensure that you at least provide an answer with a minimum of 10 characters.
        </div>
      </div>
    </Card>

    <Card class="space-y-4">
      <div>
        {{ parseText(getStatement(props.domain.questions[1]).head!) }}
      </div>

      <div>
        <div class="border-2 rounded-md">
        <textarea
            v-model="awayComment"
            class="w-full h-32 p-2 resize-none overflow-auto focus:outline-none"
            placeholder="Your answer"
            :disabled="readonly"
        />
        </div>
        <div v-if="!validAway" class="text-red">
          This is a required field. Please ensure that you at least provide an answer with a minimum of 10 characters.
        </div>
      </div>
    </Card>

    <Card class="space-y-4">
      <div class="pt-2">
        Thank you for taking the time to provide your input. If you are comfortable with the ratings and input that you
        have provided, please select FINISH.
      </div>

      <div class="pb-2">
        You may click BACK below to review your input, however once you click FINISH you will not be able to change your
        answers.
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">

import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {onMounted, ref, watch} from "vue";
import Card from "@/shared/components/Card.vue";
import {CommentResultDto} from "@/areas/userAssessments/models/dtos/CommentResultDto";
import {CommentResultType} from "@/areas/userAssessments/models/enums/CommentResultType";
import {DomainDo} from "@/areas/userAssessments/models/dos/DomainDo";
import {StatementDto} from "@/areas/assessmentTypes/model/data/dto/StatementDto";
import keycloak from "@/keycloak";
import {DelegateDo} from "@/areas/delegates/model/dos/DelegateDo";
import {QuestionDo} from "@/areas/userAssessments/models/dos/QuestionDo";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {UserAssessmentExtensions} from "@/areas/userAssessments/extensions/UserAssessmentExtensions";
import {useUserStore} from "@/areas/users/store/userStore";
import {User} from "@/areas/users/model/data/User";

const props = defineProps<{
  domain: DomainDo
  validate: boolean
  delegate: DelegateDto
  readonly: boolean
  comments: CommentResultDto[]
  individual?: User
}>()

const emits = defineEmits<{
  valid: [string, string]
  invalid: []
}>()

const userStore = useUserStore();

const validAway = ref<boolean>(true)

const validToward = ref<boolean>(true)

const towardComment = ref<string>('')

const awayComment = ref<string>('')

const validateInput = () => {
  validToward.value = (!props.validate && towardComment.value.length == 0) || towardComment.value.length > 10;

  validAway.value = (!props.validate && awayComment.value.length == 0) || awayComment.value.length > 10;
}

const checkEmit = () => {
  if (validToward.value && validAway.value) {
    emits('valid', towardComment.value, awayComment.value)
  } else {
    emits('invalid')
  }
}

const getStatement = (question: QuestionDo): StatementDto => {
  const loggedInUser = keycloak.tokenParsed;

  if (props.delegate.delegateType == DelegateType.Individual && props.delegate.individual?.guid == loggedInUser!.sub) {
    return question.first!
  } else {
    return question.third!
  }
}

watch(() => towardComment.value, () => {
  validateInput()

  checkEmit()
})

watch(
    () => props.validate,
    () => validateInput())

watch(() => awayComment.value, () => {
  validateInput()

  checkEmit()
})

onMounted(() => {
  if (props.validate) {
    validateInput()
  }

  awayComment.value = props.comments.find(c => c.commentResultType == CommentResultType.Away)?.comment ?? ''
  towardComment.value = props.comments.find(c => c.commentResultType == CommentResultType.Toward)?.comment ?? ''

  if (towardComment.value.length > 10 && awayComment.value.length > 10) {
    emits('valid', towardComment.value, awayComment.value)
  } else {
    emits('invalid')
  }
})

const parseText = (someString: string): string => {
  return UserAssessmentExtensions.parseText(someString, props.delegate!, userStore.state?.activeEntity!, props.individual)
}

</script>