<template>
  <div class="flex flex-col">
    <div v-if="label" :class="`pb-3 font-semibold ${error ? 'text-red': 'text-payne-grey'}`">
      {{ label }}
    </div>

    <div :class="`${disabled ? 'hover:cursor-not-allowed': ''}`" >
      <VueDatePicker
          v-model="date"
          :enableTimePicker="false"
          :min-date="minDate"
          :max-date="maxDate"
          :disabled="disabled"
          format="dd/MM/yyyy"
      ></VueDatePicker>
    </div>

    <div :class="`border-t peer-focus:border-t-2 peer-focus:border-payne-grey
            ${error  ? 'border-red' : 'border-payne-grey'}`"/>

    <div v-if="error" class="text-red text-xs">
      Please complete this field
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps<{
  error?: string
  label: string
  minDate?: Date
  maxDate?: Date
  date?: Date
  disabled: boolean
}>()

const emits = defineEmits<{
  updatedDate: [Date?]
}>()

const date = ref<Date | undefined>(props.date);

const parentUpdated = ref<boolean>(false)

watch(() => props.date, () => {
  parentUpdated.value = true
  date.value = props.date
})

watch(() => date.value, (newValue) => {
  if (!parentUpdated.value) {
    emits('updatedDate', newValue)
  }

  parentUpdated.value = false
})
</script>

