import {defineStore} from 'pinia'
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {UserAssessmentDto} from "@/areas/assessments/model/dtos/UserAssessmentDto";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";

interface UserAssessmentState {
    assessmentProxy: AssessmentProxy

    userAssessments: UserAssessmentDto[]

    assessorProxy: AssessorProxy

    entityGuid: string | undefined

    error?: string | undefined

    isLoading: boolean
}

function emptyState(): UserAssessmentState {
    return {
        assessmentProxy: new AssessmentProxy(),

        assessorProxy: new AssessorProxy(),

        userAssessments: [],

        entityGuid: undefined,

        error: undefined,

        isLoading: true
    }
}

export const useUserAssessmentStore = defineStore({
    id: "userAssessmentStore",
    state: (): UserAssessmentState => emptyState(),
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async init(entityGuid: string): Promise<void> {
            await this.withLoading(async () => {
                this.$state = emptyState()

                const assessmentStates =
                    [AssessmentState.Active]

                const assessorStates = [
                    AssessorState.Invited,
                    AssessorState.Accepted,
                    AssessorState.InProgress]

                const assessmentResult = await this.assessmentProxy.listByUser(entityGuid, assessmentStates, assessorStates)

                if (!assessmentResult.isSuccessful) {
                    this.error = "Could not retrieve the assessments for the user"
                    return
                }

                const userAssessmentDtos = assessmentResult.content!

                this.entityGuid = entityGuid
                this.userAssessments = userAssessmentDtos
            })
        },

        async transition(userAssessment: UserAssessmentDto, assessorState: AssessorState) {
            await this.withLoading(async () => {
                const result = await this.assessorProxy.updateState(
                    this.entityGuid!,
                    userAssessment.assessment.guid!,
                    userAssessment.delegate.guid!,
                    userAssessment.assessor.guid!,
                    assessorState)

                if (!result.isSuccessful) {
                    this.error = "We could not update as requested at this time. Please refresh and try again"
                    return
                }

                await this.init(this.entityGuid!)
            })
        },

        clearError() {
            this.error = undefined
        },
    }

});