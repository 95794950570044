import {defineStore} from 'pinia'
import {EntityUserProxy} from "@/areas/entities/proxy/entityUserProxy";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {EmployeeSearchDto} from '../model/data/dtos/employeeSearchDto';
import {EmployeeCreateDto} from '../model/data/dtos/employeeCreateDto';
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";

interface IState {
    updated_at: Date;
    proxy: EntityUserProxy;
    entityDto: Entity | null;
}

export const useEntityUserStore = defineStore({
    id: "entityUser",
    state: (): IState => ({
        updated_at: new Date(),
        proxy: new EntityUserProxy(),
        entityDto: null,
    }),
    actions : {
        async upsertEmployee(entityGuid: string, employeeCreateDto: EmployeeCreateDto) {
            return await this.proxy.create(entityGuid, employeeCreateDto);
        },

        async fetchEntityUser(entityGuid: string, userGuid: string) {
            return await this.proxy.getUser(entityGuid, userGuid);
        },

        async fetchEmployeeByEmail(entityGuid: string, userEmail: string) {
            return await this.proxy.getUserByEmail(entityGuid, userEmail);
        },

        async listUsers(entityGuid: string, employeeSearchDto: EmployeeSearchDto): Promise<OperationResult<EmployeeSearchDto>> {
            return await this.proxy.listUsers(entityGuid, employeeSearchDto);
        },

        async delete(entityGuid: string, userGuid: string) {
            return await this.proxy.delete(entityGuid, userGuid);
        },

        async getEmployeeTemplateFile(entityGuid: string) {
            return await this.proxy.getEmployeeTemplateFile(entityGuid);
        },

        async uploadFile(entityGuid: string, fileInput: File) {
            return await this.proxy.uploadFile(entityGuid, fileInput);
        }
    }
});  