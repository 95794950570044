<template>
  <div class="w-full font-roboto text-payne-grey pl-4">
    <div class="text-base flex flex-row items-center justify-center space-x-2 leading-5">
      <p class="font-medium">Demographic: </p>
      <p class="font-medium">{{ enabledRef ? 'Enabled' : 'Disabled' }}</p>
      <v-switch class="pt-5 pl-2" color="dark-blue" :model-value="enabledRef"
                @update:modelValue="enabledRef = !enabledRef">
      </v-switch>
    </div>
    <div>
      <p class="text-sm text-payne-grey pb-3 font-normal">Demographic Title</p>
    </div>
    <div class="relative">
      <input type="text"
             class="peer block w-full border-0 py-1.5 pl-2 font-roboto text-payne-grey focus:ring-0 text-sm"
             :disabled="notEditable"
             v-model="demoTitleRef" placeholder="Enter title"/>
      <div
          class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
          aria-hidden="true"/>
    </div>
    <div :class="`${demoTypeRef === '' && 'pb-10'}`">
      <div>
        <p class="text-sm text-payne-grey pb-3 pt-8 font-normal">Demographic Type</p>
      </div>
      <div class="relative h-10 w-full min-w-[200px] text-sm">
        <select v-model="demoTypeRef" :disabled="!demographic"
                class="peer h-full w-full rounded-[7px]  py-2.5 pl-2 font-roboto text-sm font-normal text-payne-grey focus:ring-0 disabled:bg-light-grey">
          <option selected value="">Choose Demographic Type</option>
          <option value="NumberedRange">Numbered Range</option>
          <option value="StaticCategory">Multiple Static Categories</option>
        </select>
        <div
            class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
            aria-hidden="true"/>
      </div>
    </div>
    <div v-if="demoTypeRef === 'NumberedRange'" class="pt-8">
      <RangeSlider :ranges="combinedRanges" @slider-value-change="updateIsOverlapping"
                   @range-title-updated="updateRangeTitle" @range-removed="deleteRange"/>
    </div>
    <div v-if="demoTypeRef === 'StaticCategory'" class="pt-8">
      <div class="relative">
        <div>
          <p class="text-sm text-payne-grey pb-3 font-normal">* Available Categories</p>
        </div>
        <input type="text"
               class="text-sm peer block w-full border-0 py-1.5 pl-2 font-roboto text-payne-grey focus:ring-0"
               v-model="newBadgeTextRef" @keyup.enter="onAddCategories"
               placeholder="Starting typing values here, separate them by pressing enter or using a comma"/>
        <div
            class="absolute inset-x-0 bottom-0 border-t border-payne-grey peer-focus:border-t-2 peer-focus:border-payne-grey"
            aria-hidden="true"/>
      </div>
      <div class="mt-2">
        <Badge v-for="({ title, guid = '' }, index) in combinedCategories"
               @category-removed="() => deleteCategory(index)"
               :key="guid" :text="title" :guid="guid" class="mr-2 mt-1" :index="index"/>
      </div>
    </div>

    <Banner v-show="rangeOverlapping" class="mt-3"></Banner>

    <div class="flex justify-end float-right pt-6">
      <button type="button" v-show="demoTypeRef === 'NumberedRange'" @click="addDemographicRange"
              class="mr-3 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-olive-green shadow-sm ring-1 ring-inset ring-olive-green hover:bg-gray-50">
        <img src="/img/plus_green.svg" alt="plus icon" class="w-6 h-6 d-inline">
        ADD ANOTHER RANGE
      </button>
      <button type="button" @click="executeModalAction" :disabled="!formIsValid()" class="w-[104px] h-[40px]"
              :class="`${!formIsValid() && 'opacity-50 hover:cursor-not-allowed'} rounded-md bg-olive-green px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-olive-green/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:olive-green`">
        DONE
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue'
import {storeToRefs} from 'pinia';
import bus from "@/bus"
//@ts-ignore
import {nth, pullAllBy} from 'lodash'
import RangeSlider from './RangeSlider.vue'
import Badge from '../../AQuarks/Badge.vue'
import Banner from '../../AQuarks/Banner.vue'
import Tooltip from '../../AQuarks/Tooltip.vue'
import {hasOverlap} from '@/components/utils/utils';
import Toast from '@/Toast';
import {useRoute, useRouter} from 'vue-router';
import {UserRole} from "@/areas/users/model/enums/UserRole";
import {Entity} from '@/areas/entities/model/data/dtos/entity';
import {useUserStore} from "@/areas/users/store/userStore";
import {
  IAddDemographic,
  IDeleteRangeOrCategory,
  IDemographicCategory,
  IDemographicFormProps,
  IDemographicRange, DemographicType,
  IRange, IUpdateRange,
  IAddDefaultDemographic, IDefaultDemographicToAdd,
  DemographicVersions, Payload,
  DemographicCategory, IUpdateDefaultDemographic
} from '@/areas/demographics/model/data/demographics';
import {useDemographicsStore} from "@/areas/demographics/store/demographicStore";
import {Demographic} from "@/areas/demographics/model/data/dtos/demographic";
import {PropType} from 'vue';
import {DemographicTypeEnum} from "@/areas/demographics/model/enums/demographicTypeEnum"

export default defineComponent({
  components: {
    RangeSlider,
    Badge,
    Banner,
    Tooltip
  },
  props: {
    selectedDemographic: {
      type: Object as PropType<Demographic>,
      default: () => ({})
    },
    existingCategories: {
      type: Array
    },
    existingRanges: {
      type: Array
    },
    index: {
      type: Number,
      default: -1
    }
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const demographicStore = useDemographicsStore()
    const activeEntity = ref<Entity | undefined>(undefined);
    const adminPriv = ref<boolean>(false);
    const demographic = ref<Demographic | undefined>(props.selectedDemographic);

    const checkRoles = async () => {
      var state = await userStore.getState();

      activeEntity.value = state.activeEntity

      switch (state.role) {
        case UserRole.SuperAdmin:
          adminPriv.value = true
          break

        case UserRole.OrganisationAdmin:
          adminPriv.value = false
          break

        case UserRole.User:
          await router.push(`/dashboard/assessments/${state.activeEntity.guid}`)
      }
    }

    const rangeMapper = (ranges: IDemographicRange[]): IRange[] => {
      return ranges?.map(({guid, title, value}) => {
        return {
          title,
          guid,
          isOverLapping: false,
          value: Array.isArray(value) ? [...value] : value !== undefined ? [value] : []
        }
      })
    }

    const existingCategories = ref(demographic?.value?.values as IDemographicCategory[] || [])
    const existingRanges = ref<IRange[]>(rangeMapper(demographic?.value?.values as IDemographicRange[]) || [])

    const newRanges = ref<IRange[]>(
        existingRanges.value.length === 0 ?
            [{
              title: 'Range 1',
              isOverLapping: false,
              value: [18, 30]
            }] : []
    )

    function rangesHaveChanged(): boolean {
      const currentRanges = [...existingRanges.value, ...newRanges.value];
      if (currentRanges.length !== initialRanges.value.length) {
        return true;
      }

      for (let i = 0; i < currentRanges.length; i++) {
        const currentRange = currentRanges[i];
        const initialRange = initialRanges.value[i];

        if (currentRange.title !== initialRange.title ||
            currentRange.value[0] !== initialRange.value[0] ||
            currentRange.value[1] !== initialRange.value[1]) {
          return true;
        }
      }

      return false;
    }

    let initialRanges = ref<any>([]);
    const combinedRanges = computed(() => [...existingRanges.value, ...newRanges.value])
    const newCategories = ref<IDemographicCategory[]>([])
    const combinedCategories = ref<IDemographicCategory[]>([...existingCategories.value, ...newCategories.value])
    const demoTitleRef = ref<string>(demographic?.value?.demographicTitle || '')
    const enabledRef = ref<boolean | undefined>(demographic?.value?.demographicEnabled)
    const rangeOverlapping = ref<boolean>(false)
    const newBadgeTextRef = ref<string>('')
    const demoTypeRef = ref(demographic?.value?.demographicType || '');
    const guidsToRemove = ref<string[]>([])
    const notEditable = ['Age', 'Race', 'Gender'].includes(demographic?.value?.demographicTitle as string);

    onMounted(async () => {
      await checkRoles()
      initialRanges.value = JSON.parse(JSON.stringify([...existingRanges.value, ...newRanges.value]));
    })
    return {
      newRanges,
      enabledRef,
      notEditable,
      demographic,
      demoTypeRef,
      activeEntity,
      demoTitleRef,
      newCategories,
      guidsToRemove,
      combinedRanges,
      rangesHaveChanged,
      existingRanges,
      newBadgeTextRef,
      demographicStore,
      rangeOverlapping,
      combinedCategories,
    }
  },
  methods: {
    deleteRange({targetGuid, ranges = [], index}: IDeleteRangeOrCategory) {
      if (index === undefined) {
        console.error('deleteRange called without a valid index.');
        return;
      }

      let rangeToRemove;

      if (index < this.existingRanges.length) {
        rangeToRemove = this.existingRanges.splice(index, 1)[0];
      } else {
        const newIndex = index - this.existingRanges.length;
        rangeToRemove = this.newRanges.splice(newIndex, 1)[0];
      }

      //@ts-ignore
      if (rangeToRemove && rangeToRemove.title) {
        //@ts-ignore
        this.guidsToRemove.push(rangeToRemove.title);
      }

      const isOverLapping = hasOverlap(ranges);
      if (!isOverLapping) {
        this.updateIsOverlapping({
          existingRanges: ranges,
          index: -1,
          isOverLapping
        })
      }
    },
    deleteCategory(index: number) {
      if (this.combinedCategories[index].title) {
        this.guidsToRemove.push(this.combinedCategories[index].title as string);
      }
      this.combinedCategories.splice(index, 1);
    },
    formIsValid(): boolean {
      if (this.demoTypeRef.trim() === '' || this.demoTitleRef.trim() === '') {
        return false;
      }

      let hasChanges = false;
      if (this.demographic?.guid) {
        hasChanges = this.demoTitleRef !== this.demographic?.demographicTitle ||
            this.demoTypeRef !== this.demographic?.demographicType ||
            this.enabledRef !== this.demographic?.demographicEnabled
      } else {
        hasChanges = true;
      }

      let hasRangeTitleChanged = false;
      if (this.demographic?.guid && this.demographic?.values) {
        hasRangeTitleChanged = this.combinedRanges.some((range, index) => {
          return range.title !== this.demographic!.values![index]?.title;
        });
      }

      const categoriesOrRangesChanged = this.newCategories.length > 0 || this.newRanges.length > 0 || this.guidsToRemove.length > 0;

      return hasChanges || categoriesOrRangesChanged || this.rangesHaveChanged() || hasRangeTitleChanged;
    },
    addDemographicRange() {
      const newRange: IRange = {
        title: '',
        isOverLapping: false,
        value: [18, 30]
      }
      const rangesHaveOverlap = hasOverlap([...this.combinedRanges, newRange])
      this.newRanges.push({
        ...newRange,
        title: `Range ${this.combinedRanges.length + 1}`,
        isOverLapping: this.combinedRanges.length > 1 && rangesHaveOverlap
      })
      this.rangeOverlapping = rangesHaveOverlap
    },
    updateRangeTitle({newTitle, index}: { newTitle: string, index: number }) {
      let ranges = [...this.combinedRanges];
      if (ranges[index]) {
        ranges[index] = {...ranges[index], title: newTitle};
        this.combinedRanges = ranges;
      }
    },
    updateIsOverlapping({isOverLapping, existingRanges, index}: IUpdateRange) {
      if (isOverLapping) {
        const range: IRange | undefined = nth(existingRanges, index);
        if (range) {
          this.rangeOverlapping = true
          range.isOverLapping = isOverLapping;
        }
      } else {
        this.rangeOverlapping = false;
        [...this.newRanges, ...existingRanges].forEach(range => range.isOverLapping = false)
      }
    },
    onAddCategories() {
      const categories = this.newBadgeTextRef.trim()
      const seenCategories = new Set(
          this.combinedCategories?.map(
              (category) => category.title.toLowerCase()
          ));
      categories.split(',').forEach(category => {
        const regex = /^[ \t]+$/
        if (category && !regex.test(category) && !seenCategories.has(category.trim().toLowerCase())) {
          seenCategories.add(category.trim().toLowerCase());
          this.newCategories.push({title: category.trim()});
          this.combinedCategories.push({title: category.trim()});
        }
      });
      this.newBadgeTextRef = '';
    },
    async executeModalAction() {
      this.onAddCategories()
      const demographics = await this.demographicStore.list(this.activeEntity!.guid as string)
      let toggleNeeded = this.enabledRef !== this.demographic?.isEnabled
      const existDemographics = new Set(
          demographics!.content!.map(({displayName}) => displayName.toLowerCase())
      )
      const toastOptions: any = {
        duration: 5000,
        position: 'top-right',
        dismissible: true
      }
      if (!this.demographic?.guid && existDemographics.has(this.demoTitleRef.trim().toLowerCase())) {
        Toast.error(`Cannot Add a new demographic "${this.demoTitleRef}" as it already exists.`, toastOptions);
        return;
      }
      if (this.demographic?.guid) {
        // This is an update operation, check if the updated title collides with other demographics
        const updatedDemographic = demographics.content?.find(({guid, displayName}) => {
          return (
              guid !== this.demographic?.guid &&
              displayName.toLowerCase() === this.demoTitleRef.trim().toLowerCase()
          );
        });
        if (updatedDemographic) {
          // Title collision during an update
          Toast.error(`Cannot update the title to "${this.demoTitleRef}" as a demographic with this title already exists.`, toastOptions);
          return;
        }
      }
      let demographicVersions: DemographicVersions[] = [];
      if (this.demoTypeRef === 'StaticCategory') {
        let categoryPayloads = this.combinedCategories.map(cat => ({title: cat.title}));
        demographicVersions.push({
          payload: categoryPayloads
        });
      } else if (this.demoTypeRef === 'NumberedRange') {
        let ranges: Payload[] = this.combinedRanges.map(range => ({
          title: range.title,
          min: range.value[0],
          max: range.value[1]
        }));
        demographicVersions.push({payload: ranges});
      }
      let newOrderingIndex = 1;
      if (demographics.content?.length !== undefined) {
        newOrderingIndex = demographics.content?.length + 1;
      }
      const addDemographic: Demographic = {
        displayName: this.demoTitleRef,
        orderingIndex: newOrderingIndex,
        isEnabled: this.enabledRef || false,
        demographicType: this.demoTypeRef as DemographicTypeEnum,
        demographicVersions: demographicVersions
      };
      if (this.demographic?.guid && this.activeEntity?.guid) {
        const updateDemographic: Demographic = {
          guid: this.demographic.guid,
          displayName: this.demoTitleRef,
          orderingIndex: newOrderingIndex,
          isEnabled: this.enabledRef || false,
          demographicType: this.demoTypeRef as DemographicTypeEnum,
          demographicVersions: demographicVersions
        }
        const result = await this.demographicStore.update(this.activeEntity.guid, this.demographic.guid, updateDemographic)
        this.demoTitleRef = ''
        this.enabledRef = false
        this.newCategories = []
        this.demoTypeRef = ''
        bus.emit('close-modal')
        bus.emit('close-popover-menu')
        if (result.isSuccessful) {
          Toast.success(
              `${!this.demographic.guid ?
                  'New demographic successfully added' :
                  'Demographic successfully updated'}`,
              toastOptions
          );
          location.reload()
          return
        }
        Toast.error(
            `${!this.demographic.guid ?
                'Demographic not added - Something went wrong' :
                'Demographic not updated - Something went wrong'}`,
            toastOptions
        );
      } else if (this.demographic?.guid == undefined && this.activeEntity?.guid) {
        //create default
        const result = await this.demographicStore.create(addDemographic, this.activeEntity?.guid)
        this.demoTitleRef = ''
        this.enabledRef = false
        this.newCategories = []
        this.demoTypeRef = ''
        bus.emit('close-modal')
        bus.emit('close-popover-menu')
        if (result.isSuccessful) {
          Toast.success('New demographic successfully added', toastOptions);
          if (!result.content?.isEnabled) {
            bus.emit('demographic-toggled', {index: this.$props.index, state: true})
          }
          location.reload()
          return
        }
        Toast.error('Demographic not added - Something went wrong', toastOptions);
      }
    }
  },
})
</script> 