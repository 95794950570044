<template>
  <Popover location="start">
    <template v-slot:popover_activator>
      <img src="/img/kebab_menu.svg" alt="more vertical icon" :class="`w-6 h-6 cursor-pointer`">
    </template>
    <v-list-item
        class="p-0 m-0">

      <v-list-item-title
          v-if="assessmentType.entityState === AssessmentTypeState.Draft"
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('publish')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 16H3C2.45 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V15C16 15.55 15.55 16 15 16ZM11 1C11 1.55 11.45 2 12 2H14.59L5.46 11.13C5.07 11.52 5.07 12.15 5.46 12.54C5.85 12.93 6.48 12.93 6.87 12.54L16 3.41V6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6V0H12C11.45 0 11 0.45 11 1Z" fill="#53687B"/>
            </svg>
          </div>

          <div class="col-span-3">
            Publish
          </div>

        </div>

      </v-list-item-title>

      <Divider class="py-2" v-if="assessmentType.entityState == AssessmentTypeState.Draft"/>

      <v-list-item-title
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('copy')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <img class="w-4 h-4" src="/img/copy.svg" alt="Copy Assessment Type">
          </div>

          <div class="col-span-3">
            Copy
          </div>

        </div>

      </v-list-item-title>

      <v-list-item-title
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('edit')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <img class="w-4 h-4" src="/img/edit.svg" alt="Edit Assessment Type">
          </div>

          <div class="col-span-3">
            {{ getEditName()}}
          </div>

        </div>

      </v-list-item-title>

      <v-list-item-title
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('delete')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <img src="/img/delete-grey.svg" alt="delete icon" class="w-4 h-4">
          </div>

          <div class="col-span-3">
            Delete
          </div>

        </div>

      </v-list-item-title>


      <v-list-item-title
          class="px-1 py-2 mx-1 hover:bg-box-stroke">

        <div @click="emits('manage')"
             class="cursor-pointer w-full grid grid-cols-4">

          <div class="flex flex-row items-center justify-center">
            <img src="/img/note.svg" alt="Manage Domains" class="w-4 h-4">
          </div>

          <div class="col-span-3">
            Manage Domains
          </div>

        </div>

      </v-list-item-title>

    </v-list-item>
  </Popover>
</template>

<script setup lang="ts">

import Popover from "@/components/Atomic/AQuarks/Popover.vue";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessmentTypeState} from "@/areas/assessmentTypes/model/data/enums/AssessmentTypeState";
import Divider from "@/shared/components/Divider.vue";

const props = defineProps<{
  assessmentType: AssessmentTypeDto
}>()

const getEditName = (): string => {
  if (props.assessmentType.entityState == AssessmentTypeState.Published) {
    return "View"
  }

  return "Edit"
}

const emits = defineEmits<{
  edit: []
  delete: []
  manage: []
  publish: []
  copy: []
}>()

</script>