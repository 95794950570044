import axiosInstance from "@/axiosConfig";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {User} from "@/areas/users/model/data/User";
import {OperationResult} from "@/areas/helpers/responses/models/operationResult";
import {Operations} from "@/areas/helpers/responses/operations";
import {ProblemDetails} from "@/areas/helpers/responses/models/problemDetails";
import {EntityTypeEnum} from "@/areas/entities/model/enums/entityTypeEnum";
import {EntityAsset} from "@/areas/entities/model/data/dtos/entityAsset";
import {EntityAssetType} from "@/areas/entities/model/enums/entityAssetType";

export class EntityProxy {
    public async create(name: string, brandColour: string, entityType: EntityTypeEnum, callingGuid: string) : Promise<OperationResult<Entity>> {
        // This function requires super admin access -- at the moment
        const uri = `/entities/${callingGuid}`
        const entity: Entity = {
            entityType: entityType,
            displayName: name,
            brandColour: brandColour
        }

        try {
            const json = JSON.stringify(entity);

            const response = await axiosInstance.post(uri, json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return Operations.ToSuccessResponse(response.data)

        } catch (error : any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async fetchEntity(entityGuid: string) : Promise<OperationResult<Entity>> {
        const uri = `/entities/${entityGuid}`

        try {
            const response = await axiosInstance.get<Entity>(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async getUsers(entityGuid: string) : Promise<OperationResult<User[]>>{
        // This function will only return if the user is a member of entity
        const uri = `/entities/${entityGuid}/users`

        try {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async updateEntity(entityGuid: string, name: string, entityBranchColour: string) : Promise<OperationResult<Entity>> {
        // This function requires super admin access -- at the moment
        const uri = `/entities/${entityGuid}`;
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = {
            Guid: entityGuid,
            DisplayName: name,
            BrandColour: entityBranchColour
        }

        try {
            const json = JSON.stringify(body)

            const response = await axiosInstance.put(uri, json, config)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async deleteEntity(entityGuid: string) : Promise<OperationResult<any>> {
        // This function requires super admin access -- at the moment
        const uri = `/entities/${entityGuid}`

        try {
            const response = await axiosInstance.delete(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async downloadCsv(entityGuid?: string | undefined): Promise<OperationResult<any>> {
        const uri = entityGuid === undefined? '/entities/users/files' : `/entities/${entityGuid}/users/files`

        try {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async requestAssetUpload(fileType: string, entityGuid: string) : Promise<OperationResult<EntityAsset>> {
        const uri = `/entities/${entityGuid}/assets`

        var asset: EntityAsset = {
            entityAssetType: EntityAssetType.Logo,
            mimeType: fileType
        };

        try {
            const signedUrlResponse = await axiosInstance.put<EntityAsset>(uri, asset)

            return Operations.ToSuccessResponse(signedUrlResponse.data);
        } catch(error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async deleteAsset(entityGuid: string, assetGuid: string) : Promise<OperationResult<EntityAsset>> {
        const uri = `/entities/${entityGuid}/assets/${assetGuid}`

        try {
            const signedUrlResponse = await axiosInstance.delete<EntityAsset>(uri)

            return Operations.ToSuccessResponse(signedUrlResponse.data);
        } catch(error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async s3Upload(signedUri: string, blob: Blob, mimeType: string): Promise<OperationResult<string>> {
        try {
            const response = await axiosInstance.put(signedUri, blob, {
                headers: {
                    'Content-Type': mimeType, // Adjust based on your file type
                    'skipAuthHeader': 'true', // Custom header to bypass auth interceptor
                },
            });

            return Operations.ToSuccessResponse("success")
        } catch(error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
            return Operations.ToErrorResponse("RequestFailure")
        }
    }
}