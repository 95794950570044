<template>
  <div class="grid grid-cols-10 w-full">
    <div class="col-span-2 flex flex-col justify-center">
      <BackButton class="text-md sm:text-xl" @back="emit('back')"/>
    </div>

    <div class="col-span-6 flex flex-col justify-center">
      <HeadingPrimary>{{ heading }}</HeadingPrimary>

      <div
          v-if="subheading"
          class="text-center text-payne-grey text-lg font-italic">
        {{subheading}}
      </div>
    </div>

    <div class="col-span-2">
      <slot></slot>
    </div>
  </div>

</template>

<script setup lang="ts">
import HeadingPrimary from "@/shared/components/HeadingPrimary.vue";
import BackButton from "@/shared/components/BackButton.vue";

defineProps<{
  heading: string
  subheading?: string
}>()

const emit = defineEmits<{
  back: []
}>()

</script>

<style scoped>

</style>