import {defineStore} from 'pinia'
import {EntityUserProxy} from "@/areas/entities/proxy/entityUserProxy";
import {EmployeeDto} from "@/areas/entities/model/data/dtos/employeeDto";
import {EmployeeSearchDto} from "@/areas/entities/model/data/dtos/employeeSearchDto";
import axios, {CancelTokenSource} from "axios";
import keycloak from "@/keycloak";
import {User} from "@/areas/users/model/data/User";

const EMPLOYEE_SEARCH_DEBOUNCE_TIMEOUT_MS = 250

interface IState {
    pageSize: number
    pageNumber: number
    results: EmployeeDto[]
    selectedUsers: User[]
    userEntityProxy: EntityUserProxy
    currentSearchTerm: string
    cancellationToken?: CancelTokenSource
    debounceTimeout?: NodeJS.Timeout
    isLoading: boolean,
    error?: string
}

function clearStore(): IState {
    return {
        pageNumber: 0,
        pageSize: 50,
        results: [],
        selectedUsers: [],
        currentSearchTerm: "",
        isLoading: true,
        userEntityProxy: new EntityUserProxy(),
        error: undefined
    }
}

function toEmployeeSearchDto(searchState: IState): EmployeeSearchDto {
    return {
        pageNumber: searchState.pageNumber,
        searchString: searchState.currentSearchTerm == "" ? undefined : searchState.currentSearchTerm,
        pageSize: searchState.pageSize,
        sortBy: "FirstName",
        sortAscending: true
    }
}

export const useEmployeeSearchStore = defineStore({
    id: "employeeSearchStore",
    state: (): IState => clearStore(),
    getters: {
        isLoggedInUser: () => {
            return (employee: EmployeeDto): boolean => {
                const loggedInUser = keycloak.tokenParsed;

                return loggedInUser?.sub == employee.user.guid
            }
        },
        allSelected: (state) => {
            return (): boolean => {
                const selectedCount = state.results.filter(r => r.selected).length

                return selectedCount == state.results.length
            }
        }
    },
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        setSelectedEmployees(users: User[]) {
            if (users) {
                this.selectedUsers = users
            } else {
                this.selectedUsers = []
            }
        },

        async searchEmployees(entityGuid: string, searchTerm: string): Promise<void> {
            if (this.cancellationToken != null) {
                this.cancellationToken.cancel()
            }

            this.cancellationToken = axios.CancelToken.source()

            //Clear the timeout after search
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout)
            }

            this.debounceTimeout = setTimeout(async () => {
                await this.withLoading(async () => {
                    searchTerm = searchTerm.trim()

                    //Check if the searchTerm has changed
                    if (searchTerm !== this.currentSearchTerm) {
                        this.pageNumber = 0
                    }

                    this.currentSearchTerm = searchTerm
                    this.pageNumber += 1

                    const employeeSearchDto = toEmployeeSearchDto(this)

                    const searchResult = await this.userEntityProxy.listUsers(entityGuid, employeeSearchDto, this.cancellationToken)

                    if (!searchResult.isSuccessful) {
                        this.pageNumber = 0

                        this.error = `Could not search employees: ${searchResult.error}`

                        return;
                    }

                    if (searchResult.content?.employees == null || searchResult.content.employees.length === 0) {
                        this.pageNumber -= 1
                    } else {
                        const employees = searchResult.content.employees

                        const selectedEmployees = employees.map(emp => {
                            const selected = this.selectedUsers.find(usr => usr.guid == emp.user.guid)

                            emp.selected = !!selected;

                            return emp
                        })

                        if (this.pageNumber == 1) {
                            this.results = selectedEmployees
                        } else {
                            this.results.push(...selectedEmployees)
                        }
                    }
                })
            }, EMPLOYEE_SEARCH_DEBOUNCE_TIMEOUT_MS)
        },

        selectEmployee(employee: EmployeeDto) {
            const existingIndex = this.selectedUsers.findIndex(user => user.guid == employee.user.guid)

            //Already in selected list
            if (existingIndex >= 0) {
                return
            }

            const index = this.results.findIndex(emp => emp.user.guid == employee.user.guid)

            //Does not exist in current result set
            if (index == -1) {
                return
            }

            this.results[index].selected = true
            this.selectedUsers.push(employee.user)
        },

        deselectEmployee(employee: EmployeeDto) {
            //Remove this if it exists in the list of selected employees
            this.selectedUsers = this.selectedUsers.filter(user => user.guid != employee.user.guid)

            const index = this.results.findIndex(emp => emp.user.guid == employee.user.guid)

            if (index == -1) {
                return
            }

            this.results[index].selected = false
        },

        clearEmployeeSearch() {

            this.$state = clearStore()
        }
    }

});