<template>
  <AppLayout>
    <div class="p-4 space-y-4">
      <DashboardHeader v-if="showDashboardHeader" :tab="'ASSESSMENT_TYPES'"/>
      <AssessmentTypeView :entity-guid="entityGuid" @showHeading="val => showDashboardHeader = val "/>
    </div>
  </AppLayout>

</template>

<script setup lang="ts">
import DashboardHeader from "@/components/Atomic/BAtoms/DashboardHeader.vue";
import AppLayout from "@/components/Layouts/AppLayout.vue";
import {useRoute} from "vue-router";
import AssessmentTypeView from "@/areas/assessmentTypes/components/AssessmentTypeView.vue";
import {ref} from "vue";

const route = useRoute()

const showDashboardHeader = ref<boolean>(true)

const entityGuid = route.params.entityGuid as string
</script>