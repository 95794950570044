<template>
  <NavHeading
      :heading="`View Assessment Results for ${assessor.user?.firstName}`"
      @back="emits('back')"
      class="py-4" />

  <AnswerForm
      :delegate="delegate"
      :readonly="true"
      :assessor="assessor"
      :show-header="false"
      :assessment-display-object="assessmentDisplayObject"
      :entity-guid="entityGuid"/>
</template>

<script setup lang="ts">

import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import AnswerForm from "@/areas/userAssessments/components/AnswerForm.vue";
import NavHeading from "@/shared/components/NavHeading.vue";

defineProps<{
  entityGuid: string
  assessmentDisplayObject: AssessmentDisplayObject,
  delegate: DelegateDto,
  assessor: AssessorDto
}>()

const emits = defineEmits<{
  back: []
}>()

</script>