import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import keycloak from './keycloak'
import {createPinia} from 'pinia'
import vuetify from './vuetify'

keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).then((authenticated) => {
    if (authenticated) {
        const app = createApp(App);

        app.use(createPinia())
        app.config.globalProperties.$keycloak = keycloak;
        app.use(router)
        app.use(vuetify)
        app.mount('#app');
    } else {
        console.warn('Failed to authenticate');
    }
}).catch((err) => {
    console.error('Keycloak initialization failed', err);
});