<template>
  <div class="w-full mx-auto sm:max-w-2xl p-4">
    <div
        v-if="!isLoading">

      <div
          v-if="state === AssessorState.Accepted">

        <Card class="py-8 space-y-4">
          <HeadingPrimary>Invite Accepted!</HeadingPrimary>

          <div>
            Thank you for accepting the invite. You will be notified when the assessment starts.
          </div>

          <GenericButton @onSubmit="goHome">
            Go Home
          </GenericButton>
        </Card>
      </div>

      <div
          v-if="state === AssessorState.Declined">

        <Card class="py-8 space-y-4">
          <HeadingPrimary>Invite Declined</HeadingPrimary>

          <div>
            You have declined the request to assess. If this was a mistake, please reach out to the delegate in question
          </div>

          <GenericButton @onSubmit="goHome">
            Go Home
          </GenericButton>
        </Card>
      </div>
    </div>


    <div class="flex justify-center w-full">
      <LoadingCard :is-loading="isLoading"/>
    </div>

    <PopupErrorMessage
        v-if="error"
        :message="error"
        @closed="error = undefined"
    />
  </div>
</template>

<script setup lang="ts">

import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import HeadingPrimary from "@/shared/components/HeadingPrimary.vue";
import Card from "@/shared/components/Card.vue";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import GenericButton from "@/shared/components/GenericButton.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";

const route = useRoute()
const router = useRouter()

const entityGuid = ref(route.params.entityGuid as string)

const assessmentGuid = ref(route.params.assessmentGuid as string)

const delegateGuid = ref(route.params.delegateGuid as string)

const assessorGuid = ref(route.params.assessorGuid as string)

const status = ref(route.params.status as string)

const error = ref<string | undefined>(undefined)

const isLoading = ref<boolean>(true)

const state = ref<AssessorState | undefined>(undefined)

const goHome = async () => {
  await router.push({
    name: 'UserAssessments',
    params: {
      entityGuid: entityGuid.value
    }
  })
}

onMounted(async () => {
  const proxy = new AssessorProxy()

  let paramState: AssessorState

  switch (status.value.toLowerCase()) {
    case "accept":
      paramState = AssessorState.Accepted
      break

    case "decline":
      paramState = AssessorState.Declined
      break

    default:

      return
  }

  const result = await proxy.updateState(entityGuid.value, assessmentGuid.value, delegateGuid.value, assessorGuid.value, paramState)

  if (!result.isSuccessful) {
    if (result.error == "AssessorNotFound") {
      error.value = "You are not set as an assessor for this delegate. If this is not correct, please contact the system admin"
      return
    }

    error.value = result.error!;
    return
  }

  state.value = paramState
  isLoading.value = false
})

</script>