<template>
  <div class="p-4 space-y-8">
    <div class="grid grid-cols-8">
      <div class="col-span-1 flex flex-row justify-start items-center">
        <BackButton @back="emit('back')" class="text-xl"/>
      </div>

      <div class="col-span-6">
        <div class="flex flex-row text-[28px] text-payne-grey font-semibold items-center justify-center">
          {{ heading }}
        </div>
      </div>

      <div class="col-span-1">
        <GenericButton class="bg-olive-green text-white" @onSubmit="save">Save</GenericButton>
      </div>
    </div>

  </div>


  <div class="space-y-4">
    <Card class="space-y-4 flex flex-col py-4 px-8">

      <AssessmentConfiguration
          :assessment-types="assessmentTypes.filter(as => as.entityState == AssessmentTypeState.Published)"
          :assessment-config-section="assessmentDetail()"
          :assessment="assessmentDisplayObject"
          :show-validation="forceShowValidationMessages"

          @validated="validateConfig"
          @inValidated="inValidateConfig"
      />

    </Card>

    <Card class="space-y-4 flex flex-col py-4 px-8">

      <AssessmentSettings
        :assessment-settings="settings()"
        :assessment="assessmentDisplayObject"

        @validateSettings="validateSettings"
        @inValidateSettings="inValidateSettings"
      />

    </Card>

  </div>
</template>

<script setup lang="ts">

import BackButton from "@/shared/components/BackButton.vue";
import AssessmentConfiguration from "@/areas/assessments/components/AssessmentConfiguration.vue";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import Card from "@/shared/components/Card.vue";
import {ref, Ref} from "vue";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import AssessmentSettings from "@/areas/assessments/components/AssessmentSettings.vue";
import GenericButton from "@/shared/components/GenericButton.vue";
import {AssessmentSettingValues} from "@/areas/assessments/model/data/AssessmentSettingValues";
import {AssessmentDetailValues} from "@/areas/assessments/model/data/AssessmentDetailValues";
import {useAssessmentStore} from "@/areas/assessments/store/AssessmentStore";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import {AssessmentTypeState} from "@/areas/assessmentTypes/model/data/enums/AssessmentTypeState";

const props = defineProps<{
  assessmentDisplayObject?: AssessmentDisplayObject
  assessmentTypes: AssessmentTypeDto[]
  entityGuid: string
}>()

const emit = defineEmits<{
  back: []
  saved: []
}>()

const detailSection: Ref<AssessmentDetailValues | undefined> = ref(undefined)
const settingSection: Ref<AssessmentSettingValues | undefined> = ref(undefined)

const assessmentStore = useAssessmentStore()

const forceShowValidationMessages: Ref<boolean> = ref(false)

const assessmentDetail = (): AssessmentDetailValues | undefined => {
  if (props.assessmentDisplayObject) {
    return {
      title: props.assessmentDisplayObject.detail.title as string,
      endDate: props.assessmentDisplayObject.detail.endDate,
      startDate: props.assessmentDisplayObject.detail.startDate,
      assessmentType: props.assessmentDisplayObject.assessment.entityAssessmentType?.assessmentType
    }
  }

  return undefined
}

const settings = (): AssessmentSettingValues | undefined => {
  if (props.assessmentDisplayObject) {
    return {
      includeLeader: props.assessmentDisplayObject.detail.includeLeader,
      limitsEnabled: !!(props.assessmentDisplayObject.detail.maximumAssessors),
      maximumAssessors: props.assessmentDisplayObject.detail.maximumAssessors,
      minimumAssessors: props.assessmentDisplayObject.detail.minimumAssessors,
      remindersEnabled : props.assessmentDisplayObject.detail.reminderFrequency !== undefined,
      reminderFrequency : props.assessmentDisplayObject.detail.reminderFrequency
    }
  }
}

const validateConfig = (assessmentConfigSection: AssessmentDetailValues) => {
  detailSection.value = assessmentConfigSection
}

const inValidateConfig = () => {
  detailSection.value = undefined
}

const validateSettings = (settings: AssessmentSettingValues) => {
  settingSection.value = settings
}

const inValidateSettings = () => {
  settingSection.value = undefined
}

const save = async () => {
  if (detailSection.value != undefined && settingSection.value != undefined) {
    await assessmentStore.save(props.entityGuid, detailSection.value, settingSection.value, props.assessmentDisplayObject?.assessment.guid!)

    if (assessmentStore.error == null) {
      emit('saved')
      return
    }

    await Toast.error(assessmentStore.error, toastOptions)

    assessmentStore.clearError()
  }
  else {
    forceShowValidationMessages.value = true
  }
}

const heading: Ref<string> = ref<string>(props.assessmentDisplayObject ? "Edit Assessment" : "Add Assessment")

</script>