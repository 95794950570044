import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($data.showTooltip = true)),
    onMouseout: _cache[1] || (_cache[1] = ($event: any) => ($data.showTooltip = false))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    ($data.showTooltip)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`absolute drop-shadow-md z-40 p-2 bg-wine-red rounded-lg top-10 left-0 ${$props.customCss ? $props.customCss : 'text-white'}`)
        }, _toDisplayString($props.text), 3))
      : _createCommentVNode("", true)
  ], 32))
}