<template>
  <div class="py-2 w-full fixed inset-x-0 bottom-0 bg-menu-bg z-10 flex flex-row items-center justify-center">
    <div class="w-full sm:max-w-2xl disabled">
      <button
          @click="save"
          :class="isLoading || disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-olive-green cursor-pointer'"
          class="w-full text-black font-bold py-2 px-4 rounded">

        <div class="flex flex-row items-center justify-center">
          <div>
            {{ text ? text : 'Save' }}
          </div>

        </div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">

const emit = defineEmits<{
  save: []
}>()

const props = defineProps<{
  text?: string
  isLoading: boolean
  disabled: boolean
}>()

const save = () => {
  if (!props.disabled && !props.isLoading) {
    emit('save')
  }
}

</script>