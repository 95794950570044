<template>
  <div class="w-full px-6 font-roboto">
    <div class="font-bold text-payne">
      <div class="cursor-pointer" @click="handleTabChange('back', currentTab.position-1)">
        <img src="/img/arrow_left.svg" alt="" class="d-inline-block mr-2 W-4 h-4 pb-1">
        <span class="text-lg">BACK</span>
      </div>
      <p class="py-6 text-[28px]">{{ pageTitle }}</p>
    </div>

    <div class="">
      <div class="border-b rounded-md drop-shadow-sm bg-white border-gray-200">
        <nav class="flex font-roboto uppercase" aria-label="Tabs">
          <div v-for="(tab, index) in formTabs"
               :key="tab.name"
               :class="[tab.name === currentTab?.name ? 'border-dark-blue text-dark-blue cursor-pointer' :
              'cursor-pointer border-transparent text-payne-grey hover:border-gray-300 hover:text-gray-700', 'w-1/2 border-b-4 py-4 px-1 text-center text-sm font-bold']"
               :aria-current="tab.current ? 'step' : undefined"
               @click="changeSelectedTab(tab)">{{ `${index + 1}. ` }}{{ tab.name }}
          </div>
        </nav>
      </div>
    </div>

    <p class="uppercase font-normal text-lg text-payne pt-4 pb-2">
      {{ currentTab?.name }}
    </p>

    <div class="hidden sm:block">
      <div class="drop-shadow-sm bg-white border-gray-200">
        <div class="py-10 px-16">
          <EmployeeDetails
              v-if="currentTab?.href === 'details'"
              :form-data="details"
              :user-details="employee"
              :field-validation-key="fieldValidationKey"
              @input-value-changed="formIsValid(currentTab.href)"/>

          <EmployeeDemographics
              v-else-if="currentTab?.href === 'demographics'"
              :dob="details.dateOfBirth.value"
              :selected-demographics="selectedDemographics"
              :user-details="employee"
              :field-validation-key="fieldValidationKey"
              :is-form-valid="demographicFormIsValid"
              @update:selectedDemographics="selectedDemographics = $event"/>

          <div class="flex justify-end pt-14">
            <button
                type="button"
                @click="handleNextButtonClick"
                :class="`mr-[85px] uppercase w-[104px] h-[40px] rounded-sm bg-olive-green px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-olive-green/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:olive-green`">
              {{ getButtonText() }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import EmployeeDetails from './Forms/Details.vue'
import EmployeeDemographics from './Forms/Demographics.vue';
import {INewEmployeeTabs, SelectedUserDemographic} from '@/types/shared'
import {NewUser} from '@/areas/users/model/data/NewUser'
import {useRoute, useRouter} from 'vue-router';
import {useEntityUserStore} from '@/areas/entities/store/entityUserStore'
import {EmployeeCreateDto} from '@/areas/entities/model/data/dtos/employeeCreateDto'
import {AnswerTypeEnum} from '@/areas/entities/model/data/dtos/answerTypeEnum'
import {UserDemographic} from '@/areas/entities/model/data/dtos/userDemographicDto'
import Toast from '@/Toast';
import {dateFormatter, isValidAndAcceptableDate, isValidEmail} from '@/components/utils/utils';
import bus from '@/bus'
import {User} from '@/areas/users/model/data/User'
import {UserRolesEnum} from '@/areas/demographics/model/enums/userRolesEnum'
import {EmployeeDto} from '@/areas/entities/model/data/dtos/employeeDto'

export default defineComponent({
  name: 'EmployeeFormTabs',
  components: {
    EmployeeDetails,
    EmployeeDemographics,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const organisationGuid = route.params.organisationGuid as string
    const userGuid = route.params.userGuid
    const entityUserStore = useEntityUserStore()

    const formTabs = ref<INewEmployeeTabs[]>([
      {name: 'EMPLOYEE DETAILS', href: 'details', current: false, position: 0},
      {name: 'DEMOGRAPHICS', href: 'demographics', current: false, position: 1,}
    ]);
    const fieldValidationKey = ref<number>(new Date().getMilliseconds())
    const btnText = ref<string>('Next')

    const isFormValid = ref<boolean>(false)
    const demographicFormIsValid = ref<{ state: boolean }>({state: false})

    const details = ref<NewUser>({
      firstName: {
        value: '',
        isValid: false
      },
      lastName: {
        value: '',
        isValid: false
      },
      email: {
        value: '',
        isValid: false
      },
      mobileNumber: '',
      role: {
        value: '',
        isValid: false
      },
      dateOfBirth: {
        value: undefined,
        isValid: false
      },
    });

    const selectedDemographics = ref<any[]>([])
    const data = ref<SelectedUserDemographic[]>([]);
    const employee = ref<EmployeeDto>();

    async function populateUserData() {
      if (route.params.userGuid) {
        const userFetchResult = await entityUserStore.fetchEntityUser(route.params.organisationGuid as string, route.params.userGuid as string);

        if (userFetchResult.isSuccessful) {
          employee.value = userFetchResult.content
        }
      }
    }

    if (route.params.userGuid) {
      populateUserData();
    }

    const getButtonText = (): string => {
      if (currentTab.value.href === "demographics") {
        return "Save"
      } else {
        return "Next"
      }
    }

    const pageTitle = computed(() => {
      return userGuid ? 'Edit Employee' : 'Add Employee';
    });

    const formIsValid = (currentTab: string, validateTab = false): boolean => {
      let isValid = true
      switch (currentTab) {
        case 'details':
          for (const key in details.value) {
            if (details.value.hasOwnProperty(key)) {
              //@ts-ignore
              if (details.value[key].value === '' && key !== 'mobileNumber') {
                isValid = false
                //@ts-ignore
                details.value[key].isValid = details.value[key].value === ''
              }
            }
          }
          return isValid && details.value.dateOfBirth.isValid && isValidEmail(details.value.email.value)
        case 'demographics':
          bus.emit('validateDemographics')
          isValid = demographicFormIsValid.value.state
          break;
        default:
          return true;
      }
      fieldValidationKey.value = new Date().getMilliseconds()
      return isFormValid.value = isValid
    }

    const saveUser = async () => {
      const userDetails: any = {}

      for (const [k, v] of Object.entries(details.value)) {
        //@ts-ignore
        userDetails[k] = v.value || v
      }
      const userDto: User = userDetails

      if (userDetails.role == "SuperAdmin") {
        userDto.userRole = UserRolesEnum.SuperAdmin
      } else if (userDetails.role == "OrganisationAdmin") {
        userDto.userRole = UserRolesEnum.OrganisationAdmin
      } else {
        userDto.userRole = UserRolesEnum.User
      }

      userDto.dateOfBirth = (userDto.dateOfBirth! as Date).toISOString().split('T')[0]

      const userDemographics: UserDemographic[] = selectedDemographics.value.map(sd => {
        let answerType: AnswerTypeEnum;

        if (typeof sd.valueGuid === 'string') {
          answerType = AnswerTypeEnum.String;
        } else if (sd.valueGuid instanceof Date) {
          answerType = AnswerTypeEnum.Date;
        } else {
          answerType = AnswerTypeEnum.Number;
        }

        const answer = sd.answer === undefined
            ? typeof sd.valueGuid === 'string'
                ? sd.valueGuid
                : sd.valueGuid instanceof Date
                    ? `${sd.valueGuid.getFullYear()}-${(sd.valueGuid.getMonth() + 1).toString().padStart(2, '0')}-${sd.valueGuid.getDate().toString().padStart(2, '0')}`
                    : sd.valueGuid.toString()
            : sd.answer instanceof Date
                ? `${sd.answer.getFullYear()}-${(sd.answer.getMonth() + 1).toString().padStart(2, '0')}-${sd.answer.getDate().toString().padStart(2, '0')}`
                : sd.answer;

        return {
          demographicGuid: sd.demographicGuid,
          demographicVersionGuid: sd.demographicVersionGuid ?? sd.versionGuid,
          answerType: answerType,
          answer: answer
        };
      });

      const employeeCreateDto: EmployeeCreateDto = {
        user: userDto,
        userDemographics: userDemographics,
      }

      if (selectedDemographics.value.length === 0 && employee.value && employee.value.userDemographics) {
        // Use existing demographics if prepopulated demographics are not changed
        //@ts-ignore
        employeeCreateDto.userDemographics = employee.value.userDemographics.map(demographic => ({
          //@ts-ignore
          demographicGuid: demographic.demographicGuid,
          //@ts-ignore
          demographicVersionGuid: demographic.demographicVersionGuid,
          //@ts-ignore
          answerType: demographic.answerType,
          //@ts-ignore
          answer: demographic.answer
        }));
      }

      const result = await entityUserStore.upsertEmployee(organisationGuid, employeeCreateDto)

      if (result.isSuccessful) {
        Toast.success('User successfully updated', {
          position: 'top-right',
          duration: 3000,
          dismissible: true
        })
        setTimeout(() => {
          localStorage.removeItem('userDemographics')
          window.location.href = `/dashboard/employees/${organisationGuid}`
        }, 1500)
        return;
      }

      return userDto
    }

    async function emailExistsCheck(email: string) {
      return await entityUserStore.fetchEmployeeByEmail(organisationGuid, email);
    }

    const handleTabChange = (direction: 'back' | 'next', position: number) => {
      switch (direction) {
        case 'next':
          changeSelectedTab(formTabs.value[position])
          break;
        default:
          if (position < 0) {
            localStorage.removeItem('userDemographics')

            router.push(`/dashboard/employees/${organisationGuid}`)

            return
          }

          currentTab.value = (formTabs.value[position])
          break;
      }
    };

    const currentTab = ref<INewEmployeeTabs>(formTabs.value[0]);

    const changeSelectedTab = (selectedTab: INewEmployeeTabs): boolean | undefined => {
      if (selectedTab.position < currentTab.value.position) {
        currentTab.value = formTabs.value[selectedTab.position];
        return;
      }
      return !formIsValid(currentTab.value.href, true) ? false : (currentTab.value = formTabs.value[selectedTab.position], true);
    };

    return {
      data,
      pageTitle,
      details,
      btnText,
      formTabs,
      employee,
      userGuid,
      currentTab,
      fieldValidationKey,
      selectedDemographics,
      demographicFormIsValid,
      saveUser,
      formIsValid,
      handleTabChange,
      changeSelectedTab,
      emailExistsCheck,
      getButtonText
    }
  },
  methods: {
    handleNextButtonClick() {
      if (this.currentTab.href === 'details') {
        if (!this.userGuid) {
          const email = this.details.email.value;

          this.emailExistsCheck(email).then((exists: any) => {
            if (exists.isSuccessful) {
              Toast.error('Email already exists. Please use a different email.', {
                position: 'top-right',
                duration: 3000,
                dismissible: true
              });
            } else {
              this.handleTabChange('next', this.currentTab.position + 1);
            }
          }).catch((error: any) => {
            Toast.error('There was an error checking the email.', {
              position: 'top-right',
              duration: 3000,
              dismissible: true
            });
          });
        } else {
          this.handleTabChange('next', this.currentTab.position + 1);
        }
      } else {
        this.saveUser()
      }
    }
  }
})
</script>
