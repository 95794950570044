<template>
<div class="flex flex-col space-y-4 w-full px-6">
      <div class="flex flex-row justify-between items-center">
        <div>
            <h1 class="font-roboto font-bold text-payne-grey text-2xl">Demographics</h1>
            <p class="font-roboto text-payne-grey text-sm">This is a list of demographics. You may configure them if needed.</p>
        </div>
        <Modal modalTitle="Add Demographic">
                <template v-slot:modal_activator>
                    <Button class="bg-olive-green text-white uppercase" buttonText="Add Demographic"
                        customStyle="w-6 h-6" imageSrc="/img/plus.svg" />
                </template>
                <v-row>
                    <DemographicForm :default="true" :ranges="demographicRanges" :actionToPerform="actionToPerform">
                    </DemographicForm>
                </v-row>
            </Modal>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Tooltip from '@/components/Atomic/AQuarks/Tooltip.vue'
import Modal from '@/components/Atomic/AQuarks/Modal.vue'
import DemographicForm from '@/components/Atomic/BAtoms/Demographics/DemographicForm.vue';
import Button from '@/components/Atomic/AQuarks/Button.vue';
import { ActionType } from "@/areas/demographics/model/data/demographics";

export default defineComponent({
    name: 'DefaultDemographicTypesHeader',
    components: {
        Button,
        Tooltip,
        Modal,
        DemographicForm
    },
    setup() {
        const actionToPerform: ActionType = 'ADDING_DEMOGRAPHIC'

        return {
            actionToPerform
        }
    },
    methods: {

    }
});
</script>